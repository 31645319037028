import axios from "axios";
import { baseURL } from "../api/apiUris";
import { RouteApi } from "../constants/Componet-name";
import { dateFormatSql, getCurrentLocalDate } from "./formatDates";
import { localStorageItemNames } from "../constants";
import { UserLogsAPi } from "../api";
import { typeConstant } from "../constants/commonFunction";

export const createUserLogsApiFuncation = ({
  endDate,
  startDate,
  idleDate,
  idleEndDate,
  idleStartDate,
}) => {
  const userId = localStorage.getItem(localStorageItemNames.userID);
  if (Number(userId) > 0) {
    const payload = {
      userId: Number(userId),
      startDate: dateFormatSql(startDate),
      createdBy: Number(userId),
      updatedBy: Number(userId),
      configDetails: {
        startDate: idleStartDate ? dateFormatSql(idleStartDate) : null,
        idealTime: [idleDate],
        endDate: idleEndDate ? dateFormatSql(idleEndDate) : null,
      },
    };
    axios.post(`${baseURL}${RouteApi.createUserLogs}`, payload);
  }
};

export const onLogoutFunction = async (history, data) => {
  const userId = localStorage.getItem(localStorageItemNames.userID);
  const type = localStorage.getItem(localStorageItemNames.type);
  const endDate = getCurrentLocalDate();
  // Delay the reload by passing control to the caller

  if (type?.toLowerCase() === typeConstant.dealer) {
    await UserLogsAPi.createUserLogs({
      userId: userId,
      endDate: dateFormatSql(endDate),
    });
  }

  localStorage.removeItem(localStorageItemNames.user_token);
  localStorage.removeItem(localStorageItemNames.userID);
  localStorage.removeItem(localStorageItemNames.type);
  localStorage.removeItem(localStorageItemNames.typeId);
  localStorage.removeItem(localStorageItemNames.name);
  localStorage.setItem("version", data?.version);
  history("/", { state: { data: "userNewVersion" } });
};
