import React, { useState, createContext, useCallback } from "react";
import { fleetListApi, modelApi } from "../api";
import { showToast } from "../../components/Toast";
import { FleetListMessage } from "../constants/popupMessage";
/**
 * Fleet list context
 */
export const FleetListContext = createContext();

/**
 * Fleet list provider
 */
export const FleetListProvider = ({ children }) => {
  const [fleetList, setFleetList] = useState([]);
  const [fleetListById, setFleetListById] = useState(null);
  const [loading, setLoading] = useState(false);
  const [vehicleInfo, setVehicleInfo] = useState([]);
  const [editData, setEditData] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [requireFields, setRequireFields] = useState([]);

  const getFleetListApi = async (values) => {
    setLoading(true);
    const response = await fleetListApi.getFleetList({
      limit: values?.limit ? values.limit : 100,
      offset: values?.offset ? values.offset : 0,
      dealerId: values?.dealerId ? values?.dealerId : null,
      companyId: values?.companyId ? values?.companyId : null,

      ...values,
      filter: {
        ...values?.filter,
      },
      sortColumn: "FleetNo",
      sortType: "desc",
      sorting: {
        fieldName: "",
        direction: "",
      },
    });
    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data?.length > 0) {
      setFleetList(response?.data?.data);
      setTotalCount(response.data.data.length);
      setLoading(false);
    } else {
      setFleetList([]);
      setLoading(false);
    }

    return response.data;
  };
  const getFleetListByIdApi = async (id) => {
    setLoading(true);
    const response = await fleetListApi.getFleetListByid(id);

    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      setFleetListById(response?.data?.data);
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const deletFleetListByIdApi = async (value) => {
    setLoading(true);
    const response = await fleetListApi.deleteFleetListByid(value?.id);

    if (response?.error) {
      setLoading(false);
    }

    if (response?.data) {
      getFleetListApi({ companyId: value?.companyId });
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const createFleetListApi = async (values) => {
    setLoading(true);
    const response = await fleetListApi.createFleetList(values);
    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      setFleetList(response?.data?.data);
      getFleetListApi({ companyId: values?.companyId });
      showToast("Fleet List created successfully!");
      setLoading(false);
    } else {
      showToast("Something went wrong!");
      setLoading(false);
    }

    return response.data;
  };
  const editFleetListApi = async ({ values, newAdd }) => {
    setLoading(true);
    if (newAdd?.newAdd == "Add") {
      await modelApi.createModel({
        id: newAdd?.modelId,
        name: newAdd?.model,
        makeId: newAdd?.make,
        yearId: newAdd?.year,
      });
    }
    const response = await fleetListApi.editFleetList(values);
    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      getFleetListApi({
        companyId: values?.companyId,
        dealerId: values?.dealerId,
      });
      setLoading(false);
      showToast("Fleet List Updated Successfully!");
    } else {
      showToast("Something went wrong!");
      setLoading(false);
    }

    return response.data;
  };
  const getFleetVehicleInfo = async (values) => {
    setLoading(true);
    const response = await fleetListApi.fleetListVehicleInfo({
      limit: values?.limit ? values.limit : 100,
      offset: values?.offset ? values.offset : 0,
      filter: {
        ...values?.filter,
      },
      sorting: {
        fieldName: "",
        direction: "",
      },
      companyId: values?.companyId,
      fleetNo: "",
    });
    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      setVehicleInfo(response?.data?.data);
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };

  const multipleFleetListApi = async ({ values, newAdd }) => {
    setLoading(true);
    if (newAdd?.newAdd == "Add") {
      await modelApi.createModel({
        id: newAdd?.modelId,
        name: newAdd?.model,
        makeId: newAdd?.make,
        yearId: newAdd?.year,
      });
    }
    const response = await fleetListApi.multipleFleetList(values);
    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      // setFleetList(response?.data?.data);
      showToast(FleetListMessage.updatedSuccess);
      getFleetListApi({
        companyId: values?.companyId,
        dealerId: values?.dealerId,
      });
    } else {
      showToast(FleetListMessage.updatedFailed);
      setLoading(false);
    }

    return response.data;
  };
  const multipleFleetDeleteApi = async (values) => {
    setLoading(true);
    const response = await fleetListApi.multipleFleetDelete(values?.ids);
    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      // setFleetList(response?.data?.data);
      getFleetListApi({
        companyId: values?.companyId,
        dealerId: values?.dealerId,
      });
    } else {
      setLoading(false);
    }

    return response.data;
  };

  const contextValue = {
    fleetList,
    setFleetList,
    loading,
    setLoading,
    setFleetListById,
    fleetListById,
    vehicleInfo,
    setVehicleInfo,
    editData,
    setEditData,
    totalCount,
    setTotalCount,
    requireFields,
    setRequireFields,
    getFleetListApi: useCallback((request) => {
      getFleetListApi(request);
    }, []),
    createFleetListApi: useCallback((request) => {
      createFleetListApi(request);
    }, []),
    editFleetListApi: useCallback((request, newAdd) => {
      editFleetListApi(request, newAdd);
    }, []),
    getFleetListByIdApi: useCallback((request) => {
      getFleetListByIdApi(request);
    }, []),
    getFleetVehicleInfoApi: useCallback((request) => {
      getFleetVehicleInfo(request);
    }, []),
    deletFleetListByIdApi: useCallback((request) => {
      deletFleetListByIdApi(request);
    }, []),
    multipleFleetListApi: useCallback((request, newAdd) => {
      multipleFleetListApi(request, newAdd);
    }, []),
    multipleFleetDeleteApi: useCallback((request) => {
      multipleFleetDeleteApi(request);
    }, []),
    // deleteDealerByIdApi: useCallback((request) => {
    //   deleteDealerByIdApi(request);
    // }, []),
  };

  return (
    <FleetListContext.Provider value={contextValue}>
      {children}
    </FleetListContext.Provider>
  );
};
