import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useCompany, useDealer, useFleetList, useYear } from "../common/hooks";
import { layoutStyle } from "../pages/layout-ui/style";
import { ActionFormFleetList } from "./Fleet-List/setAllDataFleetForm";
import { getCpmCategory } from "../common/constants/commonFunction";
import { ModelSelect } from "./ModelSelect";
import { showToast } from "./Toast";
import { FleetListMessage } from "../common/constants/popupMessage";

const AddActionDialog = ({
  modalContent,
  closeModal,
  selectedRows,
  dropdownValue,
  keyValue,
  companyId,
}) => {
  const classes = layoutStyle();
  const { editFleetListApi, multipleFleetListApi } = useFleetList();
  const { getDealerByIdApi, multipleFleetUpdate, dealerId } = useDealer();
  const [payloadValue, setPayloadValue] = useState();
  const [typeId, setTypeId] = useState(null);
  const { dealerNameHeader } = useCompany();
  const [mpgValue, setMpgValue] = useState(0);
  const [groupTypeValue, setGroupTypeValue] = useState(null);
  const { year, getYearApi } = useYear();
  const [driverName, setDriverName] = useState("");
  const [stateValue, setStateValue] = useState(null);
  const [newAdd, setNewAdd] = useState("");
  const [yearValue, setYearValue] = useState();
  const [yearName, setYearName] = useState();
  const [makeValue, setMakeValue] = useState();
  const [makeName, setMakeName] = useState();
  const [modelValueData, setModelValueData] = useState({
    modelId: null,
    model: "",
  });
  const [modelCsv, setModelCsv] = useState();
  const [mpgData, setMpgData] = useState();

  useEffect(() => {
    if (modalContent === "Set Year") {
      getYearApi({ companyId: companyId });
    }
  }, [modalContent]);
  const handleClickAuto = async (event, value) => {
    let type = typeId;
    let yearValue;
    let payload11 = [];
    if (payloadValue?.yearId) {
      let yearName = await year?.filter(
        (item) => item.id === payloadValue.yearId
      );
      if (yearName?.length > 0) {
        yearValue = yearName[0]?.year;
      }
    }
    let yearValueId = selectedRows?.length > 0 ? selectedRows[0]?.yearId : null;
    let payload = {
      year: yearValueId,
      make: makeValue,
      modelId: modelValueData?.modelId,
      model: modelValueData?.model,
      newAdd: newAdd,
    };
    let payloadValues;
    if (modalContent === "Set Model") {
      let ids = selectedRows?.map((items) => items.fleetId);
      payloadValues = {
        modelId: modelValueData?.modelId,
        companyId: companyId,
        updateIds: ids,
        dealerId: dealerNameHeader?.id,
      };
    }
    if ((typeId || groupTypeValue) && !payloadValues) {
      let payload11 = [];
      selectedRows?.map((items) => {
        let payload = {
          typeId: type ? type : items.typeId,
          fleetNo: items.fleetNo,
          year: yearValue ? yearValue : items.year,
          totalMiles: items.totalMiles,
          make: items.make,
          model: items.model,
          vin: items.vin,
          stateCode: stateValue ? stateValue : items.stateCode,
          dealer: items.dealerName,
          company: items.companyName,
          mpg: Number(payloadValue?.mpg) >= 0 ? payloadValue?.mpg : items?.mpg,
          fuelId: payloadValue?.fuelId ? payloadValue?.fuelId : items.fuelId,
          groupType: groupTypeValue,
          driverName: driverName ? driverName : items.driverName,
        };
        payload11.push(payload);
      });
      await multipleFleetUpdate(payload11);
    } else if (payloadValues) {
      let payload11 = [];
      selectedRows?.map((items) => {
        let payload = {
          typeId: items.typeId,
          fleetNo: items.fleetNo,
          year: items.year,
          totalMiles: items.totalMiles,
          make: items.make,
          model: modelValueData?.model,
          vin: items.vin,
          stateCode: items.stateCode,
          dealer: items.dealerName,
          company: items.companyName,
          mpg: items?.mpg,
          fuelId: items.fuelId,
          groupType: groupTypeValue,
          driverName: items.driverName,
        };
        payload11.push(payload);
      });
      await multipleFleetUpdate(payload11);
    } else if (payloadValue) {
      selectedRows?.map((items, index) => {
        let payload = {
          typeId: type ? type : items.typeId,
          fleetNo: items.fleetNo,
          year: yearValue ? yearValue : items.year,
          totalMiles: items.totalMiles,
          make: items.make,
          model: items.model,
          vin: items.vin,
          fuelId: payloadValue?.fuelId ? payloadValue?.fuelId : items.fuelId,
          stateCode: stateValue ? stateValue : items.stateCode,
          dealer: items.dealerName,
          company: items.companyName,
          mpg: Number(payloadValue?.mpg) >= 0 ? payloadValue?.mpg : items?.mpg,
          groupType: groupTypeValue,
          age: payloadValue?.age ? payloadValue?.age : items.age,
          driverName: driverName ? driverName : items.driverName,
        };
        payload11.push(payload);
      });
      await multipleFleetUpdate(payload11);
    } else {
      showToast(FleetListMessage.selectionValue);
    }

    const payloadValuesFinal = payloadValues ? payloadValues : payloadValue;
    if (payloadValuesFinal?.updateIds?.length > 0) {
      await multipleFleetListApi({
        values: payloadValuesFinal,
        newAdd: payload,
      });
      closeModal("save");
    }
  };
  const handleChangeFleet = async (event, value) => {
    let editPayload = {};
    let ids = selectedRows?.map((items) => items.fleetId);
    if (modalContent === "Set Type") {
      let cpmCategory = getCpmCategory(value.name);
      setTypeId(value?.id);
      editPayload = {
        typeId: value?.id,
        cpmCategory: cpmCategory,
      };
    } else if (modalContent === "Set Fuel") {
      editPayload = {
        fuelId: value?.id,
      };
    } else if (modalContent === "Duplicate") {
      editPayload = {
        duplicate: value?.id,
      };
    } else if (modalContent === "Set Drive Train") {
      editPayload = {
        driveTrainId: value?.id,
      };
    } else if (modalContent === "Set Year") {
      const age1 = new Date().getFullYear() - value.year;
      editPayload = {
        yearId: value?.id,
        age: age1,
      };
    } else if (modalContent === "Set Make") {
      editPayload = {
        makeId: value?.id,
      };
    } else if (modalContent === "Set Model") {
      editPayload = {
        modelId: value?.modelId,
        model: value?.model,
        mpg: value?.mpg,
      };
    } else if (modalContent === "Set Location") {
      editPayload = {
        stateId: value?.id,
      };
      setStateValue(value?.code);
    }
    editPayload = {
      ...editPayload,
      companyId: companyId,
      updateIds: ids,
      dealerId: dealerNameHeader?.id,
    };
    setPayloadValue(editPayload);
  };

  const handleChangeMPG = (e) => {
    let mpgValue = e.target.value ? e.target.value : null;
    setMpgValue(mpgValue);
  };

  useEffect(() => {
    if (mpgValue >= 0) {
      let ids = selectedRows?.map((items) => items.fleetId);
      let payload = {
        companyId: companyId,
        updateIds: ids,
        mpg: Number(mpgValue),
        dealerId: dealerNameHeader?.id,
      };
      setPayloadValue(payload);
    }
  }, [selectedRows, mpgValue]);

  const handleChangeGroupType = (e) => {
    let ids = selectedRows?.map((items) => items.fleetId);
    setGroupTypeValue(e.target.value);
    let payload = {
      companyId: companyId,
      updateIds: ids,
      groupType: e.target.value,
      dealerId: dealerNameHeader?.id,
    };
    setPayloadValue(payload);
  };

  const handleDriverName = (e) => {
    let ids = selectedRows?.map((items) => items.fleetId);
    const driver = e.target.value;
    setDriverName(driver);
    let payload = {
      companyId: companyId,
      updateIds: ids,
      driverName: driver,
      dealerId: dealerNameHeader?.id,
    };
    setPayloadValue(payload);
  };
  useEffect(() => {
    if (modalContent === "Set Model" && selectedRows?.length > 0) {
      let selectRow = selectedRows[0];
      setYearValue(selectRow.yearId);
      setMakeValue(selectRow.makeId);
    }
  }, [selectedRows, modalContent]);
  return (
    <Box>
      <Drawer
        open={!!modalContent}
        onClose={closeModal}
        // open={isModalOpen}
        // onClose={closeModal}
        maxWidth="sm"
        anchor="bottom"
        fullHeight
        style={{ top: "100px ", bottom: "56px !important" }}
        className="DrawerName"
        // onChange={handleChangeFleet}
      >
        <DialogTitle id="modal-title" className={classes.dailogTitle}>
          {modalContent}
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText id="modal-description">
            {modalContent !== "Set All" ? (
              <>
                <Box className={classes.actionDialog}>
                  <Box className={classes.dailogTitleInput}>{modalContent}</Box>
                  {modalContent === "Set MPG" ? (
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      sx={{ width: "100%" }}
                      value={mpgValue}
                      onChange={handleChangeMPG}
                    />
                  ) : modalContent === "Set Group Type" ? (
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      sx={{ width: "100%" }}
                      value={groupTypeValue}
                      onChange={handleChangeGroupType}
                    />
                  ) : modalContent === "Set DriverName" ? (
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      sx={{ width: "100%" }}
                      value={driverName}
                      onChange={handleDriverName}
                    />
                  ) : modalContent === "Set Location" ? (
                    <Box style={{ width: "75%" }}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={dropdownValue}
                        fullWidth
                        loading={dropdownValue}
                        loadingText={"Fetching...."}
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.id}>
                              {option.name}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" />
                        )}
                        onChange={handleChangeFleet}
                      />
                    </Box>
                  ) : modalContent === "Set Model" ? (
                    <ModelSelect
                      makeValue={makeValue}
                      modelValueData={dropdownValue}
                      setModelValueData={setModelValueData}
                      setMpgData={setMpgData}
                      yearValue={yearValue}
                      setModelCsv={setModelCsv}
                      width={500}
                      setNewAdd={setNewAdd}
                      actionModel={true}
                    />
                  ) : (
                    <Box style={{ width: "75%" }}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={dropdownValue}
                        fullWidth
                        loading={dropdownValue?.length > 0 ? false : true}
                        loadingText={"Fetching...."}
                        getOptionLabel={(option) => option[keyValue]}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.id}>
                              {option[keyValue]}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" />
                        )}
                        onChange={handleChangeFleet}
                      />
                    </Box>
                  )}
                </Box>
                <Box className={classes.actionButtonDialog}>
                  <Button
                    onClick={() => closeModal()}
                    className={classes.addActionButton}
                  >
                    Back
                  </Button>
                  <Button
                    className={classes.addActionButton}
                    onClick={handleClickAuto}
                  >
                    Save
                  </Button>
                </Box>
              </>
            ) : (
              modalContent === "Set All" && (
                <>
                  <ActionFormFleetList
                    dropdownValue={dropdownValue}
                    closeModal={closeModal}
                    selectedRows={selectedRows}
                    dealerNameHeader={dealerNameHeader}
                    companyId={companyId}
                  />
                </>
              )
            )}
          </DialogContentText>
        </DialogContent>
      </Drawer>
    </Box>
  );
};

export default AddActionDialog;
