import moment from "moment";

export const getCurrentLocalDate = () => {
  const date = new Date();

  const year = date.getFullYear();

  // Months are zero-based in JavaScript, so we add 1 and pad with leading zero if needed
  const month = String(date.getMonth() + 1).padStart(2, "0");

  // Pad day with leading zero if needed
  const day = String(date.getDate()).padStart(2, "0");

  // Pad hours with leading zero if needed
  const hours = String(date.getHours()).padStart(2, "0");

  // Pad minutes with leading zero if needed
  const minutes = String(date.getMinutes()).padStart(2, "0");

  // Pad seconds with leading zero if needed
  const seconds = String(date.getSeconds()).padStart(2, "0");

  // Pad milliseconds with leading zeros if needed
  const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

  // Construct the formatted date string
  return new Date(
    `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`
  );
};

export const dateFormatSql = (date) => {
  if (date) {
    return moment(date).format("YYYY-MM-DDTHH:mm:00.000[Z]");
  }
};
