import React, { useEffect, useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import { MakeSelect } from "../MakeSelect";
import { ModelSelect } from "../ModelSelect";
import { CommonSelect } from "../CommonSelect";
import { YearSelect } from "../YearSelect";
import { AdminPageStyle } from "../../pages/admin/style";
import { useNewVehicle } from "../../common/hooks";
import { layoutStyle } from "../../pages/layout-ui/style";

export const ActionFormFundingNewVehicle = ({
  dropdownValue,
  closeModal,
  selectedRows,
  dealerId,
  companyId,
}) => {
  const { multipleNewVehicleApi } = useNewVehicle();
  const [yearValue, setYearValue] = useState();
  const [makeValue, setMakeValue] = useState();
  const [makeName, setMakeName] = useState();
  const [modelValueData, setModelValueData] = useState({
    modelId: null,
    model: "",
  });
  const [newAdd, setNewAdd] = useState("");
  const [mpgData, setMpgData] = useState(0);
  const [modelCsv, setModelCsv] = useState();
  const [fuelId, setFuelId] = useState();
  const [ageCount, setAgeCount] = useState();
  const [newResaleValue, setNewResaleValue] = useState(0);
  const [newCostValue, setNewCostValue] = useState(0);
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [residualValue, setResidualValue] = useState(0);
  const [fundValue, setFundValue] = useState();
  const [equityGain, setEquityGain] = useState(0);

  const classes = layoutStyle();
  const handleYearChange = (event, value) => {
    let data = event.target.value;
    let date = new Date().getFullYear();
    const filterdYear = dropdownValue?.yearArray?.filter(
      (yr) => yr?.id === data
    )?.[0];
    if (data) {
      setYearValue(data);
      if (filterdYear?.year) {
        let age = date - filterdYear.year;
        setAgeCount(age);
      }
    }
  };
  // do not remove the below commented code
  const handleFuelChange = (event) => {
    let data = event.target.value;
    setFuelId(data);
  };

  const handleFundChange = (event) => {
    let data = event.target.value;
    setFundValue(data);
  };

  const handleMpgChange = (e) => {
    if (e.target.value) {
      setMpgData(Number(e.target.value));
    } else {
      setMpgData(null);
    }
  };

  const handlePaymentAmount = (event) => {
    setPaymentAmount(event.target.value);
  };

  const handleCost = (event) => {
    setNewCostValue(event.target.value);
  };

  const handleEstimatedResaleValue = (event) => {
    setNewResaleValue(event.target.value);
  };

  const handleResidual = (event) => {
    setResidualValue(Number(event.target.value));
  };

  useEffect(() => {
    let equityGain = newResaleValue - newCostValue * (residualValue / 100);
    // if (fundValue > 1) {
    //   setEquityGain(0);
    // } else {
    setEquityGain(equityGain);
    // }
  }, [newResaleValue, newCostValue, residualValue, fundValue]);

  const handleClickAuto = async () => {
    let ids = selectedRows?.map((items) => items.id);
    let editPayload = {
      updateIds: ids.map((item) => ({
        id: item,
        yearId: yearValue,
        makeId: makeValue,
        modelId: modelValueData?.modelId,
        newFuelId: fuelId,
        fundingTypeId: fundValue,
        mpg: mpgData > 0 ? mpgData : item.mpg,
        paymentAmount:
          paymentAmount > 0 ? Number(paymentAmount) : item.paymentAmount,
        newVehicleCost:
          newCostValue > 0 ? Number(newCostValue) : item.newCostValue,
        newEstimatedResaleValue:
          newResaleValue > 0 ? Number(newResaleValue) : item.newResaleValue,
        residualPercentage:
          residualValue > 0 ? Number(residualValue) : item.residualValue,
        equityGain: equityGain ? equityGain : item.equityGain,
      })),
    };
    const finalPayload = {
      ...editPayload,
      companyId: companyId,
      dealerId: dealerId,
    };
    let payload = {
      year: yearValue,
      make: makeValue,
      modelId: modelValueData?.modelId,
      model: modelValueData?.model,
      newAdd: newAdd,
    };
    await multipleNewVehicleApi({ values: finalPayload, newAdd: payload });
    closeModal();
  };
  useEffect(() => {
    if (modelValueData === null) {
      setMpgData(0);
    }
  }, [modelValueData]);

  return (
    <>
      <Box className={classes.divMt10}>
        <Box className={classes.actionDialogCommon}>
          <Box className={classes.dailogTitleInput}>New Vehicle Year</Box>
          <YearSelect
            yearValue={yearValue}
            handleYearChange={handleYearChange}
            setYear={""}
            width={500}
          />
        </Box>

        <Box className={classes.actionDialogCommon}>
          <Box className={classes.dailogTitleInput}>New Vehicle Make</Box>
          <MakeSelect
            setMakeValue={setMakeValue}
            setMakeName={setMakeName}
            setModelValueData={setModelValueData}
            width={500}
            setMpgData={setMpgData}
          />
        </Box>
        <Box className={classes.actionDialogCommon}>
          <Box className={classes.dailogTitleInput}>New Vehicle Model</Box>
          <ModelSelect
            makeValue={makeValue}
            modelValueData={modelValueData}
            setModelValueData={setModelValueData}
            setMpgData={setMpgData}
            yearValue={yearValue}
            setModelCsv={setModelCsv}
            width={500}
            newAdd={newAdd}
            setNewAdd={setNewAdd}
          />
        </Box>
        {/* do not remove the below commented code */}
        {/* <Box className={classes.actionDialogCommon}>
          <Box className={classes.dailogTitleInput}>New Vehicle Fuel </Box>
          <CommonSelect
            className={classes.textinput}
            handleChange={handleFuelChange}
            menuProp={""}
            option={dropdownValue?.fuelListArray}
            value={fuelId}
            width={500}
          />
        </Box> */}
        <Box className={classes.actionDialogCommon}>
          <Box className={classes.dailogTitleInput}>Funding Type</Box>
          <CommonSelect
            className={classes.textinput}
            handleChange={handleFundChange}
            menuProp={""}
            option={dropdownValue?.fundListArray}
            value={fundValue}
            width={500}
          />
        </Box>
        <Box className={classes.actionDialogCommon}>
          <Box className={classes.dailogTitleInput}>New Vehicle MPG</Box>
          <TextField
            id="standard-basic"
            variant="standard"
            type="number"
            className={classes.textinput}
            value={mpgData}
            onChange={handleMpgChange}
          />
        </Box>
        <Box className={classes.actionDialogCommon}>
          <Box className={classes.dailogTitleInput}>Payment Amount</Box>
          <TextField
            id="standard-basic"
            variant="standard"
            type="number"
            className={classes.textinput}
            value={paymentAmount}
            onChange={handlePaymentAmount}
          />
        </Box>
        <Box className={classes.actionDialogCommon}>
          <Box className={classes.dailogTitleInput}>New Vehicle Cost</Box>
          <TextField
            id="standard-basic"
            variant="standard"
            type="number"
            className={classes.textinput}
            value={newCostValue}
            onChange={handleCost}
          />
        </Box>
        <Box className={classes.actionDialogCommon}>
          <Box className={classes.dailogTitleInput}>
            New Estimated Resale Value
          </Box>
          <TextField
            id="standard-basic"
            variant="standard"
            type="number"
            className={classes.textinput}
            value={newResaleValue}
            onChange={handleEstimatedResaleValue}
          />
        </Box>
        <Box className={classes.actionDialogCommon}>
          <Box className={classes.dailogTitleInput}>Residual %</Box>
          <TextField
            id="standard-basic"
            variant="standard"
            type="number"
            className={classes.textinput}
            defaultValue={residualValue}
            onChange={handleResidual}
          />
        </Box>
        <Box className={classes.actionButtonDialog}>
          <Button
            onClick={() => closeModal()}
            className={classes.addActionButton}
          >
            Back
          </Button>
          <Button className={classes.addActionButton} onClick={handleClickAuto}>
            Save
          </Button>
        </Box>
      </Box>
    </>
  );
};
