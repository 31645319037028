import React, { useEffect, useState } from "react";
import { useModel } from "../common/hooks";
import { Autocomplete, createFilterOptions, TextField } from "@mui/material";
import { AdminPageStyle } from "../pages/admin/style";

export const ModelSelect = ({
  makeValue,
  yearValue,
  editData,
  setModelValueData,
  setMpgData,
  modelValueData,
  setModelCsv,
  width,
  newFleetNo,
  newAdd,
  setNewAdd,
  actionModel,
}) => {
  const { modelData, getModelList, getModelListData, modelCount } = useModel();
  const classes = AdminPageStyle();
  const filter = createFilterOptions();

  const handleModelChange = (event, value) => {
    const dataValueId = value?.modelId;
    const dataValueModel = value?.model;
    let filterdData;
    if (editData && editData?.modelId == dataValueId) {
      filterdData = editData;
    } else {
      filterdData = modelData?.filter(
        (model) => model?.modelId === dataValueId
      )?.[0];
    }
    if (dataValueId && dataValueModel && dataValueId != "Add") {
      setModelValueData({
        modelId: dataValueId,
        model: dataValueModel,
      });
      setModelCsv(value?.model);
      setMpgData(filterdData?.mpg);
    } else if (dataValueModel && dataValueId == "Add") {
      let lengthModel;
      if (modelCount > 0) {
        lengthModel = modelCount;
      } else {
        lengthModel =
          modelData && modelData?.length > 0 ? modelData[0]?.totalCount : 0;
      }
      setModelValueData({
        modelId: lengthModel + 1,
        model: dataValueModel,
      });
      setNewAdd("Add");
      setMpgData(0);
      setModelCsv(value?.model);
    } else {
      setModelValueData();
      setMpgData(0);
    }
  };

  useEffect(() => {
    if (modelData?.length > 0 && editData?.modelId && newFleetNo) {
      const modelValue = modelData?.filter(
        (items) => items.modelId === editData?.modelId
      )[0];
      setModelValueData(modelValue);
    } else {
      getModelListData();
    }
  }, [modelData, editData]);
  useEffect(() => {
    if ((makeValue?.id || makeValue) && yearValue) {
      getModelList({
        makeId: makeValue?.id ? makeValue?.id : makeValue,
        yearId: yearValue,
      });
    }
  }, [makeValue, yearValue, editData]);
  return (
    <Autocomplete
      id="combo-box-demo"
      disablePortal
      disabled={!makeValue || !yearValue}
      value={modelValueData}
      className={classes.textinput}
      sx={{
        width: width ? width : "148px !important",
        margin: actionModel ? "0px auto" : 0,
      }}
      onChange={handleModelChange}
      options={modelData}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue === option.model
        );
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            modelId: "Add",
            model: inputValue,
          });
        }
        return filtered;
      }}
      getOptionLabel={(option) => {
        return option.model || "";
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.modelId}>
            {option.model}
          </li>
        );
      }}
      renderInput={(params, option) => (
        <TextField {...params} variant="standard" />
      )}
      // onKeyDown={(e) => {
      //   if (e.key === "Enter") {
      //     e.preventDefault();
      //     const model = modelData?.filter(
      //       (model) => model?.model === e.target.value
      //     );
      //     let payload = {
      //       modelId: null,
      //       model: "",
      //     };
      //     if (model.length > 0) {
      //       payload = {
      //         modelId: model[0]?.modelId,
      //         model: model[0]?.model,
      //       };
      //     } else {
      //       payload = {
      //         modelId: "Add",
      //         model: e.target.value,
      //       };
      //     }
      //     handleModelChange(e, payload);
      //   }
      // }}
    />
  );
};
