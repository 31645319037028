import {
  Apartment,
  AssessmentOutlined,
  CarRental,
  Chat,
  DirectionsCarFilled,
  Fitbit,
  HelpOutline,
  ImportContacts,
  LocalOffer,
  ManageAccounts,
  MapsHomeWorkRounded,
  PersonRounded,
  ShowChartRounded,
} from "@mui/icons-material";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LocalAtmRoundedIcon from "@mui/icons-material/LocalAtmRounded";
import LocalGasStationOutlinedIcon from "@mui/icons-material/LocalGasStationOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";

export const navItems = [
  { path: "dashboard", name: "Dashboard", icon: <DashboardOutlinedIcon /> },
  {
    // path: "fleet-list",
    name: "Fleet",
    icon: <LocalShippingOutlinedIcon />,
    subMenu: [
      {
        path: "fleet-list",
        name: "Fleet List",
        icon: <ListAltIcon />,
      },
      {
        path: "replacement-schedule",
        name: "Replacement Schedule",
        icon: <CalendarMonthIcon />,
      },
      {
        path: "maintenance-analysis",
        name: "Maintenance Analysis",
        icon: <CarRepairIcon />,
      },
      {
        path: "fuel-analysis",
        name: "Fuel Analysis",
        icon: <AnalyticsOutlinedIcon />,
      },
      {
        path: "funding-newvehicle",
        name: "Funding/New Vehicle",
        icon: <LocalAtmRoundedIcon />,
      },
    ],
  },
  {
    path: "analysis-results",
    name: "Analysis Results",
    icon: <SignalCellularAltOutlinedIcon />,
  },
  {
    path: "fuel-price",
    name: "Fuel Price",
    icon: <LocalGasStationOutlinedIcon />,
  },
  { path: "fuel-economy", name: "Fuel Economy", icon: <ShowChartRounded /> },
  // { path: "reports", name: "Reports", icon: <DescriptionOutlinedIcon /> },
  {
    path: "",
    name: "Admin",
    icon: <Person2OutlinedIcon />,
    subMenu: [
      {
        path: "manage-companies",
        name: "Manage Companies",
        icon: <Apartment />,
        // icon: <img src={ManageCompanyImg} width={22} height={22} />,
        ssubMenu: [
          {
            path: "manage-dealer",
            name: "Manage Dealer",
            icon: <MapsHomeWorkRounded />,
          },
          {
            path: "manage-user",
            name: "Manage User",
            icon: <MapsHomeWorkRounded />,
          },
          {
            path: "manage-vehicle",
            name: "Manage Vehicle",
            icon: <MapsHomeWorkRounded />,
          },
        ],
      },
      {
        path: "manage-dealer",
        name: "Manage Dealer",
        icon: <LocalOffer />,
        // icon: <img src={DealerImg} width={22} height={22} />,
      },
      {
        path: "manage-user",
        name: "Manage User",
        icon: <ManageAccounts />,
        // icon: <img src={UserImg} width={22} height={22} />,
      },
      {
        path: "manage-vehicle",
        name: "Manage Vehicle",
        icon: <DirectionsCarFilled />,
        ssubMenu1: [
          {
            path: "make",
            name: "Make",
            icon: <CarRental />,
          },
          {
            path: "model",
            name: "Model",
            icon: <MapsHomeWorkRounded />,
          },
          {
            path: "year",
            name: "Year",
            icon: <MapsHomeWorkRounded />,
          },
        ],
        // icon: <img src={VehicleImg} width={22} height={22} />,
      },
    ],
  },
  {
    path: "analysis-data",
    name: "Analysis Data",
    icon: <AssessmentOutlined />,
  },
  {
    path: "userQueries",
    name: "User Queries",
    icon: <HelpOutline />,
  },
  {
    path: "userLogs",
    name: "User Activities",
    icon: <Fitbit />,
  },
  // {
  //   path: "userGuide",
  //   name: "User Guide",
  //   icon: <ImportContacts />,
  // },
  //will use in future
  // {
  //   path: "ComparisionCTO",
  //   name: "Questions",
  //   icon: <Chat />,
  // },
];

export const navDealerItems = [
  { path: "dashboard", name: "Dashboard", icon: <DashboardOutlinedIcon /> },
  {
    // path: "fleet-list",
    name: "Fleet",
    icon: <LocalShippingOutlinedIcon />,
    subMenu: [
      {
        path: "fleet-list",
        name: "Fleet List",
        icon: <ListAltIcon />,
      },
      {
        path: "replacement-schedule",
        name: "Replacement Schedule",
        icon: <CalendarMonthIcon />,
      },
      {
        path: "maintenance-analysis",
        name: "Maintenance Analysis",
        icon: <CarRepairIcon />,
      },
      {
        path: "fuel-analysis",
        name: "Fuel Analysis",
        icon: <AnalyticsOutlinedIcon />,
      },
      {
        path: "funding-newvehicle",
        name: "Funding/New Vehicle",
        icon: <LocalAtmRoundedIcon />,
      },
    ],
  },
  {
    path: "analysis-results",
    name: "Analysis Results",
    icon: <SignalCellularAltOutlinedIcon />,
  },
  {
    path: "fuel-price",
    name: "Fuel Price",
    icon: <LocalGasStationOutlinedIcon />,
  },
  { path: "fuel-economy", name: "Fuel Economy", icon: <ShowChartRounded /> },
  // { path: "reports", name: "Reports", icon: <DescriptionOutlinedIcon /> },

  {
    path: "manage-dealer-companies",
    name: "Manage Companies",
    icon: <Apartment />,
  },
  {
    path: "manage-userDealer",
    name: "Manage User",
    icon: <PersonRounded />,
  },
  {
    path: "analysis-data",
    name: "Analysis Data",
    icon: <AssessmentOutlined />,
  },
  {
    path: "helpDesk",
    name: "Help Desk",
    icon: <HelpOutline />,
  },
  //will use in future
  // {
  //   path: "ComparisionCTO",
  //   name: "Questions",
  //   icon: <Chat />,
  // },
  // {
  //   path: "userGuide",
  //   name: "User Guide",
  //   icon: <ImportContacts />,
  // },
];
