import React, { useState, createContext, useCallback } from "react";
import { SupportAPi } from "../api";
import { showToast } from "../../components/Toast";
import { PopupMessage } from "../constants/popupMessage";

/**
 * State list context
 */
export const SupportContext = createContext();

/**
 * State list provider
 */
export const SupportProvider = ({ children }) => {
  const getSupportApi = async (values) => {
    const response = await SupportAPi.supportData(values);
    showToast(PopupMessage.emailSent, "success");
    return response.data;
  };

  const contextValue = {
    supportData: useCallback((request) => {
      getSupportApi(request);
    }, []),
  };

  return (
    <SupportContext.Provider value={contextValue}>
      {children}
    </SupportContext.Provider>
  );
};
