import React, { useState, createContext, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { localStorageItemNames } from "../constants";
import { AccountApi, UserLogsAPi } from "../api";
import { showToast } from "./../../components/Toast";
import { dateFormatSql, getCurrentLocalDate } from "../services/formatDates";
import moment from "moment";
import { typeConstant } from "../constants/commonFunction";

/**
 * Authentication context
 */
export const AuthContext = createContext();

/**
 * Authentication data provider
 */
export const AuthProvider = ({ children }) => {
  /**
   * use notification context
   */
  //   const { addMessage, addServerErrors } = useContext(NotificationContext);
  const url = window.location.pathname;
  /**
   * use history hooks
   */
  const history = useNavigate();

  /**
   * user data state management
   */
  const [user, setUser] = useState();
  const [userLoggin, setUserLoggin] = useState();
  const [userId, setUserId] = useState();
  const [loading, setLoading] = useState(false);

  /**
   * logged in state
   */
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem(localStorageItemNames.user_token) ? true : false
  );
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onLoggedIn = async ({ email, password, userName }) => {
    setLoading(true);
    const response = await AccountApi.verifyOtp({
      otp: "",
      userName: userName,
      password: password,
      email: email,
      isOTP: false,
    });
    if (response?.data?.data && response.data.succeeded === true) {
      setUserId(response?.data?.data?.userId);
      localStorage.setItem(
        localStorageItemNames.user_token,
        response?.data?.data?.token
      );
      localStorage.setItem(
        localStorageItemNames.userID,
        response?.data?.data?.userId
      );
      localStorage.setItem("name", response?.data?.data?.userName);
      localStorage.setItem("type", response?.data?.data?.type);
      localStorage.setItem("typeId", response?.data?.data?.typeId);
      setLoggedIn(true);
      setUserLoggin(response?.data?.data);
      // if(user.data){
      // history('/Dashboard')
      // }
      //   addMessage({ message: response?.data?.msg, type: "success" });
      history("/dashboard");
      setLoading(false);
    } else {
      showToast(response?.data?.messages[0]);
      //   addMessage({ message: "User logged in failed." });
      setLoading(false);
    }
    // setLoading(true);
    // const response = await AccountApi.login({
    //   email: email,
    //   password: password,
    //   userName: userName,
    // });
    // if (response?.data) {
    //   setEmail(email);
    //   setUserName(userName);
    //   setPassword(password);
    //   if (response?.data?.messages?.length > 0) {
    //     showToast(response?.data?.messages[0]);
    //     setLoading(false);
    //     history("/otpScreen");
    //   }
    // } else {
    //   showToast("Please check your credentials!");
    //   setLoading(false);
    // }

    // return response.data;
  };

  const onVerifyOtp = async (otp) => {
    setLoading(true);
    const response = await AccountApi.verifyOtp({
      otp: otp,
      userName: userName,
      password: password,
      email: email,
    });
    if (response?.data?.data) {
      setUserId(response?.data?.data?.userId);
      localStorage.setItem(
        localStorageItemNames.user_token,
        response?.data?.data?.token
      );
      localStorage.setItem(
        localStorageItemNames.userID,
        response?.data?.data?.userId
      );
      localStorage.setItem("name", response?.data?.data?.userName);
      localStorage.setItem("type", response?.data?.data?.type);
      localStorage.setItem("typeId", response?.data?.data?.typeId);
      setLoggedIn(true);
      setUserLoggin(response?.data?.data);
      // if(user.data){
      // history('/Dashboard')
      // }
      //   addMessage({ message: response?.data?.msg, type: "success" });
      history("/dashboard");
      setLoading(false);
    } else {
      showToast("Please check your credentials!");
      //   addMessage({ message: "User logged in failed." });
      setLoading(false);
    }
  };

  const getUser = async () => {
    let userId = localStorage.getItem(localStorageItemNames.userID);
    // const response = await AccountApi.getUser({_id:userId});
    // if (response && response.data) {
    // const { data } = response.data;
    setUser();
    // } else {
    // setUser(null);
    // }
  };
  const onLogout = async () => {
    const userId = localStorage.getItem(localStorageItemNames.userID);
    const type = localStorage.getItem(localStorageItemNames.type);
    const endDate = getCurrentLocalDate();
    if (type?.toLowerCase() === typeConstant.dealer) {
      await UserLogsAPi.createUserLogs({
        userId: userId,
        endDate: dateFormatSql(endDate),
      });
    }
    localStorage.removeItem(localStorageItemNames.user_token);
    localStorage.clear();
    setLoggedIn(false);
    setUser(null);
    history("/");
  };

  useEffect(() => {
    const userId = localStorage.getItem(localStorageItemNames.user_token);
    if (loggedIn || userId) {
      if (url === "/") {
        history("/dashboard");
      } else {
        history(url);
      }
    }
  }, [loggedIn]);

  const contextValue = {
    loggedIn,
    user,
    userLoggin,
    userId,
    loading,
    setLoggedIn,
    setLoading,
    setUser,
    setUserId,
    setUserLoggin,
    state: (userData) => setUser(userData),
    onLoggedIn,
    onLogout,
    onVerifyOtp,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
