import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AdminPageStyle } from "../../pages/admin/style";
import moment from "moment";
import {
  getCurrencyValue,
  removeCurrency,
} from "../../common/services/number-service";
import { useForm } from "react-hook-form";

export const Paramter = ({
  vehicleInfoValue,
  typeStateById,
  avgFuelPrice,
  fuelAnalysis,
  fuelListFilter,
  setAvgFuelPrice,
}) => {
  const classes = AdminPageStyle();
  const { register } = useForm();

  const handleFuelPriceChange = (event) => {
    setAvgFuelPrice(event.target.value);
  };
  return (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={classes.textGeneralInfo}
        >
          parameter
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>
                  Fuel Price Location
                </span>
                <span className={classes.fleetListGenInfoInputText}>
                  {fuelListFilter ? fuelListFilter?.fuelPriceLocation : null}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>
                  Fuel price Date
                </span>
                {fuelListFilter?.date
                  ? moment(fuelListFilter?.date)
                      .set("hour", 7)
                      .set("minute", 0)
                      .set("second", 0)
                      .format("DD-MM-YYYY hh:mm A")
                  : moment()
                      .set("hour", 7)
                      .set("minute", 0)
                      .set("second", 0)
                      .format("DD-MM-YYYY hh:mm A")}
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>
                  Avg Fuel Price
                </span>
                <span className={classes.fuelPriceSign}>$</span>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  value={avgFuelPrice}
                  type="number"
                  onChange={handleFuelPriceChange}
                  className={classes.textinput}
                  // {...register("averageFuelPrice", { valueAsNumber: true })}
                />
              </Box>
            </Box>
            {/* <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>
                  Company Name
                </span>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  value={
                    vehicleInfoValue ? fuelAnalysis?.fuelCompanyName : null
                  }
                  className={classes.textinput}
                  {...register("fuelCompanyName")}
                />
              </Box>
            </Box> */}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
