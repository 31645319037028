import * as React from "react";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import { layoutStyle } from "../pages/layout-ui/style";
import { WebVersion } from "../common/api/apiUris";
import { NewUpdates } from "../common/constants";

const emails = ["username@gmail.com", "user02@gmail.com"];

function SimpleDialog(props) {
  const classes = layoutStyle();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      style={{ background: "#195A7E !important" }}
    >
      <DialogTitle className={classes.whatsNewTitle}>
        <div>New Features</div>
        <div className={classes.WhatsNewersionName}>{WebVersion}</div>
      </DialogTitle>
      <div sx={{ pt: 0 }} className={classes.whatNewHeader}>
        {/* {NewUpdates?.map((item) => {
          return ( */}
        <div>
          <div className={classes.whatNewHeaderTitle}>
            1. Closest Model Dropdown:
          </div>
          <div>
            => If an exact match is found, the "Closest Model" dropdown will not
            show any options.
            <br /> => If a closest match is available, it will be displayed in
            the dropdown.
            <br /> => If no match is found, the field will remain blank.
          </div>
          <div className={classes.descriptionNewVersion}>
            <div className={classes.descriptionNewVersionAvailableModel}>
              <strong> *Available Model Column*: </strong>
            </div>
            <div className={classes.descriptionNewVersionYesNo}>
              Yes: Closest match is available in the database.
            </div>
            <div className={classes.descriptionNewVersionYesNo}>
              No: No match is available in the database.
            </div>
          </div>
        </div>
        <div>
          <div className={classes.whatNewHeaderTitle}>
            2. Fuel, Type, and Driver Name Upload via Excel:
          </div>
          <div>
            => Dealers can now upload Fuel, Type, and Driver information using
            an Excel file.
          </div>
        </div>
        <div>
          <div className={classes.whatNewHeaderTitle}>3. Filter Options:</div>
          <div>
            => A new filter feature is accessible via the burger menu next to
            each column name, allowing you to filter the displayed records.
          </div>
        </div>
        <div>
          <div className={classes.whatNewHeaderTitle}>
            4. Help Desk Support:
          </div>
          <div>
            => Support Desk page has been added.
            <br />
            => Dealers can submit queries with a detailed description and an
            image.
            <br />
            => Each query generates a ticket for immediate issue resolution.
            <br />
          </div>
        </div>
        <div>
          <div className={classes.whatNewHeaderTitle}>
            5. Add New Model Manually:
          </div>
          <div>
            => Users can manually add models by typing the model name into the
            select dropdown and clicking on it.
          </div>
        </div>
        {/* );
        })} */}
      </div>
    </Dialog>
  );
}

export default function WhatsNew({ open, setOpen }) {
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
    </div>
  );
}
