import { Box, Button } from "@mui/material";
import Switch from "@mui/material/Switch";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { replacementScheduleColumnData } from "../../common/constants/gridRowColumn";
import {
  useCompany,
  useDealer,
  useFleetList,
  useNewVehicle,
  useReplacement,
  useUser,
} from "../../common/hooks";
import { layoutStyle } from "../layout-ui/style";
import { TruckLoader } from "./truckLoader";
import { useDebounce } from "../../components/useDebounce";
import {
  actionColumnFunction,
  checkFieldColumn,
  checkbox,
  getDefaultColDef,
} from "../../common/constants/commonFunction";
import { getCompanyId } from "../../common/constants/commonFunction";
import { ReplacementHeaderButton } from "../../components/ReplacementHeaderButton";
import { TAB_CONTENT, TAB_VALUE } from "../../common/constants/popupMessage";
import {
  ReplacementYearToggle,
  YearConstant,
  yearCountValue,
} from "../../common/constants/replacementSchedule";

export const ReplacementSchedule = () => {
  const classes = layoutStyle();
  const [columnFinal, setColumnFinal] = useState([]);
  const [rowDataFinal, setRowDataFinal] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const { setSelectedNav } = useUser();
  const navigation = useNavigate();
  const {
    getReplacementListApi,
    replacementList,
    editReplacementListApi,
    setEditData,
    loading,
    editData,
    setReplacementList,
  } = useReplacement();
  const { manualCalculationApi, setNewFleetNo, multipleManualCalculationApi } =
    useDealer();
  const { companyNameHeader, dealerNameHeader } = useCompany();
  const userType = localStorage.getItem("type");
  const defaultColDef = useMemo(() => getDefaultColDef(), []);

  const [anchorElSearch, setAnchorElSearch] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [checkedValue, setCheckedValue] = useState({
    year1: false,
    year2: false,
    year3: false,
    year4: false,
    year5: false,
  });
  const { getFleetListApi, fleetList } = useFleetList();
  const { setToastClose, setNewVehicle } = useNewVehicle();
  const [countYear, setCountYear] = useState({
    year1: 0,
    year2: 0,
    year3: 0,
    year4: 0,
    year5: 0,
  });
  const onSearch = (e) => {
    debouncedSearch(e?.target?.value);
    handleClickSearch(e);
  };
  const handleClickSearch = (event) => {
    setAnchorElSearch(event.currentTarget);
  };

  const onSearchFunc = (e) => {
    setSearchValue(e[0]);
  };
  const searchData = replacementList?.filter((replacement) =>
    replacement?.fleetNo?.toLowerCase().includes(searchValue?.toLowerCase())
  );
  const debouncedSearch = useDebounce(onSearchFunc, 500);

  useEffect(() => {
    if (searchValue) {
      setRowDataFinal([...searchData]);
    } else if (replacementList?.length > 0) {
      setRowDataFinal([...replacementList]);
    } else {
      setRowDataFinal([]);
    }
  }, [searchValue, replacementList]);

  const handleEditButton = (params) => {
    const itemId = params.id;
    const fleetNo = params.fleetNo;
    setEditData(params);
    navigation("/new-fleetlist", {
      state: {
        tabValue: TAB_VALUE.REPLACEMENT_SCHEDULE,
        selectedContent: TAB_CONTENT.REPLACEMENT_SCHEDULE,
        data: params,
        id: itemId,
        fleetNo: fleetNo,
      },
    });
  };

  const onCellClicked = (event) => {
    if (
      event?.colDef?.field !== "year1" &&
      event?.colDef?.field !== "year2" &&
      event?.colDef?.field !== "year3" &&
      event?.colDef?.field !== "year4" &&
      event?.colDef?.field !== "year5"
    ) {
      let checkField = checkFieldColumn(event);
      if (checkField) {
        handleEditButton(event?.data);
      }
    }
  };

  useEffect(() => {
    setEditData(null);
    getFleetListApi({
      companyId: getCompanyId(userType, companyNameHeader),
      dealerId: dealerNameHeader?.id,
    });
    setNewFleetNo(null);
    setToastClose(false);
    setNewVehicle([]);
  }, []);

  useEffect(() => {
    if (companyNameHeader) {
      const companyId = getCompanyId(userType, companyNameHeader);
      getReplacementListApi({
        companyId: companyId,
        dealerId: dealerNameHeader?.id,
      });
    } else {
      setRowDataFinal([]);
      setReplacementList([]);
    }
  }, [companyNameHeader]);

  useEffect(() => {
    if (replacementList?.length > 0) {
      setRowDataFinal([...replacementList]);
    }
  }, [replacementList]);

  useEffect(() => {
    if (replacementScheduleColumnData) {
      const actionColumn = actionColumnFunction({
        selectedRows: selectedRows,
        handleEditButton: handleEditButton,
        deleteButton: false,
      });
      replacementScheduleColumnData?.map((items) => {
        if (
          items.field == YearConstant.year1 ||
          items.field == YearConstant.year2 ||
          items.field == YearConstant.year3 ||
          items.field == YearConstant.year4 ||
          items.field == YearConstant.year5
        ) {
          items["cellRenderer"] = (params) => (
            <Switch
              onChange={(params1) => handleSwitchButton(params, items, params1)}
              defaultChecked={params.value}
              size="small"
            />
          );
        }
      });
      setColumnFinal([
        checkbox,
        ...replacementScheduleColumnData,
        actionColumn,
      ]);
    }
  }, [replacementScheduleColumnData, selectedRows]);

  const handleSwitchButton = async (event, values, params1) => {
    const fleet = fleetList?.filter(
      (fleet) => fleet?.fleetId === event?.data?.fleetId
    );
    let fleetNo;
    if (fleet?.length > 0) {
      fleetNo = fleet[0];
    }
    let year = ReplacementYearToggle(values?.field, params1, event);
    let editPayload = {
      ...year,
      fleetNo: event?.data?.fleetNo,
      companyId: event?.data?.companyId,
      id: event?.data?.replacementId,
      dealerId: event?.data?.dealerId,
    };

    let manualCalculation = {
      year: event?.data?.year,
      totalMiles: event?.data?.totalMiles,
      make: event?.data?.make,
      model: event?.data?.model,
      vin: fleetNo?.vin,
      stateCode: fleetNo?.stateCode,
      dealer: fleetNo?.dealerName,
      company: companyNameHeader?.companyName,
      typeId: fleetNo?.typeId ? fleetNo?.typeId : event?.data?.typeId,
      fleetNo: fleetNo?.fleetNo,
      cyclingFuelEco: 0,
      replacementSchedule: {
        id: event?.data?.replacementId,
        deleted: false,
        fleetNo: fleetNo?.fleetNo,
        ...year,
      },
      mpg: fleetNo?.mpg,
      stateName: fleetNo?.state,
    };
    setCheckedValue({
      ...checkedValue,
      [values.field]: params1.target.checked,
    });
    await editReplacementListApi(editPayload);
    await manualCalculationApi(manualCalculation);
  };
  useEffect(() => {
    if (replacementList?.length > 0) {
      const { year1, year2, year3, year4, year5 } =
        yearCountValue(replacementList);
      setCountYear({
        year1: year1,
        year2: year2,
        year3: year3,
        year4: year4,
        year5: year5,
      });
      handleUpdateCalculation();
    }
  }, [replacementList]);
  const handleUpdateCalculation = async (event) => {
    const data = [];
    replacementList?.map((items) => {
      let payload = {
        companyId: companyNameHeader?.id,
        dealerId: dealerNameHeader?.id,
        company: items.companyName,
        dealer: items.dealerName,
        vin: items.vin,
        stateCode: items.stateCode,
        stateName: items.state,
        make: items.make,
        model: items.model,
        fuelId: items.fuelId,
        typeId: items.typeId,
        fleetNo: items.fleetNo,
        mpg: Number(items.mpg),
        year: items.year,
        cyclingFuelEco: items.cyclingFuelEco,
        totalMiles: items.totalMiles,
        replacementSchedule: {
          fleetNo: items.fleetNo,
          id: items.replacementId,
          year1: items.year1,
          year2: items.year2,
          year3: items.year3,
          year4: items.year4,
          year5: items.year5,
        },
      };
      data.push(payload);
    });
    await multipleManualCalculationApi(data);
  };
  const handleRowSelection = (event) => {
    setSelectedRows(event.api.getSelectedNodes().map((node) => node.data));
  };
  useEffect(() => {
    if (rowDataFinal?.length > 0 && editData == null) {
      if (replacementList?.length > 0) {
        handleUpdateCalculation();
      }
    }
  }, [replacementList]);
  return (
    <Box className={classes.mainContent}>
      <Box className={classes.gridContantFleetList}>
        <ReplacementHeaderButton
          onSearch={onSearch}
          setSelectedNav={setSelectedNav}
          countYear={countYear}
        />
        {loading ? (
          <TruckLoader />
        ) : (
          <AgGridReact
            className="ag-theme-quartz"
            onRowSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              console.log("selectedIDs", selectedIDs);
              const selectedRows = rowDataFinal.filter((row) =>
                selectedIDs.has(row.id)
              );
              setSelectedRows(selectedRows);
            }}
            rowSelection="multiple"
            suppressRowClickSelection
            pagination={true}
            rowData={rowDataFinal}
            defaultColDef={defaultColDef}
            checkboxSelection
            columnDefs={columnFinal}
            onCellClicked={onCellClicked}
            onSelectionChanged={handleRowSelection}
          />
        )}
      </Box>
    </Box>
  );
};
