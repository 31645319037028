import { Edit, Settings } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FundingNewVehicleColumnData } from "../../common/constants/gridRowColumn";
import {
  useAnalysis,
  useCompany,
  useDealer,
  useFleetList,
  useFuelAnalysis,
  useFuelList,
  useFundList,
  useMaintainance,
  useMake,
  useModel,
  useNewVehicle,
  useReplacement,
  useUser,
  useYear,
} from "../../common/hooks";
import { SearchBar } from "../../components/SearchBar";
import { layoutStyle } from "../layout-ui/style";
import { DeleteDialog } from "../../components/DeleteDialog";
import moment from "moment";
import { TruckLoader } from "./truckLoader";
import { useDebounce } from "../../components/useDebounce";
import AddActionDialogNewVehicle from "../../components/AddActionDialogNewVehicle";
import { removeCurrency } from "../../common/services/number-service";
import { showErrorToast, showToast } from "../../components/Toast";
import {
  actionColumnFunction,
  checkFieldColumn,
  checkbox,
  getDefaultColDef,
  setModalContentByAction,
  sidebarMenu,
} from "../../common/constants/commonFunction";
import { getCompanyId } from "../../common/constants/commonFunction";
import useFleetStatistics from "../../common/constants/Report-Calculation/fleetStats";
import useMaintenanceStatistics from "../../common/constants/Report-Calculation/maintenanceStats";
import useFuelStatistics from "../../common/constants/Report-Calculation/fuelStats";
import useNewVehicleStatistics from "../../common/constants/Report-Calculation/newVehicleStats";
import useFleetManagement from "../../common/constants/Report-Calculation/fleetManagement";
import { TAB_CONTENT, TAB_VALUE } from "../../common/constants/popupMessage";
import { ReplaceExistingVehicle } from "../../components/Funding-New-Vehicle/ReplaceExistingVehicle";
import {
  getNullVehicleFields,
  uniqueNullValues,
  uniqueVehicleNullValues,
} from "../../common/constants/findNullValueGrid";
import { toast } from "react-toastify";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export const FundingNewVehicle = () => {
  const classes = layoutStyle();

  const [columnFinal, setColumnFinal] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowDataFinal, setRowDataFinal] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [replaceVehicleOpen, setReplaceVehicleOpen] = useState(false);
  const [deleteValue, setDeleteValue] = useState();
  const { state } = useLocation();
  const navigation = useNavigate();
  //Generate Report
  const { getFleetListApi, fleetList, requireFields, setRequireFields } =
    useFleetList();
  const { getMaintananceApi, maintanance, setMaintanance } = useMaintainance();
  const { fuelListFilter, getFuelAnalysisApiList, setFuelListFilter } =
    useFuelAnalysis();
  const { getReplacementListApi, replacementList } = useReplacement();
  const [purchaseFinance, setPurchaseFinance] = useState(0);
  const [rowIndex, setRowIndex] = useState(0);
  const [cashOutlay, setCashOutlay] = useState(0);
  const [averageCostPerMilesMaintanance, setAverageCostPerMilesMaintanance] =
    useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [keyValue, setKeyValue] = useState("");
  const [dropdownValue, setDropDownValue] = useState([]);
  const [modalContent, setModalContent] = useState("");
  const { setSelectedNav } = useUser();
  //Generate Report End
  const { addAnalysisResultApi } = useAnalysis();
  const {
    getNewVehicleApi,
    newVehicle,
    deleteNewVehicleByIdApi,
    loading,
    setNewVehicle,
    requireNewvehicleFields,
    setRequireNewVehicleFields,
    setToastClose,
    toastClose,
    multipleApi,
  } = useNewVehicle();
  const { companyNameHeader, dealerNameHeader } = useCompany();
  const { fuelList, getFuelListApi } = useFuelList();
  const { getFundListApi, fundList } = useFundList();
  const { make, getMakeApi } = useMake();
  const { year, getYearApi } = useYear();
  const { modelData, getModelList, setModelData } = useModel();
  const [anchorElSearch, setAnchorElSearch] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const userType = localStorage.getItem("type");
  const companyId = getCompanyId(userType, companyNameHeader);
  const { setNewFleetNo } = useDealer();
  const [loadingApis, setLoadingApis] = useState(false);

  const onSearch = (e) => {
    debouncedSearch(e?.target?.value);
    handleClickSearch(e);
  };
  const handleClickSearch = (event) => {
    setAnchorElSearch(event.currentTarget);
  };
  const onSearchFunc = (e) => {
    setSearchValue(e[0]);
  };
  const searchData = rowDataFinal?.filter((fund) =>
    fund?.fleetNo?.toLowerCase().includes(searchValue?.toLowerCase())
  );
  const debouncedSearch = useDebounce(onSearchFunc, 500);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOnClose = () => {
    setAnchorEl(null);
  };
  const closeModal = () => {
    // setIsModalOpen(false);
    setModalContent("");
  };
  const handleActionSelect = (action, value, key) => {
    handleOnClose();
    setDropDownValue(value);
    setKeyValue(key);
    setModalContentByAction({
      action: action,
      setModalContent: setModalContent,
      setReplaceVehicleOpen: setReplaceVehicleOpen,
    });
  };
  useEffect(() => {
    setNewVehicle([]);
    setNewFleetNo(null);
    setMaintanance([]);
    setFuelListFilter([]);
    setModelData([]);
    getFundListApi();
    getMakeApi();
    getYearApi();
    getFuelListApi();
  }, []);
  useEffect(() => {
    if (searchValue) {
      setRowDataFinal([...searchData]);
    } else if (newVehicle?.length > 0) {
      setRowDataFinal([...newVehicle]);
    }
  }, [searchValue, newVehicle]);

  const defaultColDef = useMemo(() => getDefaultColDef(), []);

  const handleDelete = async () => {
    await deleteNewVehicleByIdApi({
      id: deleteValue,
      companyId: companyId,
    });
    setDeleteOpen(false);
  };

  const onCellClicked = (event) => {
    let checkField = checkFieldColumn(event);
    if (checkField) {
      handleEditButton(event?.data);
    }
  };

  useEffect(() => {
    if (companyNameHeader?.id) {
      getFuelAnalysisApiList({
        companyId: companyNameHeader?.id,
        dealerId: dealerNameHeader?.id,
      });
    }
  }, [newVehicle, companyNameHeader?.id]);

  useEffect(() => {
    const fetchFields = async () => {
      try {
        setLoadingApis(true);
        await Promise.all([
          getFleetListApi({
            companyId: companyId,
            dealerId: dealerNameHeader?.id,
          }),
          getMaintananceApi({
            companyId: companyId,
            dealerId: dealerNameHeader?.id,
          }),
          getReplacementListApi({
            companyId: companyId,
            dealerId: dealerNameHeader?.id,
          }),
        ]);
      } finally {
        setLoadingApis(false);
      }
    };

    if (newVehicle?.length > 0 && !multipleApi && !loading && companyId) {
      fetchFields();
    }
  }, [newVehicle]);

  useEffect(() => {
    if (companyId) {
      let payload = {
        fleetNo: "",
        companyId: companyId,
        dealerId: dealerNameHeader?.id,
      };
      getNewVehicleApi(payload);
    } else {
      setNewVehicle([]);
    }
  }, [companyId]);
  //Generate Report Start

  const {
    currentFleetSize,
    oldVehicleYear,
    avgYear,
    currentTotalMileage,
    fuelAverageMileageCache,
  } = useFleetStatistics(fleetList);

  const {
    currentTotalAnnualizedSpend,
    currentYearTotalCost,
    maintenanceYear,
    maintenanceVehicleSpend,
    maintenanceVehicleSpendMonthly,
    averageCostPerMilesMaintenance,
    avgCostPerMiles,
    oneYearTotalCostValue,
    oneYearMaintenanceCost,
    oneYearMaintenanceTotalMiles,
  } = useMaintenanceStatistics(maintanance);

  const {
    anticipatedMpgValue,
    totalFuelSpend,
    annualSpendVehicle,
    monthlySpendVehicle,
    foreCastedValue,
    currentFuelSize,
    averageFuelGallon,
    cycleFuelYearCost,
    cycleFuelYearCost2,
    cycleFuelYearCost3,
    cycleFuelYearCost4,
    cycleFuelYearCost5,
    fuelAverageMileage,
  } = useFuelStatistics(fuelListFilter, fuelAverageMileageCache);

  const {
    averageNewVehicleCost,
    paymentFundingAmount,
    monthlyLeaseCost,
    monthlyPaymentAmount,
  } = useNewVehicleStatistics(newVehicle, fleetList);

  const { rowData, proposedCycleYear, savingTotal } = useFleetManagement(
    currentFleetSize,
    replacementList,
    rowIndex,
    monthlyPaymentAmount,
    purchaseFinance,
    cashOutlay,
    newVehicle,
    maintanance,
    fuelListFilter,
    totalFuelSpend,
    fleetList,
    companyNameHeader,
    cycleFuelYearCost,
    cycleFuelYearCost2,
    cycleFuelYearCost3,
    cycleFuelYearCost4,
    cycleFuelYearCost5
  );

  //Generate Report End
  useEffect(() => {
    if (newVehicle?.length > 0) {
      setRowDataFinal(newVehicle);
    } else {
      setRowDataFinal([]);
    }
  }, [newVehicle]);

  useEffect(() => {
    if (state?.data) {
      setRowDataFinal([...state.data]);
    } else {
      setRowDataFinal(newVehicle);
    }
  }, [state?.data]);

  useEffect(() => {
    if (FundingNewVehicleColumnData && !loading && newVehicle?.length > 0) {
      const values = uniqueVehicleNullValues(newVehicle);
      if (values?.length > 0 && companyNameHeader !== null && toastClose) {
        setRequireNewVehicleFields(values);
      } else {
        setRequireNewVehicleFields([]);
      }
    } else {
      setRequireNewVehicleFields([]);
    }
    const actionColumn = actionColumnFunction({
      selectedRows: selectedRows,
      handleEditButton: handleEditButton,
      deleteButton: false,
    });
    setColumnFinal([checkbox, ...FundingNewVehicleColumnData, actionColumn]);
  }, [newVehicle, FundingNewVehicleColumnData, loading, toastClose]);

  useEffect(() => {
    if (
      requireNewvehicleFields &&
      !loading &&
      newVehicle?.length > 0 &&
      toastClose
    ) {
      const values = uniqueVehicleNullValues(newVehicle);
      const arrayJson = getNullVehicleFields(values);
      const requireField = getNullVehicleFields(requireFields);
      if (arrayJson?.length > 0 || requireField?.length > 0) {
        showErrorToast(
          <>
            {arrayJson && (
              <>
                <div style={{ fontWeight: 600, marginBottom: 5 }}>
                  NewVehicle: Require fields
                </div>
                <div style={{ marginBottom: 10 }}>{arrayJson}</div>
              </>
            )}
            {requireField && requireField?.length > 0 && (
              <>
                <div style={{ fontWeight: 600, marginBottom: 5 }}>
                  Fleet List: Require fields
                </div>
                <div>{requireField}</div>
              </>
            )}
          </>,
          companyNameHeader == null ? true : false
        );
      } else {
        toast.dismiss();
      }
    } else {
      toast.dismiss();
    }
    return () => {};
  }, [requireNewvehicleFields]);

  const handleAddButton = () => {
    navigation("/new-fleetlist", {
      state: {
        tabValue: TAB_VALUE.FLEET_LIST,
        selectedContent: TAB_CONTENT.FLEET_LIST,
      },
    });
  };
  const handleEditButton = (params) => {
    navigation("/new-fleetlist", {
      state: {
        tabValue: TAB_VALUE.FUNDING_NEW_VEHICLE,
        selectedContent: TAB_CONTENT.FUNDING_NEW_VEHICLE,
        data: params,
      },
    });
  };
  const handleNext = async (event) => {
    let vehicleSpend = currentTotalAnnualizedSpend / currentFleetSize;
    let costPerMiles =
      currentTotalAnnualizedSpend / averageCostPerMilesMaintanance;
    let avgMpgValue = anticipatedMpgValue / currentFleetSize;
    let year1Monthly = oneYearTotalCostValue / currentFleetSize / 12;
    let monthlyLeaseCost1 = monthlyLeaseCost / currentFleetSize;
    let foreCastedValue1 = foreCastedValue / currentFleetSize;
    let perCostAvg = vehicleSpend / fuelAverageMileageCache;
    let fleet = {
      currentFleetSize: currentFleetSize,
      avgVehicleYear: Math.floor(avgYear || 0),
      avgNewVehiclePrice: averageNewVehicleCost,
      currentMileage: currentTotalMileage,
      currentCycle: oldVehicleYear,
      annualMiles: fuelAverageMileageCache,
      companyId: companyId,
    };
    let maintainanceCost = {
      totalAnnualized: currentTotalAnnualizedSpend,
      vehicleSpend: vehicleSpend,
      vehicleSpendMonth: vehicleSpend / 12,
      costPerMile: perCostAvg,
      companyId: companyId,
    };
    let fuelCost = {
      avgMpg: avgMpgValue,
      avgAnnualMiles: fuelAverageMileageCache,
      numberOfVehicle: currentFuelSize,
      costPerGallon: averageFuelGallon,
      totalFuelSpend: totalFuelSpend,
      annualSpendVehicle: annualSpendVehicle,
      monthlySpendVehicle: monthlySpendVehicle,
      companyId: companyId,
    };
    let cyclingComparision = {
      proposedCycle: proposedCycleYear,
      monthlyLeaseCost: monthlyLeaseCost1,
      avgYear1Mpg: cycleFuelYearCost,
      fuelSpendY1: foreCastedValue1,
      monthlyMaintananceYear1: year1Monthly,
      avgYear1CostPerMiles: oneYearMaintenanceTotalMiles,
      companyId: companyId,
    };

    rowData?.map((items) => {
      items["companyId"] = companyId;
    });

    let fleetMix = [...rowData];

    fleetMix.forEach((fleetRow) => {
      fleetRow.cashOutlay = removeCurrency(fleetRow.cashOutlay);
      fleetRow.currentPayment = removeCurrency(fleetRow.currentPayment);
      fleetRow.cyclingPayment = removeCurrency(fleetRow.cyclingPayment);
      fleetRow.vehicleEquity = removeCurrency(fleetRow.vehicleEquity);
      fleetRow.maintenance = removeCurrency(fleetRow.maintenance);
      fleetRow.fuel = removeCurrency(fleetRow.fuel);
      fleetRow.fleetSpeed = removeCurrency(fleetRow.fleetSpeed);
    });

    if (companyNameHeader) {
      let resultData = JSON.stringify({
        fleet: fleet,
        maintainanceCost: maintainanceCost,
        fuelCost: fuelCost,
        cyclingComparision: cyclingComparision,
        fleetMix: fleetMix,
      });
      let payload = {
        reportData: resultData,
        companyId: companyId,
        createdBy: Number(localStorage.getItem("cashflow_admin_user_id")),
        reportName: `${localStorage.getItem("name")}_${
          companyNameHeader?.companyName
        }_${moment().format("MM-DD-YYYY")}`,
        createdAt: moment().format(),
      };
      await addAnalysisResultApi(payload);
      setSelectedNav(sidebarMenu.analysisResult);
      navigation("/analysis-results");
    } else {
      showToast("Please select company name!");
    }
  };
  const handleBack = () => {
    setSelectedNav(sidebarMenu.fuel);
    navigation("/fuel-analysis");
  };

  useEffect(() => {
    if (fleetList?.length > 0) {
      const values = uniqueNullValues(fleetList);
      if (values === "") {
        setRequireFields([]);
      } else {
        values?.map((items) => {
          items += ", ";
        });
        setRequireFields(values);
      }
    }
  }, [fleetList, newVehicle]);
  return (
    <Box className={classes.mainContent}>
      <CustomTabPanel value={0} index={0}>
        <Box className={classes.gridContantFleetList}>
          <Stack
            direction="row"
            sx={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Box className={classes.headerNameText}>Funding/New Vehicle</Box>
            <Box className={classes.addEditGrid}>
              <Button
                variant="outlined"
                className={classes.fleetListNewButton}
                onClick={handleBack}
              >
                <span style={{ fontSize: 12 }}>Back</span>
              </Button>
              {newVehicle &&
                newVehicle?.length > 0 &&
                requireFields &&
                requireFields?.length === 0 &&
                !loadingApis &&
                requireNewvehicleFields &&
                requireNewvehicleFields?.length === 0 && (
                  <Button
                    variant="outlined"
                    className={classes.fleetListNewButton}
                    onClick={handleNext}
                  >
                    <span style={{ fontSize: 12 }}>Generate Report</span>
                  </Button>
                )}
              <Button
                variant="outlined"
                className={classes.fleetListNewButton}
                onClick={handleClick}
                disabled={selectedRows.length === 0}
              >
                <Settings
                  style={{ fontSize: 16, marginBottom: 2, marginRight: 3 }}
                />
                <span style={{ fontSize: 12 }}>Action</span>
              </Button>
              <Button
                variant="outlined"
                className={classes.fleetListNewButton}
                onClick={handleAddButton}
              >
                NEW
              </Button>
              <SearchBar onSearch={onSearch} />
            </Box>
            <>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleOnClose}
              >
                <MenuItem
                  onClick={() =>
                    handleActionSelect("setNewVehicleYear", year, "year")
                  }
                >
                  Set New Vehicle Year
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleActionSelect("setNewVehicleMake", make, "name")
                  }
                >
                  Set New Vehicle Make
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleActionSelect("setNewVehicleModel", modelData, "model")
                  }
                >
                  Set New Vehicle Model
                </MenuItem>
                <MenuItem
                  onClick={() => handleActionSelect("setNewVehicleMPG")}
                >
                  Set New Vehicle MPG
                </MenuItem>
                {/* <MenuItem
                  onClick={() =>
                    handleActionSelect("setNewFuel", fuelList, "name")
                  }
                >
                  Set New Fuel
                </MenuItem> */}
                <MenuItem
                  onClick={() => handleActionSelect("setPaymentAmount")}
                >
                  Set Payment Amount
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleActionSelect(
                      "setFundingType",
                      fundList,
                      "fundingType"
                    )
                  }
                >
                  Set Funding Type
                </MenuItem>

                <MenuItem
                  onClick={() => handleActionSelect("setNewVehicleCost")}
                >
                  Set New Vehicle Cost
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleActionSelect("setNewEstimatedResaleValue")
                  }
                >
                  Set New Estimated Resale Value
                </MenuItem>
                <MenuItem onClick={() => handleActionSelect("setResidual")}>
                  Set Residual %
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleActionSelect(
                      "replaceExistingVehicle",
                      selectedRows,
                      "newVehicle"
                    )
                  }
                >
                  Replace With Existing Vehicle Make/Model
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleActionSelect("setAll", {
                      fuelListArray: fuelList,
                      fundListArray: fundList,
                    })
                  }
                >
                  Set All
                </MenuItem>
              </Menu>
            </>
          </Stack>
          {loading ? (
            <TruckLoader />
          ) : (
            <AgGridReact
              className="ag-theme-quartz"
              onSelectionChanged={(event) => {
                const selectedNodes = event.api.getSelectedNodes();
                const selectedRows = selectedNodes.map((node) => node.data);
                setSelectedRows(selectedRows);
              }}
              onRowSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRows = rowDataFinal.filter((row) =>
                  selectedIDs.has(row.id)
                );
                setSelectedRows(selectedRows);
              }}
              rowSelection="multiple"
              suppressRowClickSelection
              pagination={true}
              rowData={rowDataFinal}
              defaultColDef={defaultColDef}
              checkboxSelection
              columnDefs={columnFinal}
              onCellClicked={onCellClicked}
            />
          )}
        </Box>
      </CustomTabPanel>
      <AddActionDialogNewVehicle
        modalContent={modalContent}
        closeModal={closeModal}
        selectedRows={selectedRows}
        dropdownValue={dropdownValue}
        keyValue={keyValue}
        companyId={companyId}
        dealerId={dealerNameHeader?.id}
        setSelectedRows={setSelectedRows}
      />
      {deleteOpen && (
        <DeleteDialog
          open={deleteOpen}
          setOpen={() => setDeleteOpen(false)}
          deleteApi={() => handleDelete()}
        />
      )}
      {replaceVehicleOpen && (
        <ReplaceExistingVehicle
          open={replaceVehicleOpen}
          selectedRowData={selectedRows}
          companyId={companyId}
          dealerId={dealerNameHeader?.id}
          setOpen={() => setReplaceVehicleOpen(false)}
          fleetList={fleetList}
          setSelectedRows={setSelectedRows}
        />
      )}
    </Box>
  );
};
