import React, { useState, createContext, useCallback } from "react";
import { UserLogsAPi } from "../api";
import { executeApi } from "./base-context";
import { showToast } from "../../components/Toast";

/**
 * Authentication context
 */
export const UserLogsContext = createContext();

/**
 * Authentication data provider
 */
export const UserLogsProvider = ({ children }) => {
  const [userLogs, setUserLogs] = useState([]);
  const [userLogsData, setUserLogsData] = useState([]);
  const [userLogsById, setUserLogsById] = useState([]);
  // const [typeId, setTypeId] = useState([])
  const [loading, setLoading] = useState(false);
  const [selectedNav, setSelectedNav] = useState("Dashboard");
  const userId = localStorage.getItem("cashflow_admin_user_id");
  const executeAppointmentAPI = async (apiFunction, request) => {
    const response = await executeApi(apiFunction, request);
    return response;
  };

  const getUserLogs = async (values) => {
    try {
      setLoading(true);
      const response = await UserLogsAPi.getUserLogs({
        limit: values?.limit ? values.limit : 10000,
        offset: values?.offset ? values.offset : 0,
        filter: {
          ...values?.filter,
        },
        sorting: {
          fieldName: "",
          direction: "",
        },
      });

      if (response?.data?.data) {
        setUserLogs(response.data.data);
        setUserLogsData(response.data.data);
        setLoading(false);

        // const user = response?.data?.data?.find((item) => item.id === parseInt(userId));

        // if (user) {
        //   setTypeId(user?.typeId)
        // } else {
        // }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const createUserLogs = async (request) => {
    const userType = localStorage.getItem("type");
    const userTypeId = localStorage.getItem("cashflow_admin_user_id");
    // setLoading(true);
    try {
      setLoading(true);
      const response = await UserLogsAPi.createUserLogs(request);
      if (response.data.succeeded === true) {
        // alert(response.data.messages.length===0?"aaaa":"bbbb");/

        if (userType === "dealer") {
          getUserLogs({ filter: { type: userType, typeId: userTypeId } });
        } else {
          getUserLogs();
        }
        setLoading(false);
      } else {
        showToast(response.data.messages[0]);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const updateUserLogs = async (request) => {
    const userType = localStorage.getItem("type");
    const userTypeId = localStorage.getItem("cashflow_admin_user_id");
    // setLoading(true);
    try {
      setLoading(false);
      const response = await UserLogsAPi.updateUserLogs(request);
      if (response.data.succeeded === true) {
        if (userType === "dealer") {
          getUserLogs({ filter: { type: userType, typeId: userTypeId } });
        } else {
          getUserLogs();
        }
        setUserLogsData(null);
        setLoading(false);

        return response;
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getAllLoggedUserWithUserName = async (values) => {
    try {
      setLoading(true);
      const response = await UserLogsAPi.GetAllLoggedUserWithUserName({
        limit: values?.limit ? values.limit : 10000,
        offset: values?.offset ? values.offset : 0,
        filter: {
          ...values?.filter,
        },
        sorting: {
          fieldName: "",
          direction: "",
        },
      });

      if (response?.data?.data) {
        setUserLogs(response.data.data);
        setLoading(false);

        // const user = response?.data?.data?.find((item) => item.id === parseInt(userId));

        // if (user) {
        //   setTypeId(user?.typeId)
        // } else {
        // }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const contextValue = {
    userLogs,
    loading,
    userLogsById,
    setUserLogsById,
    setUserLogs,
    createUserLogs: useCallback((request) => {
      createUserLogs(request);
    }, []),
    getUserLogs,
    setUserLogsData,
    updateUserLogs,
    userLogsData,
    selectedNav,
    setSelectedNav,
    getUserLogsList: useCallback((values) => {
      getAllLoggedUserWithUserName(values);
    }, []),
  };

  return (
    <UserLogsContext.Provider value={contextValue}>
      {children}
    </UserLogsContext.Provider>
  );
};
