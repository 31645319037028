import React, { Fragment, useEffect, useState } from "react";
import { ThemeProvider } from "../../common/contexts";
import { AppContextWrapper } from "./App-contextWrapper";
import { Route, Routes, useNavigate } from "react-router-dom";
import Layout from "./Layout";
import Starred from "./Starred";
import Dashboard from "../admin/Dashboard";
import { FleetList } from "../admin/Fleet-list";
import { FuelEconomy } from "../admin/Fuel-Economy";
import { FuelPrice } from "../admin/Fuel-price";
import { MaintenanceAnalysis } from "../admin/Maintance-analysis";
import { ManageCompanies } from "../admin/Manage-companies";
import { ReplacementSchedule } from "../admin/Replacement-schedule";
import { Reports } from "../admin/Reports";
import { FuelAnalysis } from "../admin/Fuel-analysis";
import { AnalysisResults } from "../admin/Analysis-results";
import { FundingNewVehicle } from "../admin/Funding-newvehicle";
import { EditFleetList } from "../admin/editFleetList";
import { AddFleetList } from "../admin/addFleetList";
import { ManageDealer } from "../admin/Manage-Dealer";
import { ManageVehicle } from "../admin/Manage-Vehicle";
import { ManageUser } from "../admin/Manage-User";
import { Login } from "../login/Login";
import { Profile } from "../admin/profile";
import AddUser from "../admin/AddUser";
import { AddDealer } from "../admin/AddDealer";
import { NewFleetList } from "../admin/FleetList/NewFleet-list";
import { ManageUserDealer } from "../admin/ManageUserDealer";
import { ManageCompanyDealer } from "../admin/company-dealer";
import { ManageDealerCompanies } from "../admin/ManageDealerCompanies";
import { PowerBiReport } from "../admin/PowerBiReport";
import { AnalysisResults1 } from "../admin/Analysis-results-1";
import NotFound from "../../components/NotFoundPage";
import { OtpScreen } from "../login/otpScreen";
import { showToast } from "../../components/Toast";
import { Vincentric } from "../admin/Vincentric";
import { AdminViewUserQueries } from "../admin/AdminViewUserQueries";
import { HelpDeskDealer } from "../admin/HelpDeskDealer";
import HelpDesk from "../admin/HelpDesk";
import { dateFormatSql } from "../../common/services/formatDates";
import { Userlogs } from "../admin/Userlogs";
import {
  createUserLogsApiFuncation,
  onLogoutFunction,
} from "../../common/services/createUserLogs";
import { ComparisionCTO } from "../admin/ComparisionCTO";
import { localStorageItemNames } from "../../common/constants";
import { typeConstant } from "../../common/constants/commonFunction";
import { UserGuide } from "../admin/UserGuide";
import { PopupMessage } from "../../common/constants/popupMessage";

export const App = () => {
  const [startTime, setStartTime] = useState(null);
  const [idleTime, setIdleTime] = useState(null);
  const history = useNavigate();
  // Set the start time once when the component is mounted
  useEffect(() => {
    const start = Date.now();
    const startDateFormat = dateFormatSql(start);
    if (!startTime) {
      setStartTime(startDateFormat);
      const userType = localStorage.getItem(localStorageItemNames.type);
      if (userType?.toLowerCase() == typeConstant.dealer) {
        createUserLogsApiFuncation({
          startDate: startDateFormat,
          idleDate: startDateFormat,
          idleStartDate: startDateFormat,
        });
      }
    }

    // Define a cleanup function to handle end time when the user closes the tab or browser
    const handleBeforeUnload = () => {
      const idle = Date.now();
      const idleDateFormat = dateFormatSql(idle);
      setIdleTime(idleDateFormat);
      const userType = localStorage.getItem(localStorageItemNames.type);
      if (userType?.toLowerCase() == typeConstant.dealer) {
        createUserLogsApiFuncation({
          startDate: idleDateFormat,
          idleDate: idleDateFormat,
          idleStartDate: idleDateFormat,
        });
      }
      // Optionally, send this data to the server here.
    };

    // Set the end time when browser/tab is closed
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // Set the end time when the app goes into the background (user switches tab or minimizes the window)
  useEffect(() => {
    const handleBlur = () => {
      const idle = Date.now();
      const userType = localStorage.getItem(localStorageItemNames.type);
      const idleDateFormat = dateFormatSql(idle);
      setIdleTime(idleDateFormat);
      if (userType?.toLowerCase() == typeConstant.dealer) {
        createUserLogsApiFuncation({
          endDate: idleDateFormat,
          idleDate: idleDateFormat,
          idleEndDate: idleDateFormat,
        });
      }
      // Optionally, send this data to the server here.
    };

    const handleFocus = () => {
      const idle = Date.now();
      const userType = localStorage.getItem(localStorageItemNames.type);
      const idleDateFormat = dateFormatSql(idle);
      setIdleTime(idleDateFormat);
      if (userType?.toLowerCase() == typeConstant.dealer) {
        createUserLogsApiFuncation({
          startDate: idleDateFormat,
          idleDate: idleDateFormat,
          idleStartDate: idleDateFormat,
        });
      }
      console.log("End Time (on blur):", dateFormatSql(idle));
    };

    window.addEventListener("blur", handleBlur);
    window.addEventListener("focus", handleFocus);

    return () => {
      window.removeEventListener("blur", handleBlur);
      window.removeEventListener("focus", handleFocus);
    };
  }, []);

  useEffect(() => {
    const interval = window.setInterval(() => {
      const local = localStorage.getItem("version");
      fetch("/version/version.json")
        .then((res) => res.json())
        .then(async (data) => {
          if (data?.version !== local && local !== null) {
            await onLogoutFunction(history, data);
          } else {
            localStorage.setItem("version", data?.version);
            localStorage.setItem(
              localStorageItemNames.showMessageAfterReload,
              "false"
            );
          }
        });
    }, 5000);
    return () => window.clearInterval(interval);
  }, []);
  return (
    <ThemeProvider>
      <AppContextWrapper>
        <Routes>
          <Fragment>
            <Route path="/" element={<Login />} />
            <Route path="otpScreen" element={<OtpScreen />} />
            <Route element={<Layout />}>
              <Route path="Dashboard" element={<Dashboard />} />
              <Route path="starred" element={<Starred />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="addFleetList" element={<AddFleetList />} />
              <Route path="editFleetList" element={<EditFleetList />} />
              <Route path="fuel-economy" element={<FuelEconomy />} />
              <Route path="fuel-price" element={<FuelPrice />} />
              <Route path="manage-companies" element={<ManageCompanies />} />
              <Route path="manage-dealer" element={<ManageDealer />} />
              <Route
                path="manage-company-dealer"
                element={<ManageCompanyDealer />}
              />
              <Route path="add-dealer" element={<AddDealer />} />
              <Route path="manage-user" element={<ManageUser />} />
              <Route path="manage-vehicle" element={<ManageVehicle />} />
              <Route path="reports" element={<Reports />} />
              <Route path="fuel-analysis" element={<FuelAnalysis />} />
              <Route path="analysis-results" element={<AnalysisResults />} />
              <Route path="analysisReport" element={<AnalysisResults1 />} />
              <Route path="profile" element={<Profile />} />
              <Route path="fleet-list" element={<FleetList />} />
              <Route path="new-fleetlist" element={<NewFleetList />} />
              <Route path="addUser" element={<AddUser />} />
              <Route path="manage-userDealer" element={<ManageUserDealer />} />
              <Route
                path="manage-dealer-companies"
                element={<ManageDealerCompanies />}
              />
              <Route
                path="funding-newvehicle"
                element={<FundingNewVehicle />}
              />
              <Route
                path="replacement-schedule"
                element={<ReplacementSchedule />}
              />
              <Route
                path="maintenance-analysis"
                element={<MaintenanceAnalysis />}
              />
              <Route path="powerBi-Report" element={<PowerBiReport />} />
              <Route path="*" element={<NotFound />} />
              <Route path="analysis-data" element={<Vincentric />} />
              <Route path="userQueries" element={<HelpDesk />} />
              <Route path="helpDesk" element={<HelpDeskDealer />} />
              <Route path="user-queries" element={<AdminViewUserQueries />} />
              <Route path="userLogs" element={<Userlogs />} />
              <Route path="ComparisionCTO" element={<ComparisionCTO />} />
              <Route path="userGuide" element={<UserGuide />} />
            </Route>
          </Fragment>
        </Routes>
      </AppContextWrapper>
    </ThemeProvider>
  );
};
