import { Save } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import {
  useCompany,
  useFleetList,
  useFuelList,
  useFundList,
  useMake,
  useModel,
  useNewVehicle,
  useReplacement,
  useYear,
} from "../../../common/hooks";
import { AdminPageStyle } from "../style";
import { getCompanyId } from "../../../common/constants/commonFunction";
import axios from "axios";
import { removeCommaAndRemoveCurrency } from "../../../common/services/number-service";
import { showToast } from "../../../components/Toast";
import { CurrentVehicleInfo } from "../../../components/Funding-New-Vehicle/CurrentVehicleInfo";
import { CycleYearInfo } from "../../../components/Funding-New-Vehicle/CycleYearInfo";
import { NewVehicleInfo } from "../../../components/Funding-New-Vehicle/NewVehicleInfo";
import { VehicleEquity } from "../../../components/Funding-New-Vehicle/VehicleEquity";

export const NewFleetListFundingNewVehicle = ({
  selectedContent,
  fleetNoValue,
}) => {
  const classes = AdminPageStyle();
  const { getFleetListApi, fleetList, getFleetVehicleInfoApi } = useFleetList();
  const { year, getYearApi } = useYear();
  const { make, getMakeApi } = useMake();
  const { modelData, getModelList, getModelListData, modelCount } = useModel();
  const { fundList, getFundListApi } = useFundList();
  const { fuelList, getFuelListApi } = useFuelList();
  const { companyNameHeader, dealerNameHeader } = useCompany();
  const { state } = useLocation();
  const [vehicleInfoValue, setVehicleInfoValue] = useState(state?.data);
  const [newResaleValue, setNewResaleValue] = useState();
  const [newCostValue, setNewCostValue] = useState();
  const [paymentAmount, setPaymentAmount] = useState();
  const [currentResaleValue, setCurrentResaleValue] = useState(0);
  const [residualValue, setResidualValue] = useState();
  const [equityGain, setEquityGain] = useState(0);
  const [vehicleInfoData, setVehicleInfoData] = useState({});
  const [loadingEstimatedValue, setLoadingEstimatedValue] = useState(false);
  const [newResaleValueComma, setNewResaleValueComma] = useState("");
  const [currentResaleValueComma, setCurrentResaleValueComma] = useState("");
  const [newCostValueComma, setNewCostValueComma] = useState("");
  const [newAdd, setNewAdd] = useState("");
  const [fuelId, setFuelId] = useState(
    state?.data &&
      fundList?.filter((item) => item?.id === state?.data?.newFuelId)?.[0]
  );
  const userType = localStorage.getItem("type");
  const {
    createNewVehicleApi,
    editNewVehicleApi,
    getNewVehicleApi,
    newVehicle,
    editEstimatedNewVehicleApi,
    setNewVehicle,
  } = useNewVehicle();
  const { getReplacementListApi, replacementList } = useReplacement();
  const [editData, setEditData] = useState({});
  const [yearValue, setYearValue] = useState(
    state?.data &&
      year?.filter((item) => item?.id === state?.data?.newYearId)?.[0]?.id
  );
  let companyId;
  const [makeValue, setMakeValue] = useState(
    state?.data &&
      make?.filter((item) => item?.id === state?.data?.newMakeId)?.[0]
  );
  const [modelValueData, setModelValueData] = useState(
    state?.data &&
      modelData?.filter(
        (item) => item?.modelId === state?.data?.newModelId
      )?.[0]
  );
  const [fundValue, setFundValue] = useState(
    state?.data &&
      fundList?.filter((item) => item?.id === state?.data?.fundingTypeId)?.[0]
  );
  const { register, handleSubmit, reset, setValue } = useForm({});
  const [year1Value, setYear1Value] = useState(0);
  const [year2Value, setYear2Value] = useState(0);
  const [year3Value, setYear3Value] = useState(0);
  const [year4Value, setYear4Value] = useState(0);
  const [year5Value, setYear5Value] = useState(0);
  const [yearFalse, setYearFalse] = useState(false);
  const [year1ValueData, setYear1ValueData] = useState({
    year1: editData?.replaceYear1 || false,
    year2: editData?.replaceYear2 || false,
    year3: editData?.replaceYear3 || false,
    year4: editData?.replaceYear4 || false,
    year5: editData?.replaceYear5 || false,
  });
  const [fundType, setFundType] = useState();
  const [mpgValue, setMpgValue] = useState(0);

  useEffect(() => {
    if (!yearFalse) {
      const updateYearValues = () => {
        let firstTrueFound = false;
        Object.entries(year1ValueData).forEach(([yearKey, yearValue]) => {
          let value = 0;
          if (yearValue) {
            value = firstTrueFound ? equityGain : 0;
            firstTrueFound = true;
          }
          switch (yearKey) {
            case "year1":
              setYear1Value((prev) =>
                value ? value : equityGain == 0 ? equityGain : prev
              );
              break;
            case "year2":
              setYear2Value((prev) =>
                value ? value : equityGain == 0 ? equityGain : prev
              );
              break;
            case "year3":
              setYear3Value((prev) =>
                value ? value : equityGain == 0 ? equityGain : prev
              );
              break;
            case "year4":
              setYear4Value((prev) =>
                value ? value : equityGain == 0 ? equityGain : prev
              );
              break;
            case "year5":
              setYear5Value((prev) =>
                value ? value : equityGain == 0 ? equityGain : prev
              );
              break;
            default:
              break;
          }
        });
      };

      updateYearValues();
    }
  }, [year1ValueData, equityGain]);

  useEffect(() => {
    if (yearValue && makeValue) {
      getModelList({
        makeId: makeValue?.id ? makeValue?.id : makeValue,
        yearId: yearValue?.id ? yearValue?.id : yearValue,
      });
    }
  }, [yearValue, makeValue]);
  useEffect(() => {
    if (companyNameHeader) {
      companyId = getCompanyId(userType, companyNameHeader);
    }
  }, [companyNameHeader]);
  useEffect(() => {
    if (companyId) {
      if (vehicleInfoValue) {
        getReplacementListApi({
          fleetNo: vehicleInfoData?.fleetNo,
          companyId: companyId,
          dealerId: dealerNameHeader?.id,
        });
      }
    }
  }, [companyId, vehicleInfoValue, vehicleInfoData]);

  useEffect(() => {
    if (state?.data || replacementList) {
      let replacementData = replacementList?.filter(
        (item) => item?.fleetNo === vehicleInfoData?.fleetNo
      )?.[0];
      if (replacementData) {
        setYear1ValueData({
          year1: replacementData?.year1,
          year2: replacementData?.year2,
          year3: replacementData?.year3,
          year4: replacementData?.year4,
          year5: replacementData?.year5,
        });
        setNewCostValue(editData?.newVehicleCost || 0);
        setResidualValue(editData?.residualPercentage || 0);
      } else {
        setYear1ValueData({
          year1: state?.data?.replaceYear1,
          year2: state?.data?.replaceYear2,
          year3: state?.data?.replaceYear3,
          year4: state?.data?.replaceYear4,
          year5: state?.data?.replaceYear5,
        });
        setNewCostValue(state?.data?.newVehicleCost || 0);
        setResidualValue(state?.data?.residualPercentage || 0);
      }
    }
  }, [state, replacementList, editData]);

  useEffect(() => {
    if (state?.data) {
      setVehicleInfoValue(state?.data);
      setYearValue(
        year?.filter((item) => item?.year === state?.data?.newYear)?.[0]?.id
      );
    }
  }, [state?.data]);
  useEffect(() => {
    if (modelData?.length > 0) {
      var modelOldData = modelData?.filter(
        (item) => item?.modelId === state?.data?.newModelId
      )?.[0];

      if (state?.data?.newModelId && modelOldData) {
        setModelValueData(modelOldData);
      }
    } else {
      getModelListData();
    }
  }, [modelData]);
  useEffect(() => {
    if (fleetNoValue) {
      setNewVehicle([]);
      getNewVehicleApi({
        fleetNo: fleetNoValue,
        companyId: companyId,
        dealerId: dealerNameHeader?.id,
      });
    } else if (companyNameHeader) {
      setNewVehicle([]);
      getFleetVehicleInfoApi({
        companyId: companyId,
        dealerId: dealerNameHeader?.id,
      });
    }
  }, [companyId]);

  useEffect(() => {
    if (companyId) {
      getFleetListApi({ companyId: companyId, dealerId: dealerNameHeader?.id });
    }
  }, [companyNameHeader]);
  useEffect(() => {
    if (vehicleInfoValue?.fleetNo) {
      const newData = fleetList?.filter(
        (item) => item?.fleetId === vehicleInfoValue?.fleetId
      )?.[0];
      setVehicleInfoData(newData);
    } else if (vehicleInfoValue) {
      const newData = fleetList?.filter(
        (item) => item?.fleetId === vehicleInfoValue
      )?.[0];
      setVehicleInfoData(newData);
    }
  }, [vehicleInfoValue]);

  useEffect(() => {
    let newVehicleData = newVehicle?.filter(
      (item) => item?.fleetNo === vehicleInfoData?.fleetNo
    )?.[0];
    if (newVehicle?.length > 0 && vehicleInfoData?.fleetNo && newVehicleData) {
      setYear1Value(newVehicle[0]?.year1 || 0);
      setYearValue(newVehicle[0]?.newYearId);
      setMakeValue({
        id: newVehicle[0]?.newMakeId || null,
        name: newVehicle[0]?.newMake || "",
      });
      setModelValueData({
        modelId: newVehicle[0]?.newModelId || null,
        model: newVehicle[0]?.newModel || "",
      });
      setFundValue({
        id: newVehicle[0]?.fundingTypeId || null,
        fundingType: newVehicle[0]?.fundingType || "",
      });
      setYear2Value(newVehicle[0]?.year2 || 0);
      setYear3Value(newVehicle[0]?.year3 || 0);
      setYear4Value(newVehicle[0]?.year4 || 0);
      setYear5Value(newVehicle[0]?.year5 || 0);
      setYearFalse(true);
      setEditData(newVehicle[0]);
      setNewCostValueComma(newVehicle[0]?.newVehicleCost || 0);
      setNewResaleValueComma(newVehicle[0]?.newEstimatedResaleValue || 0);
      setCurrentResaleValueComma(
        newVehicle[0]?.currentEstimatedResaleValue || 0
      );
      setNewResaleValue(newVehicle[0]?.newEstimatedResaleValue || 0);
      setPaymentAmount(newVehicle[0]?.paymentAmount || 0);
      setMpgValue(newVehicle[0]?.newMpg || 0);
      setCurrentResaleValue(newVehicle[0]?.currentEstimatedResaleValue || 0);
      setFuelId(newVehicle[0]?.newFuelId || 0);
    }
  }, [newVehicle, vehicleInfoData?.fleetNo]);

  useEffect(() => {
    getYearApi();
    getMakeApi();
    getFundListApi();
    getFuelListApi();
  }, []);

  const calculateEquityGain = () => {
    if (newResaleValue && newCostValue && residualValue) {
      const estimatedResaleValue = parseFloat(newResaleValue);
      const newVehicleCost = parseFloat(newCostValue);
      const residualPercentage = parseFloat(residualValue);
      const equityGainValue =
        estimatedResaleValue - newVehicleCost * (residualPercentage / 100);
      if (residualValue == 0) {
        setEquityGain(0);
      } else {
        setEquityGain(equityGainValue);
      }
    } else {
      setEquityGain(0);
    }
  };

  useEffect(() => {
    calculateEquityGain();
  }, [newResaleValue, newCostValue, residualValue, fundValue]);

  useEffect(() => {
    if (
      fundType?.fundingType?.toLowerCase() == "apr" ||
      fundType?.fundingType?.toLowerCase() == "cash"
    ) {
      setResidualValue(0);
    }
  }, [fundType]);

  const handleSaveNewVehicle = async (values) => {
    let currentEstimate = values?.currentEstimatedResaleValue
      ? values?.currentEstimatedResaleValue
      : 0;
    let paymentAmount1 = values?.paymentAmount ? values?.paymentAmount : 0;
    let payload = {
      ...values,
      fleetNo: vehicleInfoData?.fleetNo,
      yearId: yearValue,
      makeId: makeValue?.id,
      model: modelValueData?.model,
      modelId: modelValueData?.modelId,
      fundingTypeId: fundValue?.id,
      companyId: companyId,
      dealerId: dealerNameHeader?.id,
      equityGain: equityGain,
      mpg: mpgValue,
      // newFuelId: fuelId,
      fuelId: fuelId,
    };

    if (state?.data) {
      let newVehicleId = newVehicle?.length > 0 ? newVehicle[0].id : 0;
      let removeCurrency = removeCommaAndRemoveCurrency(currentResaleValue);
      let editPayload = {
        ...values,
        id: newVehicleId,
        newEstimatedResaleValue: values?.newEstimatedResaleValue
          ? Number(values?.newEstimatedResaleValue)
          : newResaleValue,
        newVehicleCost: values?.newVehicleCost
          ? Number(values?.newVehicleCost)
          : newCostValue,
        residualPercentage: values?.residualPercentage
          ? Number(values?.residualPercentage)
          : residualValue,
        paymentAmount: values?.paymentAmount
          ? Number(values?.paymentAmount)
          : paymentAmount,
        currentEstimatedResaleValue: removeCurrency,
        fleetNo: vehicleInfoData?.fleetNo,
        yearId: yearValue,
        makeId: makeValue?.id,
        modelId: modelValueData?.modelId,
        // newFuelId: fuelId,
        model: modelValueData?.model,
        fuelId: fuelId,
        fundingTypeId: fundValue?.id,
        companyId: companyId,
        dealerId: dealerNameHeader?.id,
        equityGain: equityGain,
        mpg: mpgValue,
        year2: year2Value,
        year3: year3Value,
        year4: year4Value,
        year5: year5Value,
      };
      await editNewVehicleApi({ values: editPayload, newAdd });
    } else {
      await createNewVehicleApi(payload);
    }
  };

  const handleEstimatedValue = async (event) => {
    let vinNumber = vehicleInfoData?.vin;
    let ResaleValue;
    setLoadingEstimatedValue(true);
    var config = {
      method: "get",
      url: `https://api.vehicledatabases.com/market-value/${vinNumber}`,
      headers: {
        "x-AuthKey": "da6bc7336e404b94a9fa328506fe86f6",
      },
    };
    axios(config)
      .then(async (response) => {
        let marketValue = response?.data?.data?.market_value?.market_value_data;
        let valueOfMarket = marketValue?.[0]?.["market value"];
        let DealerRetail = valueOfMarket?.filter(
          (items) => items.Condition == "Average"
        );
        if (DealerRetail?.length > 0) {
          ResaleValue = DealerRetail[0]?.["Trade-In"];
        }
        if (ResaleValue) {
          let newVehicleId = newVehicle?.length > 0 ? newVehicle[0].id : 0;
          let resaleValue = removeCommaAndRemoveCurrency(ResaleValue);
          let payload = {
            id: newVehicleId,
            currentEstimatedResaleValue: resaleValue,
          };
          await editEstimatedNewVehicleApi(payload);
        }
        setCurrentResaleValue(ResaleValue);
        setLoadingEstimatedValue(false);
      })
      .catch(function (error) {
        setLoadingEstimatedValue(false);
        showToast("Estimated value not found for this VIN");
      });
  };
  return (
    <Box>
      <Box className={classes.fleetListInformation}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.headerFleet}
            padding={"10px !important"}
          >
            {vehicleInfoData ? `${selectedContent} : ` : selectedContent}
            {vehicleInfoData?.fleetNo ? vehicleInfoData?.fleetNo : ""}
            <Button
              className={classes.headerSaveButton}
              onClick={handleSubmit(handleSaveNewVehicle)}
              startIcon={<Save />}
              variant="contained"
            >
              Save
            </Button>
          </Grid>

          <CurrentVehicleInfo
            vehicleInfoValue={vehicleInfoValue}
            fleetList={fleetList}
            vehicleInfoData={vehicleInfoData}
            loadingEstimatedValue={loadingEstimatedValue}
            state={state}
            setVehicleInfoValue={setVehicleInfoValue}
            currentResaleValue={currentResaleValue}
            setCurrentResaleValue={setCurrentResaleValue}
            handleEstimatedValue={handleEstimatedValue}
          />
          <CycleYearInfo year1ValueData={year1ValueData} />
          <NewVehicleInfo
            yearValue={yearValue}
            setYearValue={setYearValue}
            year={year}
            setMakeValue={setMakeValue}
            setModelValueData={setModelValueData}
            make={make}
            makeValue={makeValue}
            modelValueData={modelValueData}
            modelData={modelData}
            setMpgValue={setMpgValue}
            mpgValue={mpgValue}
            paymentAmount={paymentAmount}
            setPaymentAmount={setPaymentAmount}
            fundValue={fundValue}
            setFundType={setFundType}
            setFundValue={setFundValue}
            fundList={fundList}
            newCostValue={newCostValue}
            setNewCostValue={setNewCostValue}
            newResaleValue={newResaleValue}
            setNewResaleValue={setNewResaleValue}
            setYearFalse={setYearFalse}
            fuelList={fuelList}
            fuelId={fuelId}
            setFuelId={setFuelId}
            editData={editData}
            setNewAdd={setNewAdd}
            modelCount={modelCount}
          />
          <VehicleEquity
            year1Value={year1Value}
            setYear1Value={setYear1Value}
            year2Value={year2Value}
            setYear2Value={setYear2Value}
            year3Value={year3Value}
            setYear3Value={setYear3Value}
            year4Value={year4Value}
            setYear4Value={setYear4Value}
            year5Value={year5Value}
            setYear5Value={setYear5Value}
            residualValue={residualValue}
            equityGain={equityGain}
            setYearFalse={setYearFalse}
            setResidualValue={setResidualValue}
          />
        </Grid>
      </Box>
    </Box>
  );
};
