import React, { useState, createContext, useCallback } from "react";
import { modelApi } from "../api";
import _ from "lodash";

/**
 * Model context
 */
export const ModelContext = createContext();

/**
 * Model data provider
 */
export const ModelProvider = ({ children }) => {
  const [model, setModel] = useState();
  const [modelById, setModelById] = useState([]);
  const [makeDataList, setMakeDataList] = useState([]);
  const [modelData, setModelData] = useState([]);
  const [modelLoading, setModelLoading] = useState(false);
  const [modelValue, setModelValue] = useState([]);
  const [closetModel, setClosetModel] = useState([]);
  const [selectClosetModel, setSelectClosetModel] = useState([]);
  const [newRowData, setNewRowData] = useState([]);
  const [isClosetModel, setIsClosetModel] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedModel, setSelectedModel] = useState("");
  const [newRowIndexData, setNewRowIndexData] = useState(null);
  const [modelCount, setModelCount] = useState(0);

  const getModel = async (values) => {
    setModelLoading(true);
    const response = await modelApi.getModel({
      limit: values?.limit ? values.limit : 10000,
      offset: values?.offset ? values.offset : 0,
      filter: {
        ...values?.filter,
      },
      sorting: {
        fieldName: "",
        direction: "",
      },
    });
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setModelLoading(false);
    }
    if (response?.data?.data) {
      setModel(response?.data?.data);
      if (response.data.data.length > 0) {
        let makeData = [];
        for (let index = 0; index < 10; index++) {
          let dataModel = await modelApi.getMakeById(
            response.data.data[index].makeId
          );
          makeData.push({
            id: dataModel?.data?.data?.id,
            makes: dataModel?.data?.data?.name,
            name: response?.data?.data[index]?.name,
          });
        }

        setModelData([...makeData]);
      }
      setModelLoading(false);
    } else {
      setModel([]);
      setModelLoading(false);
    }

    return response.data;
  };
  const getModelListData = async (values) => {
    setModelLoading(true);
    const response = await modelApi.modelCount(values);
    console.log("response", response);
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setModelLoading(false);
    }
    if (response?.data?.data) {
      setModelCount(response?.data?.data);
      setModelLoading(false);
    } else {
      setModelCount([]);
      setModelLoading(false);
    }

    return response.data;
  };

  const getMakeList = async (values) => {
    setModelLoading(true);
    let whereClause = {};
    if (values?.yearId) {
      whereClause = { ...whereClause, yearId: values.yearId };
    }
    const response = await modelApi.modelList({
      limit: values?.limit ? values.limit : 10000,
      offset: values?.offset ? values.offset : 0,
      ...whereClause,
      filter: {
        ...values?.filter,
      },
      sorting: {
        fieldName: "",
        direction: "",
      },
    });
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setModelLoading(false);
    }
    if (response?.data?.data) {
      let makeData = [];
      response?.data?.data?.map((items) => {
        let payload = {
          makeId: items.makeId,
          make: items.make,
        };
        makeData.push(payload);
      });
      let unique = [...new Set(makeData)];
      _.uniqWith(makeData, _.isEqual);

      setMakeDataList(unique);
      setModelLoading(false);
    } else {
      setMakeDataList([]);
      setModelLoading(false);
    }

    return response.data;
  };

  const getModelList = async (values) => {
    setModelLoading(true);
    let whereClause = {};
    if (values?.yearId && values?.makeId) {
      whereClause = {
        ...whereClause,
        yearId: values.yearId,
        makeId: values.makeId,
      };
    }
    const response = await modelApi.modelList({
      limit: values?.limit ? values.limit : 100,
      offset: values?.offset ? values.offset : 0,
      ...whereClause,
      filter: {
        ...values?.filter,
      },
      sorting: {
        fieldName: "",
        direction: "",
      },
    });
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setModelLoading(false);
    }
    if (response?.data?.data) {
      setModelData(response?.data?.data);
      setModelLoading(false);
    } else {
      setModelData([]);
      setModelLoading(false);
    }

    return response.data;
  };

  const getModelMake = async (values) => {
    setModelLoading(true);
    const response = await modelApi.getModel({
      limit: values?.limit ? values.limit : 100,
      offset: values?.offset ? values.offset : 0,
      filter: {
        ...values?.filter,
      },
      sorting: {
        fieldName: "",
        direction: "",
      },
    });
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setModelLoading(false);
    }
    if (response?.data?.data) {
      setModelValue(response?.data?.data);
      setModelLoading(false);
    } else {
      setModel([]);
      setModelLoading(false);
    }

    return response.data;
  };
  const getModelByIdApi = async (id) => {
    setModelLoading(true);
    const response = await modelApi.getModelByid(id);

    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setModelLoading(false);
    }

    if (response?.data?.data) {
      setModelById(response?.data?.data);
      setModelLoading(false);
    } else {
      setModelLoading(false);
    }

    return response.data;
  };
  const deleteModelByIdApi = async (id) => {
    setModelLoading(true);
    const response = await modelApi.deleteModelByid(id);

    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setModelLoading(false);
    }

    if (response?.data?.data) {
      getModel();
      setModelLoading(false);
    } else {
      setModelLoading(false);
    }

    return response.data;
  };
  const createModel = async (values) => {
    setModelLoading(true);
    const response = await modelApi.createModel(values);
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setModelLoading(false);
    }

    if (response?.data?.data) {
      setModel(response?.data?.data);
      getModel();
    } else {
      setModelLoading(false);
    }

    return response.data;
  };
  const editModel = async (values) => {
    setModelLoading(true);
    const response = await modelApi.editModel(values);
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setModelLoading(false);
    }

    if (response?.data?.data) {
      getModel();
      setModelLoading(false);
    } else {
      setModelLoading(false);
    }

    return response.data;
  };
  const closetMatchModel = async (request) => {
    setModelLoading(true);
    const response = await modelApi.closetMatchModel(request);
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setModelLoading(false);
    }
    if (response?.data?.data) {
      setClosetModel(response?.data?.data);
      setModelLoading(false);
    } else {
      setClosetModel([]);
      setModelLoading(false);
    }

    return response.data;
  };
  const contextValue = {
    model,
    setModel,
    modelLoading,
    setModelLoading,
    setModelById,
    modelById,
    modelData,
    setModelData,
    modelValue,
    makeDataList,
    closetModel,
    setClosetModel,
    selectClosetModel,
    setSelectClosetModel,
    newRowData,
    setNewRowData,
    selectedRow,
    setSelectedRow,
    isClosetModel,
    selectedModel,
    setSelectedModel,
    setIsClosetModel,
    newRowIndexData,
    setNewRowIndexData,
    modelCount,
    setModelCount,
    getModel: useCallback((request) => {
      getModel(request);
    }, []),
    createModelApi: useCallback((request) => {
      createModel(request);
    }, []),
    editModelApi: useCallback((request) => {
      editModel(request);
    }, []),
    getModelByIdApi: useCallback((request) => {
      getModelByIdApi(request);
    }, []),
    deleteModelApi: useCallback((request) => {
      deleteModelByIdApi(request);
    }, []),
    getModelMake: useCallback((request) => {
      getModelMake(request);
    }, []),
    getModelList: useCallback((request) => {
      getModelList(request);
    }, []),
    getMakeList: useCallback((request) => {
      getMakeList(request);
    }, []),
    closetMatchModel: useCallback((request) => {
      closetMatchModel(request);
    }, []),
    getModelListData: useCallback((request) => {
      getModelListData(request);
    }),
  };

  return (
    <ModelContext.Provider value={contextValue}>
      {children}
    </ModelContext.Provider>
  );
};
