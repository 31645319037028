import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { MakeSelect } from "../MakeSelect";
import { ModelSelect } from "../ModelSelect";
import { CommonSelect } from "../CommonSelect";
import { YearSelect } from "../YearSelect";
import { useDealer, useFleetList } from "../../common/hooks";
import { getCpmCategory } from "../../common/constants/commonFunction";
import { layoutStyle } from "../../pages/layout-ui/style";

export const ActionFormFleetList = ({
  dropdownValue,
  closeModal,
  selectedRows,
  dealerNameHeader,
  companyId,
}) => {
  const { multipleFleetListApi } = useFleetList();
  const { multipleFleetUpdate } = useDealer();
  const [yearValue, setYearValue] = useState();
  const [year, setYear] = useState();
  const [makeValue, setMakeValue] = useState();
  const [makeName, setMakeName] = useState();
  const [modelValueData, setModelValueData] = useState({
    modelId: null,
    model: "",
  });
  const [mpgData, setMpgData] = useState();
  const [groupType, setGroupType] = useState();
  const [modelCsv, setModelCsv] = useState();
  const [fuelId, setFuelId] = useState();
  const [driveTrainId, setDriveTrainId] = useState();
  const [typeId, setTypeId] = useState();
  const [ageCount, setAgeCount] = useState();
  const [cpmCategory, setCpmCategory] = useState();
  const [driverName, setDriverName] = useState("");
  const [stateChange, setStateChange] = useState();
  const classes = layoutStyle();
  const [newAdd, setNewAdd] = useState("");
  const handleYearChange = (event, value) => {
    let data = event.target.value;
    let year = value.props.children;
    let date = new Date().getFullYear();
    const filterdYear = dropdownValue?.yearArray?.filter(
      (yr) => yr?.id === data
    )?.[0];
    if (data) {
      setYearValue(data);
      setYear(year);
      if (filterdYear?.year) {
        let age = date - filterdYear.year;
        setAgeCount(age);
      }
    }
  };

  const handleFuelChange = (event) => {
    let data = event.target.value;
    setFuelId(data);
  };

  const handleDriveTrainChange = (event) => {
    let data = event.target.value;
    setDriveTrainId(data);
  };

  const handleMpgChange = (e) => {
    if (e.target.value) {
      setMpgData(Number(e.target.value));
    } else {
      setMpgData(null);
    }
  };

  const handleGroupTypeChange = (e) => {
    if (e.target.value) {
      setGroupType(e.target.value);
    } else {
      setGroupType(null);
    }
  };

  const handleTypeChange = (event, value) => {
    let data = event.target.value;
    setCpmCategory(getCpmCategory(value.props.children));
    setTypeId(data);
  };

  const handleClickAuto = async () => {
    let ids = selectedRows?.map((items) => items.fleetId);
    let editPayload = {
      updateIds: ids,
      companyId: companyId,
      dealerId: dealerNameHeader?.id,
    };
    if (typeId) {
      editPayload = { ...editPayload, typeId: typeId };
    }

    if (modelValueData?.modelId) {
      editPayload = {
        ...editPayload,
        modelId: modelValueData?.modelId,
      };
    }
    if (mpgData) {
      editPayload = {
        ...editPayload,
        mpg: mpgData,
      };
    }
    if (fuelId) {
      editPayload = {
        ...editPayload,
        fuelId: fuelId,
      };
    }
    if (driveTrainId) {
      editPayload = {
        ...editPayload,
        driveTrainId: driveTrainId,
      };
    }
    if (ageCount) {
      editPayload = {
        ...editPayload,
        age: ageCount,
      };
    }
    if (cpmCategory) {
      editPayload = {
        ...editPayload,
        cpmCategory: cpmCategory,
      };
    }
    if (groupType) {
      editPayload = {
        ...editPayload,
        groupType: groupType,
      };
    }
    if (driverName) {
      editPayload = {
        ...editPayload,
        driverName: driverName,
      };
    }
    if (stateChange?.id) {
      editPayload = {
        ...editPayload,
        stateId: stateChange?.id,
      };
    }
    if (yearValue) {
      editPayload = {
        ...editPayload,
        yearId: yearValue,
      };
    }
    if (makeValue) {
      editPayload = {
        ...editPayload,
        makeId: makeValue,
      };
    }
    let payload = {
      year: yearValue,
      make: makeValue,
      modelId: modelValueData?.modelId,
      model: modelValueData?.model,
      newAdd: newAdd,
    };
    await multipleFleetListApi({ values: editPayload, newAdd: payload });
    let payload11 = [];
    selectedRows?.map((items) => {
      let payload = {
        typeId: typeId ? typeId : items.typeId,
        fleetNo: items.fleetNo,
        year: year ? year : items.year,
        totalMiles: items.totalMiles,
        make: makeName ? makeName : items.make,
        model: modelValueData?.model ? modelValueData?.model : items.model,
        vin: items.vin,
        stateCode: stateChange?.code ? stateChange?.code : items.stateCode,
        dealer: items.dealerName,
        company: items.companyName,
        mpg: mpgData ? mpgData : items.mpg,
        fuelId: fuelId ? fuelId : items.fuelId,
        groupType: groupType ? groupType : items.groupType,
        driverName: driverName ? driverName : items.driverName,
      };
      payload11.push(payload);
    });
    await multipleFleetUpdate(payload11, "setAll");
    closeModal();
  };
  useEffect(() => {
    if (modelValueData === null) {
      setMpgData(0);
    }
  }, [modelValueData]);
  const handleDriverName = (e) => {
    const driver = e.target.value;
    setDriverName(driver);
  };
  const handleStateChange = (event, value) => {
    setStateChange(value);
  };
  return (
    <>
      <Box className={classes.actionDialogCommon}>
        <Box className={classes.dailogTitleInput}>Year</Box>
        <YearSelect
          yearValue={yearValue}
          handleYearChange={handleYearChange}
          setYear={""}
          width={500}
        />
      </Box>
      <Box className={classes.actionDialogCommon}>
        <Box className={classes.dailogTitleInput}>Make</Box>
        <MakeSelect
          setMakeValue={setMakeValue}
          setMakeName={setMakeName}
          width={500}
          setModelValueData={setModelValueData}
          setMpgData={setMpgData}
        />
      </Box>
      <Box className={classes.actionDialogCommon}>
        <Box className={classes.dailogTitleInput}>Model</Box>
        <ModelSelect
          makeValue={makeValue}
          modelValueData={modelValueData}
          setModelValueData={setModelValueData}
          setMpgData={setMpgData}
          yearValue={yearValue}
          setModelCsv={setModelCsv}
          width={500}
          setNewAdd={setNewAdd}
        />
      </Box>
      <Box className={classes.actionDialogCommon}>
        <Box className={classes.dailogTitleInput}>Fuel</Box>
        <CommonSelect
          className={classes.textinput}
          handleChange={handleFuelChange}
          menuProp={""}
          option={dropdownValue?.fuelListArray}
          value={fuelId}
          width={500}
        />
      </Box>
      <Box className={classes.actionDialogCommon}>
        <Box className={classes.dailogTitleInput}>Type</Box>
        <CommonSelect
          className={classes.textinput}
          handleChange={handleTypeChange}
          menuProp={""}
          option={dropdownValue?.typeListArray}
          value={typeId}
          width={500}
        />
      </Box>
      {/* <Box className={classes.actionDialogCommon}>
        <Box className={classes.dailogTitleInput}>Drive Train</Box>
        <CommonSelect
          className={classes.textinput}
          handleChange={handleDriveTrainChange}
          menuProp={""}
          option={dropdownValue?.driveTrainListArray}
          value={driveTrainId}
          width={500}
        />
      </Box> */}
      <Box className={classes.actionDialogCommon}>
        <Box className={classes.dailogTitleInput}>MPG</Box>
        <TextField
          id="standard-basic"
          variant="standard"
          type="number"
          className={classes.textinput}
          value={mpgData}
          onChange={handleMpgChange}
        />
      </Box>
      <Box className={classes.actionDialogCommon}>
        <Box className={classes.dailogTitleInput}>Location</Box>
        <Autocomplete
          disablePortal
          className={classes.textinput}
          id="combo-box-demo"
          onChange={handleStateChange}
          options={dropdownValue?.state}
          loading={dropdownValue?.state}
          loadingText={"Fetching...."}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            );
          }}
          renderInput={(params) => <TextField {...params} variant="standard" />}
        />
      </Box>
      <Box className={classes.actionDialogCommon}>
        <Box className={classes.dailogTitleInput}>Group Type</Box>
        <TextField
          id="standard-basic"
          variant="standard"
          type="string"
          className={classes.textinput}
          value={groupType}
          onChange={handleGroupTypeChange}
        />
      </Box>
      <Box className={classes.actionDialogCommon}>
        <Box className={classes.dailogTitleInput}>Driver Name</Box>
        <TextField
          id="standard-basic"
          variant="standard"
          type="string"
          className={classes.textinput}
          value={driverName}
          onChange={handleDriverName}
        />
      </Box>
      <Box className={classes.actionButtonDialog}>
        <Button
          onClick={() => closeModal()}
          className={classes.addActionButton}
        >
          Back
        </Button>
        <Button className={classes.addActionButton} onClick={handleClickAuto}>
          Save
        </Button>
      </Box>
    </>
  );
};
