import { Save } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { TypeName } from "../../../common/constants";
import {
  useCompany,
  useDealerCompany,
  useDriveTrain,
  useFleetList,
  useFuelList,
  useMake,
  useModel,
  useStates,
  useTypeList,
  useYear,
} from "../../../common/hooks";
import { useDealer } from "../../../common/hooks/dealer-hook";
import { showToast } from "../../../components/Toast";
import { AdminPageStyle } from "../style";
import { getCompanyId } from "../../../common/constants/commonFunction";
import { addComma, removeComma } from "../../../common/services/number-service";
import { GeneralInfoFleet } from "../../../components/Fleet-List/GeneralInfoFleet";

export const NewFleetListGeneralContent = ({
  selectedContent,
  fleetNoValue,
}) => {
  const { make, getMakeApi } = useMake();
  const { modelData, getModelList } = useModel();
  const classes = AdminPageStyle();
  // const [year, setYear] = useState([]);
  const { year, getYearApi } = useYear();
  const { createFleetListApi, editFleetListApi, getFleetListApi, fleetList } =
    useFleetList();
  const { stateList, getStateListApi } = useStates();
  const { state } = useLocation();
  const [editData, setEditData] = useState(state?.data);
  const { getDealerApi, dealer, singleFleetUpdateDealerApi, updateFleetApi } =
    useDealer();
  const { addDealerCompanyApi } = useDealerCompany();
  const { fuelList, getFuelListApi } = useFuelList();
  const { typeList, getTypeListApi } = useTypeList();
  const { driveTrainList, getDriveTrainListApi } = useDriveTrain();
  const [yearValue, setYearValue] = useState(state?.yearId || null);
  const [makeValue, setMakeValue] = useState(
    editData && make?.filter((item) => item?.id === editData?.makeId)?.[0]
  );
  const [modelValueData, setModelValueData] = useState(editData);
  const [mpgData, setMpgData] = useState(editData?.mpg || null);
  const [ageCount, setAgeCount] = useState(0);
  const [typeName, setTypeName] = useState("");
  const [dealerId, setDealerId] = useState(editData?.dealerId || "");
  const [driveTrainId, setDriveTrainId] = useState(
    editData?.driveTrainId || ""
  );
  const [stateId, setStateId] = useState(editData?.stateId || null);
  const [typeId, setTypeId] = useState(editData?.typeId || "");
  const [fuelId, setFuelId] = useState(editData?.fuelId || "");
  const { companyNameHeader, dealerNameHeader } = useCompany();
  const [totalMiles, setTotalMiles] = useState(state?.data?.totalMiles);
  const [annualMilage, setAnnualMilage] = useState(0);
  const [monthlyMilage, setMonthlyMilage] = useState(0);
  const { register, handleSubmit, setValue, reset, getValues } = useForm();
  const [dealerIdValue, setDealerIdValue] = useState();
  const [dealerName, setDealerName] = useState("");
  const { getListDealerApi, dealerList, newFleetNo } = useDealer();
  const [typeData, setTypeData] = useState();
  const [vinValue, setVinValue] = useState("00000000000000000");
  const [makeName, setMakeName] = useState();
  const [yearCsv, setYearCsv] = useState();
  const [modelCsv, setModelCsv] = useState();
  const [stateCsv, setCsvState] = useState();
  const [driverName, setDriverName] = useState();
  const [divisionOfCompany, setDivisionCompany] = useState();
  const [operationalLocations, setOperationLocation] = useState();
  const userType = localStorage.getItem("type");
  const companyId = getCompanyId(userType, companyNameHeader);
  const [newAdd, setNewAdd] = useState("");
  useEffect(() => {
    if (state?.data) {
      setDriverName(editData?.drivername);
      setDivisionCompany(editData?.divisionOfCompany);
      setOperationLocation(editData?.operationalLocations);
      setAgeCount(editData?.age);
    } else {
      setDriverName("");
      setDivisionCompany("");
      setOperationLocation("");
    }
  }, [editData, setValue, state?.data]);

  useEffect(() => {
    let totalMilesValue = ageCount > 0 ? ageCount : 1;
    let totalMileage = totalMiles?.toString()?.replace(/,/g, "");
    if (Number(totalMilesValue) > 0 && Number(totalMileage) > 0) {
      let annualMilage = Number(totalMileage) / Number(totalMilesValue);
      let monthlyMilage = annualMilage / 12;
      setAnnualMilage(annualMilage);
      setMonthlyMilage(monthlyMilage);
    }
  }, [editData, totalMiles, ageCount]);
  useEffect(() => {
    getFuelListApi();
    getTypeListApi();
    getDriveTrainListApi();
    getDealerApi();
    getStateListApi();
  }, []);
  useEffect(() => {
    if (fleetNoValue) {
      let filterValue = fleetList?.filter(
        (item) => item?.fleetNo === fleetNoValue
      );
      if (filterValue?.length > 0) {
        setEditData(filterValue[0]);
        getFleetListApi({
          companyId: companyId,
          fleetId: filterValue[0].fleetId,
          dealerId: dealerNameHeader?.id,
        });
      }
    } else if (companyNameHeader) {
      getFleetListApi({ companyId: companyId, dealerId: dealerNameHeader?.id });
    }
  }, [companyNameHeader]);

  useEffect(() => {
    if (editData) {
      setYearValue(editData?.yearId);
      setFuelId(editData?.fuelId);
      setTypeId(editData?.typeId);
      setDriveTrainId(editData?.driveTrainId);
      setMpgData(editData?.mpg);
      setVinValue(editData?.vin);
      setStateId(editData?.stateId);
    }
  }, [editData]);

  const handleChangeTotalMiles = (e) => {
    setTotalMiles(e.target.value);
  };

  useEffect(() => {
    if (typeData) {
      setTypeName(typeData?.name);
    } else if (editData?.type) {
      setTypeName(editData?.type);
    } else {
      setTypeName("");
    }
  }, [editData, typeData]);
  const handleDealerChange = (event, value) => {
    let data = event.target.value;
    setDealerId(data);
  };
  const handleFuelChange = (event) => {
    let data = event.target.value;
    setFuelId(data);
  };
  const handleDriveTrainChange = (event) => {
    let data = event.target.value;
    setDriveTrainId(data);
  };
  const handleStateChange = (event) => {
    let data = event.target.value;
    let filterdData = stateList?.filter((state) => state?.id === data)?.[0];
    setCsvState(filterdData);
    setStateId(data);
  };
  useEffect(() => {
    if (companyNameHeader) {
      getListDealerApi({ companyId: companyId });
    }
  }, [companyNameHeader]);

  useEffect(() => {
    if (dealerList?.length > 0) {
      setDealerIdValue(dealerList[0].dealerId);
      setDealerName(dealerList[0].dealerName);
    } else {
      setDealerIdValue(null);
      setDealerName("");
    }
  }, [dealerList, companyNameHeader]);
  const handleSaveFleet = async (values) => {
    const totalMileRemoveComma = totalMiles?.toString()?.replace(/,/g, "");
    const totalMilesValue = Number(totalMileRemoveComma);
    const vinValueCount = vinValue?.length;
    // if (vinValueCount != 17) {
    //   showToast("Please enter the 17-digit VIN number.");
    //   return;
    // }
    let payload = {
      ...values,
      driverName: driverName,
      divisionOfCompany: divisionOfCompany,
      operationalLocations: operationalLocations,
      age: ageCount,
      yearId: yearValue,
      makeId: makeValue,
      modelId: modelValueData?.modelId
        ? modelValueData?.modelId
        : modelValueData,
      fuelId: fuelId,
      driveTrainId: driveTrainId,
      typeId: typeId,
      dealerId: dealerId ? dealerId : dealerIdValue,
      stateId: stateId,
      companyId: companyId,
      mpg: mpgData,
      totalMiles: totalMilesValue,
      annualMileage: annualMilage,
      monthlyMileage: monthlyMilage,
      Deleted: false,
      vin: vinValue,
      cpmCategory:
        typeName.toLowerCase() == TypeName.suv ||
        typeName.toLowerCase() == TypeName.sedan ||
        typeName.toLowerCase() == TypeName.HD ||
        typeName.toLowerCase() == TypeName.LD ||
        typeName.toLowerCase() == TypeName.VAN
          ? "Standard"
          : typeName.toLowerCase() == TypeName.EV
          ? "EV"
          : typeName.toLowerCase() == TypeName.MD
          ? "MD"
          : "",
    };
    let csvPayload = {
      ...values,
      year: Number(yearCsv),
      totalMiles: Number(totalMiles),
      monthlyMileage: Number(monthlyMilage),
      make: makeName,
      model: modelCsv,
      vin: vinValue,
      dealer: dealerName,
      company: companyNameHeader?.companyName,
      stateCode: stateCsv?.code,
      driverName: driverName,
      divisionOfCompany: divisionOfCompany,
      operationalLocations: operationalLocations,
    };

    let editPayload = {
      ...values,
      id: editData?.fleetId,
      vin: vinValue,
      fleetNo: editData?.fleetNo,
      companyId: companyId,
      yearId: yearValue,
      driverName: driverName,
      age: ageCount,
      makeId: makeValue,
      divisionOfCompany: divisionOfCompany,
      operationalLocations: operationalLocations,
      modelId: modelValueData?.modelId
        ? modelValueData?.modelId
        : modelValueData,
      fuelId: fuelId,
      typeId: typeId,
      driveTrainId: driveTrainId,
      dealerId: dealerId ? dealerId : dealerIdValue,
      mpg: mpgData,
      stateId: stateId,
      annualMileage: annualMilage,
      monthlyMileage: monthlyMilage,
      totalMiles: totalMilesValue,
      cpmCategory:
        typeName.toLowerCase() == TypeName.suv ||
        typeName.toLowerCase() == TypeName.sedan ||
        typeName.toLowerCase() == TypeName.HD ||
        typeName.toLowerCase() == TypeName.LD ||
        typeName.toLowerCase() == TypeName.VAN
          ? "Standard"
          : typeName.toLowerCase() == TypeName.EV
          ? "EV"
          : typeName.toLowerCase() == TypeName.MD
          ? "MD"
          : "",
    };
    if (
      makeValue == null ||
      yearValue == null ||
      stateId == null ||
      modelValueData == null ||
      totalMiles == null
    ) {
      if (yearValue == null) {
        showToast("Year field is required!");
      } else if (makeValue == null) {
        showToast("Make field is required!");
      } else if (stateId == null) {
        showToast("State field is required!");
      } else if (modelValueData == null) {
        showToast("Model field is required!");
      } else if (totalMiles == null) {
        showToast("totalMiles field is required!");
      }
    } else if (dealerName == "" && dealerId == "") {
      showToast("Dealer field is required!");
    } else {
      let payload = {
        year: yearValue,
        make: makeValue,
        modelId: modelValueData?.modelId,
        model: modelValueData?.model,
        newAdd: newAdd,
      };
      if (state?.data) {
        const calculationYearId = yearValue ? yearValue : editData?.yearId;
        const yearDataValue = year?.find((yr) => yr?.id === calculationYearId);

        let singleFleetUpload = {
          year: yearDataValue?.year,
          totalMiles: totalMiles ? totalMilesValue : editData?.totalMiles,
          fleetNo: editData?.fleetNo,
          make: makeName ? makeName : editData?.make,
          model: modelValueData?.model
            ? modelValueData?.model
            : editData?.model,
          vin: vinValue ? vinValue : editData?.vin,
          stateCode: stateCsv?.code ? stateCsv?.code : editData?.stateCode,
          dealer: dealerName ? dealerName : editData?.dealerName,
          company: companyNameHeader?.companyName
            ? companyNameHeader?.companyName
            : editData?.companyName,
          typeId: typeId ? typeId : editData?.typeId,
          mpg: mpgData,
          fuelId: fuelId ? fuelId : editData?.fuelId,
        };
        await singleFleetUpdateDealerApi(singleFleetUpload);
        await editFleetListApi({ values: editPayload, newAdd: payload });
      } else {
        if (dealerName == "") {
          let payloadDealer = {
            dealerId: dealerId,
            companyId: companyId,
          };
          await addDealerCompanyApi(payloadDealer);
        }
        await updateFleetApi(csvPayload, payload);
        // await createFleetListApi(payload);
      }
    }
  };
  return (
    <Box className={classes.fleetListInformation}>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.headerFleet}
          padding={"10px !important"}
        >
          <span>
            {editData ? `${selectedContent} : ` : selectedContent}
            {editData ? editData.fleetNo : ""}
          </span>
          <div className={classes.headerButtonNext}>
            <Button
              className={classes.headerSaveButton}
              onClick={handleSubmit(handleSaveFleet)}
              startIcon={<Save />}
              variant="contained"
            >
              Save
            </Button>
          </div>
        </Grid>
        <GeneralInfoFleet
          setMakeName={setMakeName}
          setMakeValue={setMakeValue}
          setAgeCount={setAgeCount}
          setVinValue={setVinValue}
          setDealerId={setDealerId}
          setFuelId={setFuelId}
          setDriveTrainId={setDriveTrainId}
          setStateId={setStateId}
          setMpgData={setMpgData}
          setTypeData={setTypeData}
          setTypeId={setTypeId}
          yearValue={yearValue}
          setYearValue={setYearValue}
          typeList={typeList}
          ageCount={ageCount}
          year={year}
          vinValue={vinValue}
          dealerId={dealerId}
          fuelId={fuelId}
          driveTrainId={driveTrainId}
          stateId={stateId}
          mpgData={mpgData}
          typeId={typeId}
          editData={editData}
          fuelList={fuelList}
          driveTrainList={driveTrainList}
          typeName={typeName}
          dealer={dealer}
          stateList={stateList}
          dealerName={dealerName}
          makeValue={makeValue}
          modelValueData={modelValueData}
          setModelValueData={setModelValueData}
          companyNameHeader={companyNameHeader}
          // setYear={setYear}
          setYearCsv={setYearCsv}
          setModelCsv={setModelCsv}
          setCsvState={setCsvState}
          driverName={driverName}
          setDriverName={setDriverName}
          divisionOfCompany={divisionOfCompany}
          setDivisionCompany={setDivisionCompany}
          operationalLocations={operationalLocations}
          setOperationLocation={setOperationLocation}
          newFleetNo={newFleetNo}
          setNewAdd={setNewAdd}
        />

        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              className={classes.textGeneralInfo}
            >
              miles information
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <Box className={classes.divMt10}>
                  <Box className={classes.fleetListGenInfoDiv}>
                    <span className={classes.fleetListTotalMileageText}>
                      Total Miles
                    </span>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      // type="number"
                      className={classes.textinput}
                      defaultValue={
                        addComma(editData?.totalMiles)
                          ? addComma(editData?.totalMiles)
                          : addComma(totalMiles)
                      }
                      onChange={handleChangeTotalMiles}
                    />
                  </Box>
                </Box>
                <Box className={classes.divMt10}>
                  <Box className={classes.fleetListGenInfoDiv}>
                    <span className={classes.fleetListMileageText}>
                      Annual Mileage
                    </span>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      // type="number"
                      disabled
                      value={addComma(Math.round(annualMilage))}
                      className={classes.textinput}
                      {...register("annualMileage", {
                        // valueAsNumber: true,
                      })}
                    />
                  </Box>
                </Box>
                <Box className={classes.divMt10}>
                  <Box className={classes.fleetListGenInfoDiv}>
                    <span className={classes.fleetListMileageText}>
                      Monthly Mileage
                    </span>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      // type="number"
                      disabled
                      value={addComma(Math.round(monthlyMilage))}
                      className={classes.textinput}
                      {...register("monthlyMileage", {
                        // valueAsNumber: true,
                      })}
                    />
                  </Box>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Box>
  );
};
