import { deleteData, postData, postFormData } from "./base-api";
import ApiUris from "./apiUris";

/**
 * Help Desk List Api method declarations
 */
export const helpDeskListApi = {
  getHelpDeskList(request) {
    return postData(ApiUris.helpDeskApi.getHelpDesk, request);
  },
  createHelpDesk(request) {
    return postFormData(ApiUris.helpDeskApi.createHelpDesk, request);
  },
  editHelpDesk(request) {
    return postFormData(ApiUris.helpDeskApi.updateHelpDesk, request);
  },
  deleteHelpDesk(id) {
    return deleteData(`${ApiUris.helpDeskApi.deleteHelpDesk}?id=${id}`);
  },
};
