import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Badge,
  TextField,
  Autocomplete,
  Button,
} from "@mui/material";
import {
  AccountCircle,
  MoreVert as MoreIcon,
  Notifications as NotificationsIcon,
  Email,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useAuth,
  useColor,
  useCompany,
  useDealer,
  useDealerCompany,
} from "../../common/hooks";
import { layoutStyle } from "./style";
import { typeConstant } from "../../common/constants/commonFunction";
import WhatsNew from "../../components/WhatsNew";

const Header = () => {
  const classes = layoutStyle();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const navigation = useNavigate();
  const history = useLocation();
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const { colorHeader } = useColor();
  const { onLogout } = useAuth();
  const {
    companyHeader,
    getCompanyApi,
    setCompanyNameHeader,
    company,
    getCompanyLogo,
    companyLogo,
    setDealerNameHeader,
    setCompanyLogo,
  } = useCompany();
  const { getDealerCompanyListApi, dealerCompanyData, setDealerCompanyData } =
    useDealerCompany();
  const {
    dealer,
    getDealerApi,
    dealerId,
    companyDealerId,
    loading,
    setDealerId,
    setCompanyDealerId,
  } = useDealer();
  const [companyDefaultValue, setCompanyDefaultValue] = useState(null);
  const [headerCompany, setHeaderCompany] = useState([]);
  const userType = localStorage.getItem("type");
  const typeId = parseInt(localStorage.getItem("typeId"));
  const [companiesOption, setCompaniesOption] = useState([]);
  const [dealerDefaultValue, setDealerDefaultValue] = useState(null);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    getCompanyApi();
    getDealerApi();
  }, []);

  useEffect(() => {
    if (userType === "admin") {
      getCompanyApi();
    } else if (userType === "dealer") {
      if (typeId) {
        getDealerCompanyListApi({ dealerId: typeId });
      } else {
        setDealerCompanyData([]);
      }
    } else {
      onLogout();
    }
  }, [typeId, userType]);

  useEffect(() => {
    if (userType == typeConstant.dealer && companyDefaultValue) {
      getCompanyLogo(companyDefaultValue?.id);
    }
  }, [companyDefaultValue]);

  const handleDealerChange = (event, value) => {
    setCompanyDefaultValue(null);
    setCompanyNameHeader(null);
    if (value?.companies) {
      setCompaniesOption(value?.companies);
    }
    setDealerDefaultValue(value);
    setDealerNameHeader(value);
    setDealerId(null);
    setCompanyDealerId(null);
  };

  useEffect(() => {
    if (companyDealerId && dealerId) {
      let companyId;
      let dealerFilter = dealer?.filter((items) => items.id == dealerId);
      if (dealerFilter?.length > 0) {
        companyId = dealerFilter[0]?.companies?.filter(
          (items) => items.id == companyDealerId
        );
      } else {
        companyId = company?.filter((items) => items.id == companyDealerId);
      }
      setDealerDefaultValue(dealerFilter[0]);
      if (companyId?.length > 0) {
        setCompanyDefaultValue(companyId[0]);
        setCompanyNameHeader(companyId[0]);
      }
      if (dealerFilter?.length > 0) {
        setDealerNameHeader(dealerFilter[0]);
        setCompaniesOption(dealerFilter[0]?.companies);
      }
    } else if (userType === "admin" && companyHeader) {
      let dealerFilter = dealer?.filter((items) => items.id == dealerId);
      if (dealerFilter?.length > 0) {
        setDealerDefaultValue(dealerFilter[0]);
      }
      if (companiesOption && dealerFilter?.length == 0) {
        // setCompanyDefaultValue(null);
        // setCompanyNameHeader(null);
      } else {
        if (dealer.length > 0) {
          setDealerDefaultValue(dealer[0]);
          if (dealer[0].companies.length > 0) {
            setCompaniesOption(dealer[0].companies);
            setCompanyNameHeader(dealer[0].companies[0]);
            setCompanyDefaultValue(dealer[0].companies[0]);
          } else {
            setCompaniesOption([]);
            setCompanyNameHeader(null);
            setCompanyDefaultValue(null);
          }
        }
      }
    } else if (userType === "dealer" && dealer) {
      let type = localStorage.getItem("typeId");
      let dealerFilter = dealer?.filter((items) => items.id == type);
      setHeaderCompany(dealerFilter[0]?.companies);
      setDealerDefaultValue(dealerFilter[0]);
      if (dealerFilter.length > 0) {
        if (!companyDefaultValue) {
          setCompaniesOption(dealerFilter[0]?.companies);
          if (dealerFilter[0]?.companies?.length > 0) {
            let companyValue = dealerFilter[0]?.companies[0];
            setCompanyDefaultValue(companyValue);
            setCompanyNameHeader(companyValue);
          }

          setDealerNameHeader(dealerFilter[0]);
        }
      } else {
        setCompanyDefaultValue(null);
        setCompanyNameHeader([]);
      }
    }
  }, [
    userType,
    companyHeader,
    dealerCompanyData,
    companiesOption,
    dealerId,
    companyDealerId,
    dealer,
  ]);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleProfile = () => {
    setAnchorEl(null);
    navigation("/profile");
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogout = async () => {
    setDealerId(null);
    setCompanyDealerId(null);
    setCompanyNameHeader(null);
    setDealerNameHeader(null);
    setCompanyLogo(null);
    await onLogout();
  };

  const handleCompanyChange = (e, value) => {
    setCompanyNameHeader(value);
    setCompanyDefaultValue(value);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleProfile}>Profile</MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails">
          <Badge badgeContent={4} color="error">
            <Email />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls={menuId}
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          bgcolor: colorHeader || "#18333A",
        }}
        className={classes.appBarShift}
      >
        <Toolbar>
          <Box className={classes.titleHeader}>
            <Box className={classes.newVersionButtonBox}>
              <Button
                className={classes.newVersionButton}
                onClick={() => setOpen(true)}
              >
                What's New ?
              </Button>
            </Box>
            {userType == typeConstant.dealer && dealerDefaultValue && (
              <Box className={classes.dealerTitle}>
                <span className={classes.dealerNameTitle}>
                  Dealer : {dealerDefaultValue?.dealerName}
                </span>
              </Box>
            )}
            <Box sx={{ flexGrow: 1 }} />
            {history.pathname !== "/reports" &&
              userType == typeConstant.dealer &&
              companyLogo?.companyImage && (
                <Box className={classes.dealerLogo}>
                  <img
                    src={`data:image/png;base64,${companyLogo?.companyImage}`}
                    height="50px"
                    width="150px"
                    objectFit="cover"
                  />
                </Box>
              )}
          </Box>
          <Box
            sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
          >
            {userType === typeConstant.admin && (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="small"
                loading={loading}
                loadingText="Fetching..."
                variant="outlined"
                sx={{
                  width: "150px",
                  marginRight: "10px",
                  ".MuiSvgIcon-root, .MuiInputBase-root ": { color: "#ffffff" },
                  ".MuiInputBase-root": {
                    fontSize: "12px",
                    "&:before": { borderBottom: "1px solid rgb(186,199,30)" },
                    "&:after": { borderBottom: "2px solid #9cbf2e" },
                  },
                }}
                value={dealerDefaultValue}
                options={dealer}
                onChange={handleDealerChange}
                getOptionLabel={(option) => option.dealerName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Dealer"
                    sx={{
                      ".MuiFormLabel-root": {
                        color: "white !important",
                        fontSize: 12,
                      },
                    }}
                  />
                )}
              />
            )}
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              variant="outlined"
              sx={{
                width: "150px",
                marginRight: "10px",
                ".MuiSvgIcon-root, .MuiInputBase-root ": { color: "#ffffff" },
                ".MuiInputBase-root": {
                  fontSize: "12px",
                  "&:before": { borderBottom: "1px solid rgb(186,199,30)" },
                  "&:after": { borderBottom: "2px solid #9cbf2e" },
                },
              }}
              loading={loading}
              loadingText="Fetching..."
              options={companiesOption}
              getOptionLabel={(option) => option.companyName}
              value={companyDefaultValue}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Company"
                  sx={{
                    ".MuiFormLabel-root": {
                      color: "white !important",
                      fontSize: 12,
                    },
                  }}
                />
              )}
              onChange={handleCompanyChange}
            />
            <Box
              sx={{
                textTransform: "capitalize",
                fontSize: 14,
                fontWeight: "500",
              }}
            >
              {localStorage.getItem("name")}
            </Box>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {<WhatsNew open={open} setOpen={setOpen} />}
    </>
  );
};

export default Header;
