import React, { useState, createContext, useCallback } from "react";
import { CommentsApi } from "../api";

export const CommentsContext = createContext();

export const CommentsProvider = ({ children }) => {
  //   const [helpdeskList, setHelpdeskList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const [createLoading, setCreateLoading] = useState(false);

  const getCommentsApi = async (values) => {
    setLoading(true);
    const response = await CommentsApi.getCommentsList({
      ...values,
      limit: values?.limit ? values.limit : 100,
      offset: values?.offset ? values.offset : 0,
      filter: {
        ...values?.filter,
      },
      sorting: {
        fieldName: "",
        direction: "",
      },
    });
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      //   setHelpdeskList(response?.data?.data);
      setComments(response?.data?.data);
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };

  const createCommentsApi = async (values) => {
    setCreateLoading(true);
    const response = await CommentsApi.createComments(values);
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      //   setHelpdeskList(response?.data?.data);
      getCommentsApi({
        filter: { userId: values?.userId },
        limit: 10000,
        offset: 0,
      });
      setCreateLoading(false);
    } else {
      setCreateLoading(false);
    }

    return response.data;
  };
  const editCommentsApi = async (values) => {
    setLoading(true);
    const response = await CommentsApi.editComments(values);
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      getCommentsApi({
        companyId: values?.companyId,
        limit: 10000,
        offset: 0,
      });
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };

  const contextValue = {
    // helpdeskList,
    // setHelpdeskList,
    loading,
    setLoading,
    comments,
    setComments,
    createLoading,
    setCreateLoading,
    getCommentsApi: useCallback((request) => {
      getCommentsApi(request);
    }, []),
    createCommentsApi: useCallback((request, userId) => {
      createCommentsApi(request, userId);
    }, []),
    editCommentsApi: useCallback((request) => {
      editCommentsApi(request);
    }, []),
  };

  return (
    <CommentsContext.Provider value={contextValue}>
      {children}
    </CommentsContext.Provider>
  );
};
