import React, { useState, createContext, useCallback } from "react";
import { UserAPi } from "../api";
import { executeApi } from "./base-context";
import { showToast } from "../../components/Toast";

/**
 * Authentication context
 */
export const UserContext = createContext();

/**
 * Authentication data provider
 */
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState([]);
  const [userData, setUserData] = useState([]);
  // const [typeId, setTypeId] = useState([])
  const [loading, setLoading] = useState(false);
  const [selectedNav, setSelectedNav] = useState("Dashboard");
  const userId = localStorage.getItem("cashflow_admin_user_id");
  const executeAppointmentAPI = async (apiFunction, request) => {
    const response = await executeApi(apiFunction, request);
    return response;
  };

  const getUser = async (values) => {
    try {
      setLoading(true);
      const response = await UserAPi.getUser({
        limit: values?.limit ? values.limit : 10000,
        offset: values?.offset ? values.offset : 0,
        filter: {
          ...values?.filter,
        },
        sorting: {
          fieldName: "",
          direction: "",
        },
      });

      if (response?.data?.data) {
        setUser(response.data.data);
        setUserData(response.data.data);
        setLoading(false);

        // const user = response?.data?.data?.find((item) => item.id === parseInt(userId));

        // if (user) {
        //   setTypeId(user?.typeId)
        // } else {
        // }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const createUser = async (request) => {
    const userType = localStorage.getItem("type");
    const userTypeId = localStorage.getItem("cashflow_admin_user_id");
    // setLoading(true);
    try {
      setLoading(true);
      const response = await UserAPi.createUser(request);
      if (response.data.succeeded === true) {
        // alert(response.data.messages.length===0?"aaaa":"bbbb");/

        if (userType?.toLowerCase() === "dealer") {
          getUser({ filter: { type: userType, createdBy: userTypeId } });
        } else if (userType?.toLowerCase() === "admin") {
          getUser();
        }
        setLoading(false);
      } else {
        showToast(response.data.messages[0]);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const updateUser = async (request) => {
    const userType = localStorage.getItem("type");
    const userTypeId = localStorage.getItem("cashflow_admin_user_id");
    // setLoading(true);
    try {
      setLoading(false);
      const response = await UserAPi.updateUser(request);
      if (response.data.succeeded === true) {
        if (userType?.toLowerCase() === "dealer") {
          getUser({ filter: { type: userType, createdBy: userTypeId } });
        } else if (userType?.toLowerCase() === "admin") {
          getUser();
        }
        setUserData(null);
        setLoading(false);

        return response;
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteUser = async (id) => {
    const userType = localStorage.getItem("type");
    const userTypeId = localStorage.getItem("cashflow_admin_user_id");
    // setLoading(true);
    try {
      // setLoading(true)
      const response = await UserAPi.deleteUser(id);
      if (response.data.succeeded === true) {
        if (userType === "dealer") {
          getUser({ filter: { type: userType, typeId: userTypeId } });
        } else {
          getUser();
        }
        setLoading(false);
      }
    } catch (error) {
      // setLoading(false)
    }
  };
  const getUserById = async (values) => {
    try {
      setLoading(true);
      const response = await executeAppointmentAPI(UserAPi.getbyId, values);

      if (response?.data) {
        setUserData(response.data);
      } else {
        setUserData([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const contextValue = {
    user,
    loading,
    setUser,
    createUser,
    getUser,
    setUserData,
    updateUser,
    userData,
    selectedNav,
    setSelectedNav,
    // typeId,
    deleteUser: useCallback((id) => {
      deleteUser(id);
    }, []),
    getUserById: useCallback((request) => {
      getUserById(request);
    }, []),
  };

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};
