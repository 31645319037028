import { deleteData, getData, postData } from "./base-api";
import ApiUris from "./apiUris";

/**
 * User Api method declarations
 */
export const UserLogsAPi = {
  getUserLogs(request) {
    return postData(ApiUris.userLogsApi.getUserLogs, request);
  },

  createUserLogs(request) {
    return postData(ApiUris.userLogsApi.createUserLogs, request);
  },

  updateUserLogs(request) {
    return postData(ApiUris.userLogsApi.updateUserLogs, request);
  },
  GetAllLoggedUserWithUserName(request) {
    return postData(
      `${ApiUris.userLogsApi.GetAllLoggedUserWithUserName}`,
      request
    );
  },
};
