import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  fundingNewVehicleMatchData,
  transformData,
} from "../common/constants/commonFunction";
import { useModel, useNewVehicle } from "../common/hooks";
import { layoutStyle } from "../pages/layout-ui/style";
import { ActionFormFundingNewVehicle } from "./Funding-New-Vehicle/SetAllDataNewVehicleForm";
import { FUNDING_NEW_VEHICLE_CONSTANT } from "../common/constants/popupMessage";
import { ModelSelect } from "./ModelSelect";

const AddActionDialogNewVehicle = ({
  modalContent,
  closeModal,
  selectedRows,
  dropdownValue,
  keyValue,
  companyId,
  dealerId,
  setSelectedRows,
}) => {
  const classes = layoutStyle();
  const { multipleNewVehicleApi } = useNewVehicle();
  const { modelData, getModelList } = useModel();
  const [payloadValue, setPayloadValue] = useState();
  const [dropDownValue, setDropDownValue] = useState([]);
  const [textFieldValue, setTextFieldValue] = useState("");
  // const [equityGain, setEquityGain] = useState(0);
  const [yearValue, setYearValue] = useState();
  const [yearName, setYearName] = useState();
  const [makeValue, setMakeValue] = useState();
  const [makeName, setMakeName] = useState();
  const [modelValueData, setModelValueData] = useState({
    modelId: null,
    model: "",
  });
  const [modelCsv, setModelCsv] = useState();
  const [mpgData, setMpgData] = useState();
  const [newAdd, setNewAdd] = useState("");

  useEffect(() => {
    if (selectedRows[0]?.newMakeId && selectedRows[0]?.newYearId) {
      getModelList({
        makeId: selectedRows[0]?.newMakeId,
        yearId: selectedRows[0]?.newYearId,
      });
    } else {
      setDropDownValue([]);
    }
  }, [selectedRows[0]?.newMakeId && selectedRows[0]?.newYearId]);

  useEffect(() => {
    if (modelData?.length > 0) {
      setDropDownValue([...modelData]);
    }
    if (dropdownValue?.length > 0) {
      setDropDownValue([...dropdownValue]);
    }
  }, [modelData, dropdownValue]);

  const handleChangeTextField = async (event) => {
    const value = event.target.value;
    let editPayload = {};
    let equityGain = {};
    let ids = selectedRows?.map((items) => items.newVehicleId);
    if (modalContent === FUNDING_NEW_VEHICLE_CONSTANT.SET_PAYMENT_AMOUNT) {
      editPayload = {
        updateIds: ids.map((item) => ({
          id: item,
          paymentAmount: Number(value),
        })),
      };
    } else if (
      modalContent === FUNDING_NEW_VEHICLE_CONSTANT.SET_NEW_VEHICLE_COST
    ) {
      equityGain = selectedRows?.map(
        (items) =>
          items.newEstimatedResaleValue -
          Number(value) * (items?.residualPercentage / 100)
      );
      const transformedData = transformData(ids, equityGain);

      editPayload = {
        updateIds: transformedData.map((item) => ({
          ...item,
          newVehicleCost: Number(value),
        })),
      };
    } else if (
      modalContent === FUNDING_NEW_VEHICLE_CONSTANT.SET_NEW_VEHICLE_MPG
    ) {
      editPayload = {
        updateIds: ids.map((item) => ({
          id: item,
          mpg: Number(value),
        })),
      };
    } else if (
      modalContent === FUNDING_NEW_VEHICLE_CONSTANT.SET_NEW_VEHICLE_FUEL
    ) {
      editPayload = {
        updateIds: ids.map((item) => ({
          id: item,
          newFuelId: value,
        })),
      };
    } else if (modalContent === FUNDING_NEW_VEHICLE_CONSTANT.SET_RESIDUAL) {
      equityGain = selectedRows?.map(
        (items) =>
          items.newEstimatedResaleValue -
          items.newVehicleCost * (Number(value) / 100)
      );

      const transformedData = transformData(ids, equityGain);

      editPayload = {
        updateIds: transformedData.map((item) => ({
          ...item,
          residualPercentage: Number(value),
        })),
      };
    } else if (
      modalContent ===
      FUNDING_NEW_VEHICLE_CONSTANT.SET_NEW_ESTIMATES_RESALE_VALUE
    ) {
      equityGain = selectedRows?.map(
        (items) =>
          Number(value) -
          items.newVehicleCost * (items?.residualPercentage / 100)
      );

      const transformedData = transformData(ids, equityGain);
      editPayload = {
        updateIds: transformedData.map((item) => ({
          ...item,
          newEstimatedResaleValue: Number(value),
        })),
      };
    }

    const finalPayload = {
      ...editPayload,
      companyId: companyId,
      dealerId: dealerId,
    };
    setTextFieldValue(value);
    setPayloadValue(finalPayload);
  };

  const handleChangeFleet = async (event, value) => {
    let editPayload = {};
    let ids = selectedRows?.map((items) => items.newVehicleId);
    if (modalContent === FUNDING_NEW_VEHICLE_CONSTANT.SET_FUNDING_TYPE) {
      if (
        value?.fundingType === FUNDING_NEW_VEHICLE_CONSTANT.APR ||
        value?.fundingType === FUNDING_NEW_VEHICLE_CONSTANT.CASH
      ) {
        editPayload = {
          updateIds: ids.map((item) => ({
            id: item,
            fundingTypeId: value?.id,
            residualPercentage: 0,
            equityGain: 0,
          })),
        };
      } else if (value?.fundingType === FUNDING_NEW_VEHICLE_CONSTANT.LEASE) {
        editPayload = {
          updateIds: ids.map((item) => ({
            id: item,
            fundingTypeId: value?.id,
          })),
        };
      }
    } else if (
      modalContent === FUNDING_NEW_VEHICLE_CONSTANT.SET_NEW_VEHICLE_MAKE
    ) {
      editPayload = {
        updateIds: ids.map((item) => ({
          id: item,
          makeId: value?.id,
        })),
      };
    } else if (
      modalContent === FUNDING_NEW_VEHICLE_CONSTANT.SET_NEW_VEHICLE_MODEL
    ) {
      editPayload = {
        updateIds: ids.map((item) => ({
          id: item,
          modelId: value?.modelId,
          mpg: value?.mpg,
        })),
      };
    } else if (
      modalContent === FUNDING_NEW_VEHICLE_CONSTANT.SET_NEW_VEHICLE_YEAR
    ) {
      editPayload = {
        updateIds: ids.map((item) => ({
          id: item,
          yearId: value?.id,
        })),
      };
    } else if (
      modalContent === FUNDING_NEW_VEHICLE_CONSTANT.SET_NEW_VEHICLE_FUEL
    ) {
      editPayload = {
        updateIds: ids.map((item) => ({
          id: item,
          newFuelId: value?.id,
        })),
      };
    }
    const finalPayload = {
      ...editPayload,
      companyId: companyId,
      dealerId: dealerId,
    };
    setPayloadValue(finalPayload);
  };

  const handleClickAuto = async () => {
    let payload = {
      year: yearValue,
      make: makeValue,
      modelId: modelValueData?.modelId,
      model: modelValueData?.model,
      newAdd: newAdd,
    };
    let payloadValues;
    if (modalContent === FUNDING_NEW_VEHICLE_CONSTANT.SET_NEW_VEHICLE_MODEL) {
      let ids = selectedRows?.map((items) => items.newVehicleId);
      payloadValues = {
        updateIds: ids.map((item) => ({
          id: item,
          modelId: modelValueData?.modelId,
        })),
        companyId: companyId,
        dealerId: dealerId,
      };
    }
    let yearValueId = selectedRows?.length > 0 ? selectedRows[0]?.yearId : null;
    let payload23 = {
      year: yearValueId,
      make: makeValue,
      modelId: modelValueData?.modelId,
      model: modelValueData?.model,
      newAdd: newAdd,
    };
    const payloadValuesFinal = payloadValues ? payloadValues : payloadValue;
    await multipleNewVehicleApi({
      values: payloadValuesFinal,
      newAdd: payload23,
    });
    setTextFieldValue("");
    setSelectedRows([]);
    closeModal();
  };
  useEffect(() => {
    if (
      modalContent === FUNDING_NEW_VEHICLE_CONSTANT.SET_NEW_VEHICLE_MODEL &&
      selectedRows?.length > 0
    ) {
      let selectRow = selectedRows[0];
      setYearValue(selectRow.yearId);
      setMakeValue(selectRow.makeId);
    }
  }, [selectedRows, modalContent]);
  return (
    <Box>
      <Drawer
        open={!!modalContent}
        onClose={closeModal}
        // open={isModalOpen}
        // onClose={closeModal}
        maxWidth="sm"
        anchor="bottom"
        fullHeight
        style={{ top: "100px ", bottom: "56px !important" }}
        className="DrawerName"
        // onChange={handleChangeFleet}
      >
        <DialogTitle id="modal-title" className={classes.dailogTitle}>
          {modalContent}
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText id="modal-description">
            {modalContent !== "Set All" &&
            modalContent !==
              FUNDING_NEW_VEHICLE_CONSTANT.SET_NEW_VEHICLE_MODEL ? (
              <>
                <Box className={classes.actionDialog}>
                  <Box className={classes.dailogTitleInput}>{modalContent}</Box>

                  <Box style={{ width: "75%" }}>
                    {fundingNewVehicleMatchData.includes(modalContent) ? (
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={dropDownValue}
                        fullWidth
                        getOptionLabel={(option) => option[keyValue]}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.id}>
                              {option[keyValue]}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" />
                        )}
                        onChange={handleChangeFleet}
                      />
                    ) : (
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        sx={{ width: "100%" }}
                        value={textFieldValue}
                        onChange={handleChangeTextField}
                      />
                    )}
                  </Box>
                </Box>
                <Box className={classes.actionButtonDialog}>
                  <Button
                    onClick={() => closeModal()}
                    className={classes.fleetListNewButton}
                  >
                    Back
                  </Button>
                  <Button
                    className={classes.fleetListNewButton}
                    onClick={handleClickAuto}
                  >
                    {modalContent}
                    {/* Save */}
                  </Button>
                </Box>
              </>
            ) : modalContent ===
              FUNDING_NEW_VEHICLE_CONSTANT.SET_NEW_VEHICLE_MODEL ? (
              <>
                <ModelSelect
                  makeValue={makeValue}
                  modelValueData={dropdownValue}
                  setModelValueData={setModelValueData}
                  setMpgData={setMpgData}
                  yearValue={yearValue}
                  setModelCsv={setModelCsv}
                  width={500}
                  setNewAdd={setNewAdd}
                  actionModel={true}
                />
                <Box className={classes.actionButtonDialog}>
                  <Button
                    onClick={() => closeModal()}
                    className={classes.fleetListNewButton}
                  >
                    Back
                  </Button>
                  <Button
                    className={classes.fleetListNewButton}
                    onClick={handleClickAuto}
                  >
                    {modalContent}
                    {/* Save */}
                  </Button>
                </Box>
              </>
            ) : (
              modalContent === "Set All" && (
                <>
                  <ActionFormFundingNewVehicle
                    dropdownValue={dropdownValue}
                    closeModal={closeModal}
                    selectedRows={selectedRows}
                    dealerId={dealerId}
                    companyId={companyId}
                  />
                </>
              )
            )}
          </DialogContentText>
        </DialogContent>
      </Drawer>
    </Box>
  );
};

export default AddActionDialogNewVehicle;
