import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { layoutStyle } from "../../pages/layout-ui/style";
import { useModel, useNewVehicle, useYear } from "../../common/hooks";
import { DISPLAY_MESSAGES } from "../../common/constants/popupMessage";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";

export const ReplaceExistingVehicle = ({
  open,
  selectedRowData,
  companyId,
  dealerId,
  setOpen,
  fleetList,
  setSelectedRows,
}) => {
  const classes = layoutStyle();
  const { multipleNewVehicleApi } = useNewVehicle();
  const { year, getYearApi } = useYear();
  const { modelData, modelLoading, getModelList } = useModel();

  useEffect(() => {
    getYearApi();
    getModelList();
  }, []);

  const handleClick = async () => {
    const currentYear = new Date().getFullYear();
    const yearId = year?.filter((item) => item.year === currentYear);
    let yearDataValue;
    if (yearId?.length > 0) {
      yearDataValue = yearId[0]?.id;
    }
    let selectedValue = [];
    modelData?.map((item, index) => {
      selectedRowData.map((data, index) => {
        if (
          item.yearId === yearDataValue &&
          data.makeId === item.makeId &&
          data.model === item.model &&
          data.newMakeId === data.modelId &&
          data.newModelId === data.modelId
        ) {
          selectedRowData[index].newModelId = item.modelId;
          selectedRowData[index].newMpg = item.mpg;
        } else if (
          item.yearId === yearDataValue &&
          data.newMakeId !== data.makeId &&
          data.newModelId !== data.modelId &&
          data.model === item.model
        ) {
          selectedRowData[index].newMakeId = item.makeId;
          selectedRowData[index].newModelId = item.modelId;
          selectedRowData[index].newMpg = item.mpg;
        }
      });
    });
    fleetList?.map((item) => {
      selectedRowData.map((data, index) => {
        if (item.FleetNo === data.FleetNo) {
          selectedRowData[index].fuelId = item.fuelId;
        }
      });
    });
    const extractedData = selectedRowData.map(
      ({
        id,
        makeId,
        modelId,
        yearId,
        newMpg,
        newMakeId,
        newModelId,
        fuelId,
      }) => ({
        id,
        makeId: newMakeId ? newMakeId : makeId,
        modelId: newModelId ? newModelId : modelId,
        yearId: yearDataValue ? yearDataValue : yearId,
        mpg: newMpg,
        fuelId: fuelId,
      })
    );

    const editPayload = {
      updateIds: extractedData.map((item) => ({
        ...item,
      })),
    };
    const finalPayload = {
      ...editPayload,
      companyId: companyId,
      dealerId: dealerId,
    };
    await multipleNewVehicleApi({ values: finalPayload });
    setOpen(false);
    setSelectedRows([]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {DISPLAY_MESSAGES.REPLACE_EXISTING_VEHICLE}
        </DialogTitle>
        <DialogActions>
          <Button
            variant="outlined"
            type="submit"
            className={classes.deleteCancelButton}
            autoFocus
            onClick={handleClose}
          >
            Cancel
          </Button>
          {modelLoading ? (
            <span>
              <CircularProgress
                color="inherit"
                className={classes.fetchingMakeModel}
              />
            </span>
          ) : (
            <Button
              variant="outlined"
              type="submit"
              className={classes.deleteOkButton}
              onClick={handleClick}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
