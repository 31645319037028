import { Edit } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { FUNDING_NEW_VEHICLE_CONSTANT, USER_CONTROL } from "./popupMessage";
import { TypeName } from "./typeName";

export const fundingNewVehicleMatchData = [
  FUNDING_NEW_VEHICLE_CONSTANT.SET_FUNDING_TYPE,
  FUNDING_NEW_VEHICLE_CONSTANT.SET_NEW_VEHICLE_MAKE,
  FUNDING_NEW_VEHICLE_CONSTANT.SET_NEW_VEHICLE_MODEL,
  FUNDING_NEW_VEHICLE_CONSTANT.SET_NEW_VEHICLE_YEAR,
  FUNDING_NEW_VEHICLE_CONSTANT.SET_NEW_VEHICLE_FUEL,
];

export const sidebarMenu = {
  dashboard: "Dashboard",
  fleetList: "Fleet List",
  replacement: "Replacement Schedule",
  maintenance: "Maintenance Analysis",
  fuel: "Fuel Analysis",
  newVehicle: "Funding/New Vehicle",
  analysisResult: "Analysis Results",
  reports: "Reports",
};
export const getCompanyId = (userType, companyNameHeader) => {
  let companyId;
  if (userType === "admin") {
    companyId = companyNameHeader?.id;
  } else {
    companyId = companyNameHeader?.companyId
      ? companyNameHeader?.companyId
      : companyNameHeader?.id;
  }
  return companyId;
};

export const typeConstant = {
  dealer: "dealer",
  admin: "admin",
};

export const getCellClass = (field, params) => {
  switch (field) {
    case "annualNeeds":
      return params.node.rowIndex !== 0 ? "" : "cellColorAnalysis";
    case "purchasedFinancial":
    case "currentPayment":
      return params.node ? "cellColorAnalysis" : "";
    case "cashOutlay":
      return params.node.rowIndex == 0 ? "" : "cellColorAnalysis";
    case "vehicleEquity":
      return "cellColorAnalysis";
    default:
      return "";
  }
};

export const getDealerId = (userType, dealer, dealerName) => {
  let companyId;
  let dealerId = [];
  if (userType == typeConstant.dealer) {
  } else {
    dealer?.map((items) => {
      if (items?.dealerName == dealerName) {
        dealerId.push(items);
      }
    });
  }

  return dealerId[0];
};

function checkDealerId(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
}

export const transformData = (ids, equityGains, value) => {
  return ids.map((id, index) => ({
    id: id,
    equityGain: equityGains[index],
  }));
};
export const formatChartTooltipValue = (val) => {
  if (val == null) {
    return "0 thousands";
  } else {
    return `${val.toFixed(2)} thousands`;
  }
};
export const formatDonutChartTooltipValue = (val) => {
  if (val == null) {
    return "0";
  } else {
    return `${val.toFixed(2)}`;
  }
};

export const checkFieldColumn = (event) => {
  if (
    event?.colDef?.headerName !== "Action" &&
    event?.colDef?.field !== "checkbox"
  ) {
    return true;
  } else {
    return false;
  }
};

export const checkbox = {
  field: "checkbox",
  headerName: "",
  minWidth: 50,
  maxWidth: 50,
  checkboxSelection: true,
  headerCheckboxSelection: "checkbox",
  headerCheckboxSelectionFilteredOnly: true,
};

export const actionColumnFunction = ({
  selectedRows,
  handleEditButton,
  handleDeleteButton,
  deleteButton,
}) => {
  let actionColumn = {
    field: "action",
    headerName: "Action",
    sortable: false,
    resizable: true,
    width: 140,
    disableClickEventBubbling: true,
    cellRenderer: (params) => (
      <>
        <IconButton
          style={{
            color: selectedRows?.includes(params?.id) ? "white" : "black",
          }}
          onClick={() => handleEditButton(params.data)}
          disabled={selectedRows?.length > 1}
        >
          <Edit />
        </IconButton>
        {deleteButton && (
          <IconButton
            style={{
              color: selectedRows?.includes(params?.id) ? "white" : "black",
            }}
            onClick={() => handleDeleteButton(params.data)}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </>
    ),
  };

  return actionColumn;
};

export const getDefaultColDef = () => {
  return {
    flex: 1,
    minWidth: 150,
    menuTabs: ["filterMenuTab"],
    filter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    enableFilter: true,
  };
};

export const setModalContentByAction = ({
  action,
  setModalContent,
  setReplaceVehicleOpen,
}) => {
  switch (action) {
    case "setPaymentAmount":
      setModalContent(FUNDING_NEW_VEHICLE_CONSTANT.SET_PAYMENT_AMOUNT);
      break;
    case "setFundingType":
      setModalContent(FUNDING_NEW_VEHICLE_CONSTANT.SET_FUNDING_TYPE);
      break;
    case "setNewVehicleMake":
      setModalContent(FUNDING_NEW_VEHICLE_CONSTANT.SET_NEW_VEHICLE_MAKE);
      break;
    case "setNewVehicleModel":
      setModalContent(FUNDING_NEW_VEHICLE_CONSTANT.SET_NEW_VEHICLE_MODEL);
      break;
    case "setNewVehicleYear":
      setModalContent(FUNDING_NEW_VEHICLE_CONSTANT.SET_NEW_VEHICLE_YEAR);
      break;
    case "setNewVehicleCost":
      setModalContent(FUNDING_NEW_VEHICLE_CONSTANT.SET_NEW_VEHICLE_COST);
      break;
    case "setNewVehicleMPG":
      setModalContent(FUNDING_NEW_VEHICLE_CONSTANT.SET_NEW_VEHICLE_MPG);
      break;
    case "setNewFuel":
      setModalContent(FUNDING_NEW_VEHICLE_CONSTANT.SET_NEW_VEHICLE_FUEL);
      break;
    case "setResidual":
      setModalContent(FUNDING_NEW_VEHICLE_CONSTANT.SET_RESIDUAL);
      break;
    case "setNewEstimatedResaleValue":
      setModalContent(
        FUNDING_NEW_VEHICLE_CONSTANT.SET_NEW_ESTIMATES_RESALE_VALUE
      );
      break;
    case "replaceExistingVehicle":
      setReplaceVehicleOpen(true);
      break;
    case "setAll":
      setModalContent(FUNDING_NEW_VEHICLE_CONSTANT.SET_ALL);
      break;
    default:
      // Handle default case if needed
      break;
  }
};

export function extractValue(obj, property, delimiter = "$", index = 1) {
  if (obj?.[property]) {
    return obj?.[property]?.split(delimiter)?.[index];
  } else {
    return "0";
  }
}

export const getFileName = (prefix) => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${prefix}_${day}${month}${year}`;
};

export const setModelContentByActionFleetList = ({
  action,
  key,
  selectedRows,
  handleExport,
  handleDeleteButton,
  setKeyValue,
  setModalContent,
}) => {
  switch (action) {
    case "export":
      handleExport();
      break;
    case "delete":
      handleDeleteButton(selectedRows);
      break;
    default:
      setKeyValue(key);
      switch (action) {
        case "setFuel":
          setModalContent("Set Fuel");
          break;
        case "setType":
          setModalContent("Set Type");
          break;
        case "setDriveTrain":
          setModalContent("Set Drive Train");
          break;
        case "setYear":
          setModalContent("Set Year");
          break;
        case "setMake":
          setModalContent("Set Make");
          break;
        case "setModel":
          setModalContent("Set Model");
          break;
        case "setMPG":
          setModalContent("Set MPG");
          break;
        case "groupType":
          setModalContent("Set Group Type");
          break;
        case "setAll":
          setModalContent("Set All");
          break;
        case "driverName":
          setModalContent("Set DriverName");
        case "State":
          setModalContent("Set Location");
          break;
        default:
          break;
      }
      break;
  }
};

export const getCpmCategory = (data) => {
  const lowerData = data.toLowerCase();
  if (
    [
      TypeName.suv,
      TypeName.sedan,
      TypeName.HD,
      TypeName.LD,
      TypeName.VAN,
    ].includes(lowerData)
  ) {
    return "Standard";
  } else if (lowerData === TypeName.EV) {
    return "EV";
  } else if (lowerData === TypeName.MD) {
    return "MD";
  } else {
    return "";
  }
};

export const userControl = ({
  handleDeactivate,
  handleReactivate,
  classes,
}) => {
  let userControlColumn = {
    field: "userControl",
    headerName: "User Control",
    sortable: false,
    resizable: true,
    minWidth: 150,
    disableClickEventBubbling: true,
    cellRenderer: (params) => (
      <>
        {params.data.status === USER_CONTROL.ACTIVATE && (
          <Button
            className={classes.addFleetListButton}
            style={{
              color: "black",
            }}
            onClick={() => handleDeactivate(params.data)}
          >
            Deactivate
          </Button>
        )}
        {params.data.status === USER_CONTROL.DEACTIVATE && (
          <Button
            className={classes.buttonReactive}
            style={{
              color: "black",
            }}
            onClick={() => handleReactivate(params?.data)}
          >
            Reactivate
          </Button>
        )}
      </>
    ),
  };

  return userControlColumn;
};

export const onGridReady = (params, setGridApi) => {
  setGridApi(params.api);
};
