import React, { useState, createContext, useCallback } from "react";
import { helpDeskListApi } from "../api";
import { showToast } from "../../components/Toast";
import { useNavigate } from "react-router-dom";
import { typeConstant } from "../constants/commonFunction";

/**
 * Fund list context
 */
export const HelpDeskContext = createContext();

/**
 * Fund list provider
 */
export const HelpDeskProvider = ({ children }) => {
  const [helpdeskList, setHelpdeskList] = useState([]);
  const [loading, setLoading] = useState(false);
  const userType = localStorage.getItem("type");
  const navigate = useNavigate();
  const getHelpdeskListApi = async (values) => {
    setLoading(true);
    const response = await helpDeskListApi.getHelpDeskList({
      limit: values?.limit ? values.limit : 100,
      offset: values?.offset ? values.offset : 0,
      filter: {
        ...values?.filter,
      },
      sorting: {
        fieldName: "",
        direction: "",
      },
    });
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      const userType1 = localStorage.getItem("type");
      setHelpdeskList(response?.data?.data);
      if (userType1 === typeConstant.dealer) {
        navigate("/helpDesk");
      } else {
        navigate("/userQueries");
      }
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };

  const createHelpdeskListApi = async (values, userId) => {
    setLoading(true);
    const response = await helpDeskListApi.createHelpDesk(values);
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      setHelpdeskList(response?.data?.data);
      showToast("Helpdesk created successfully");
      getHelpdeskListApi({
        filter: { userId: userId },
        limit: 10000,
        offset: 0,
      });
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const editHelpdeskListApi = async (values) => {
    setLoading(true);
    const response = await helpDeskListApi.editHelpDesk(values);
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
      showToast("Failed to update Helpdesk");
    }

    if (response?.data?.data) {
      showToast("Helpdesk updated successfully");
      getHelpdeskListApi({
        companyId: values?.companyId,
        limit: 10000,
        offset: 0,
      });
      setLoading(false);
    } else {
      showToast("Failed to update Helpdesk");
      setLoading(false);
    }

    return response.data;
  };

  const deleteHelpdeskApi = async (id) => {
    setLoading(true);
    const response = await helpDeskListApi.deleteHelpDesk(id);

    if (response?.error) {
      setLoading(false);
    }

    if (response?.data) {
      getHelpdeskListApi({ limit: 10000, offset: 0 });
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };

  const contextValue = {
    helpdeskList,
    setHelpdeskList,
    loading,
    setLoading,
    getHelpDeskListApi: useCallback((request) => {
      getHelpdeskListApi(request);
    }, []),
    createHelpDeskListApi: useCallback((request, userId) => {
      createHelpdeskListApi(request, userId);
    }, []),
    editHelpDeskListApi: useCallback((request) => {
      editHelpdeskListApi(request);
    }, []),
    deleteHelpDeskApi: useCallback((request) => {
      deleteHelpdeskApi(request);
    }, []),
  };

  return (
    <HelpDeskContext.Provider value={contextValue}>
      {children}
    </HelpDeskContext.Provider>
  );
};
