import { postData, postFormData } from "./base-api";
import ApiUris from "./apiUris";

/**
 * Help Desk List Api method declarations
 */
export const CommentsApi = {
  getCommentsList(request) {
    return postData(ApiUris.commentsApi.getComments, request);
  },
  createComments(request) {
    return postData(ApiUris.commentsApi.createComments, request);
  },
  editComments(request) {
    return postData(ApiUris.commentsApi.updateComments, request);
  },
};
