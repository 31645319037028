import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { analysisReportColumnData } from "../../common/constants/gridRowColumn";
import { useAnalysis, useCompany, useUser } from "../../common/hooks";
import { AdminPageStyle } from "./style";
import {
  getCompanyId,
  getDefaultColDef,
  sidebarMenu,
} from "../../common/constants/commonFunction";
import { useForm } from "react-hook-form";
import {
  addComma,
  getCurrencyValue,
} from "../../common/services/number-service";
import { TruckLoader } from "../../pages/admin/truckLoader";
import { handleExportButton } from "../../common/services/exportPowerBI";

export const AnalysisResults1 = () => {
  const classes = AdminPageStyle();
  const { state } = useLocation();
  const [reportDataFleet, setReportDataFleet] = useState();
  const [rowData, setRowData] = useState([]);
  const [cyclingComparision, setCyclingComparision] = useState();
  const [proposedCycle, setProposedCycle] = useState();
  const [fuelCost, setFuelCost] = useState();
  const [maintananceCost, setMaintananceCost] = useState();
  const [totalSavings, setTotalSavings] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);
  const navigation = useNavigate();
  const {
    updateAnalysisResult,
    loading,
    setAnalysisResultId,
    analysisResult,
    updateViewAnalysisResult,
    getAnalysisResultByIdApi,
    analysisResultId,
  } = useAnalysis();
  const { companyNameHeader } = useCompany();
  const { setSelectedNav } = useUser();
  const userType = localStorage.getItem("type");
  const companyId = getCompanyId(userType, companyNameHeader);
  const { register, handleSubmit, reset, setValue } = useForm({});
  const defaultColDef = useMemo(() => getDefaultColDef(), []);
  const [reportDataFinal, setReportDataFinal] = useState();
  const [data, newData] = useState([]);
  const [loadingGrid, setLoadingGrid] = useState(false);

  useEffect(() => {
    if (state?.data) {
      if (state.data.reportData) {
        let reportData = JSON.parse(state.data.reportData);
        setReportDataFleet(reportData?.fleet);
        setCyclingComparision(reportData?.cyclingComparision);
        setProposedCycle(reportData?.cyclingComparision?.proposedCycle || 0);
        setRowData(reportData?.fleetMix);
        setFuelCost(reportData?.fuelCost);
        setMaintananceCost(reportData?.maintainanceCost);
        setReportDataFinal(state.data);
      }
      if (state?.data?.id) {
        getAnalysisResultByIdApi(state.data.id);
      }
    }
  }, [state]);

  useEffect(() => {
    if (analysisResultId) {
      let reportData = JSON.parse(analysisResultId.reportData);
      setReportDataFleet(reportData?.fleet);
      setCyclingComparision(reportData?.cyclingComparision);
      setProposedCycle(reportData?.cyclingComparision?.proposedCycle || 0);
      setRowData(reportData?.fleetMix);
      setFuelCost(reportData?.fuelCost);
      setMaintananceCost(reportData?.maintainanceCost);
      setReportDataFinal(analysisResultId);
    }
  }, [analysisResultId]);

  const calculateTotalSavings = (data) => {
    const total = data.reduce((acc, row) => acc + (row.savings || 0), 0);
    setTotalSavings(parseFloat(total.toFixed(2)));
  };

  useEffect(() => {
    if (state?.data) {
      if (data?.length > 0) {
        if (rowData.length > 0) {
          const updatedRowData = [...rowData];
          for (let i = 0; i < rowData.length; i++) {
            updatedRowData[0].cashOutlay =
              reportDataFleet?.avgNewVehiclePrice?.toFixed(2) *
              updatedRowData[0].purchasedFinancial;
            updatedRowData[0].fleetSpeed =
              updatedRowData[0].cashOutlay +
              updatedRowData[0].fuel +
              updatedRowData[0].maintenance +
              updatedRowData[0].cyclingPayment +
              updatedRowData[0].currentPayment;
            updatedRowData[i].savings =
              updatedRowData[0].fleetSpeed -
              updatedRowData[i].fleetSpeed +
              updatedRowData[i].vehicleEquity;
            setRowData(updatedRowData);
            calculateTotalSavings(updatedRowData);
          }
        }
      }
    }
  }, [state?.data, rowData.length, data]);

  useEffect(() => {
    if (state?.data) {
      if (rowData.length > 0) {
        const updatedRowData = [...rowData];
        updatedRowData[0].purchasedFinancial = updatedRowData[0].annualNeeds;

        setRowData(updatedRowData);
        calculateTotalSavings(updatedRowData);
      }
    }
  }, [state?.data, rowData.length]);

  const handleCellValueChange = (event, value) => {
    const updatedRowData = [...rowData];
    const rowIndex = event.rowIndex;
    if (event.colDef.field == "purchasedFinancial") {
      if (rowIndex !== 0) {
        updatedRowData[rowIndex].cyclingPayment = parseFloat(
          (
            cyclingComparision?.monthlyLeaseCost?.toFixed(2) *
            updatedRowData[rowIndex].purchasedFinancial *
            12
          )?.toFixed(2)
        );
      }
      updatedRowData[rowIndex].fleetSpeed =
        updatedRowData[rowIndex].cashOutlay +
        updatedRowData[rowIndex].fuel +
        updatedRowData[rowIndex].maintenance +
        updatedRowData[rowIndex].cyclingPayment +
        updatedRowData[rowIndex].currentPayment;
      updatedRowData[rowIndex].savings =
        updatedRowData[0].fleetSpeed -
        updatedRowData[rowIndex].fleetSpeed +
        updatedRowData[rowIndex].vehicleEquity;
    } else if (event.colDef.field == "cashOutlay") {
      updatedRowData[rowIndex].fleetSpeed =
        updatedRowData[rowIndex].cashOutlay +
        updatedRowData[rowIndex].fuel +
        updatedRowData[rowIndex].maintenance +
        updatedRowData[rowIndex].cyclingPayment +
        updatedRowData[rowIndex].currentPayment;
      updatedRowData[rowIndex].savings =
        updatedRowData[0].fleetSpeed -
        updatedRowData[rowIndex].fleetSpeed +
        updatedRowData[rowIndex].vehicleEquity;
    } else if (event.colDef.field == "currentPayment") {
      updatedRowData[rowIndex].fleetSpeed =
        updatedRowData[rowIndex].cashOutlay +
        updatedRowData[rowIndex].fuel +
        updatedRowData[rowIndex].maintenance +
        updatedRowData[rowIndex].cyclingPayment +
        updatedRowData[rowIndex].currentPayment;
      updatedRowData[rowIndex].savings =
        updatedRowData[0].fleetSpeed -
        updatedRowData[rowIndex].fleetSpeed +
        updatedRowData[rowIndex].vehicleEquity;
    } else if (event.colDef.field == "annualNeeds") {
      updatedRowData[rowIndex].purchasedFinancial =
        updatedRowData[rowIndex].annualNeeds;
      updatedRowData[rowIndex].cashOutlay =
        reportDataFleet?.avgNewVehiclePrice?.toFixed(2) *
        updatedRowData[rowIndex].purchasedFinancial;
      updatedRowData[rowIndex].fleetSpeed =
        updatedRowData[rowIndex].cashOutlay +
        updatedRowData[rowIndex].fuel +
        updatedRowData[rowIndex].maintenance +
        updatedRowData[rowIndex].cyclingPayment +
        updatedRowData[rowIndex].currentPayment;
    } else if (event.colDef.field == "vehicleEquity") {
      updatedRowData[rowIndex].savings =
        updatedRowData[0].fleetSpeed -
        updatedRowData[rowIndex].fleetSpeed +
        updatedRowData[rowIndex].vehicleEquity;
    }
    setRowData(updatedRowData);
    newData(updatedRowData);
    setLoadingGrid(true);
    calculateTotalSavings(updatedRowData);
  };

  const handleSave = async (value) => {
    let reportData = JSON.parse(state?.data?.reportData);
    let cyclingComparisionValue = {
      ...cyclingComparision,
      proposedCycle: proposedCycle,
    };
    let resultData = JSON.stringify({
      fleet: reportData?.fleet,
      maintainanceCost: reportData?.maintainanceCost,
      fuelCost: reportData?.fuelCost,
      cyclingComparision: cyclingComparisionValue,
      fleetMix: rowData,
    });
    let payload = {
      reportData: resultData,
      companyId: companyId,
      createdBy: Number(localStorage.getItem("cashflow_admin_user_id")),
      id: state?.data?.id,
      reportName: state?.data?.reportName,
      createdAt: moment().format(),
    };
    if (value) {
      await updateViewAnalysisResult(payload);
    } else {
      await updateAnalysisResult(payload);
    }
  };

  const handleCreateFleet = () => {
    navigation("/fleet-list");
  };

  const handlePowerBiReport = async () => {
    // navigation("/reports?id=" + state.data.id);
    await handleSave(true);
    navigation("/reports", { state: { id: state?.data?.id } });
    setSelectedNav(sidebarMenu.reports);
  };

  const handleProposedCycleChange = (e) => {
    let data = Number(e.target.value);
    if (!e.target.value) {
      setProposedCycle();
      if (timeoutId) {
        clearTimeout(timeoutId);
        setTimeoutId(null);
      }
      return;
    }
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setProposedCycle(data);
    const newTimeoutId = setTimeout(() => {
      if (data > 5) {
        setProposedCycle(5);
      } else if (data < 0 || data === 0) {
        setProposedCycle(1);
      } else {
        setProposedCycle(data);
      }
      setTimeoutId(null);
    }, 500);
    setTimeoutId(newTimeoutId);
  };
  const gridRef = useRef(null);

  useEffect(() => {
    const appendTotalSavings = () => {
      const paginationPanel = document.querySelector(".ag-paging-panel");
      if (paginationPanel) {
        let totalSavingsDiv = document.querySelector(".custom-total-savings");
        if (!totalSavingsDiv) {
          totalSavingsDiv = document.createElement("div");
          totalSavingsDiv.className = `${classes.analysisTotalSavingButton} custom-total-savings`;
          paginationPanel.appendChild(totalSavingsDiv);
        }
        totalSavingsDiv.innerHTML = `<div class="${
          classes.totalSavingText
        }">Total Savings: ${getCurrencyValue(totalSavings)}</div>`;
      }
    };

    if (!loading && gridRef?.current) {
      setTimeout(() => {
        appendTotalSavings();
      }, 1000);
    }
  }, [totalSavings, classes, gridRef, loading]);

  useEffect(() => {
    setAnalysisResultId(null);
  }, []);

  return (
    <Box className={classes.mainAnalysisContent}>
      <Box style={{ marginBottom: "40px" }}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.headerAnalysisReport}
          >
            <span className={classes.headerAnalysisName}>
              CURRENT SITUATION
            </span>
            <Box>
              <Button
                variant="outlined"
                type="submit"
                className={classes.reportAnalysisButton}
                autoFocus
                onClick={handleCreateFleet}
              >
                Create
              </Button>
              <Button
                variant="outlined"
                type="submit"
                className={classes.reportAnalysisButton}
                autoFocus
                onClick={handlePowerBiReport}
              >
                View Report
              </Button>
              <Button
                className={classes.exportPowerBIButton}
                onClick={() => handleExportButton(reportDataFinal)}
              >
                Export
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            className={"gridAnalysisItem"}
            padding={0}
          >
            <Accordion defaultExpanded className="AccordingAnalysis">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className={classes.textGeneralInfoResult}
              >
                Fleet
              </AccordionSummary>
              <AccordionDetails className={classes.accordinAnalysisResult}>
                <Box>
                  <Box className={classes.divMt10}>
                    <Box className={classes.analysisResultTextDiv}>
                      <span className={classes.analysisListGenInfoTtext}>
                        Current Fleet Size
                      </span>
                      <span className={classes.fleetListGenInfoInputText}>
                        {reportDataFleet?.currentFleetSize}
                      </span>
                    </Box>
                  </Box>
                  <Box className={classes.divMt10}>
                    <Box className={classes.analysisResultTextDiv}>
                      <span className={classes.analysisListGenInfoTtext}>
                        Avg. Vehicle Model Year
                      </span>
                      <span className={classes.fleetListGenInfoInputText}>
                        {reportDataFleet?.avgVehicleYear}
                      </span>
                    </Box>
                  </Box>
                  <Box className={classes.divMt10}>
                    <Box className={classes.analysisResultTextDiv}>
                      <span className={classes.analysisListGenInfoTtext}>
                        Avg New Vehicle Price
                      </span>
                      <span className={classes.fleetListGenInfoInputText}>
                        {getCurrencyValue(reportDataFleet?.avgNewVehiclePrice)}
                      </span>
                    </Box>
                  </Box>
                  <Box className={classes.divMt10}>
                    <Box className={classes.analysisResultTextDiv}>
                      <span className={classes.analysisListGenInfoTtext}>
                        Avg. Current Mileage
                      </span>
                      <span className={classes.fleetListGenInfoInputText}>
                        {addComma(Math.round(reportDataFleet?.currentMileage))}
                      </span>
                    </Box>
                  </Box>
                  <Box className={classes.divMt10}>
                    <Box className={classes.analysisResultTextDiv}>
                      <span className={classes.analysisListGenInfoTtext}>
                        Current Cycle (Years)
                      </span>
                      <span className={classes.fleetListGenInfoInputText}>
                        {reportDataFleet?.currentCycle}
                      </span>
                    </Box>
                  </Box>
                  <Box className={classes.divMt10}>
                    <Box className={classes.analysisResultTextDiv}>
                      <span className={classes.analysisListGenInfoTtext}>
                        Avg. Annual Miles
                      </span>
                      <span className={classes.fleetListGenInfoInputText}>
                        {addComma(Math.round(reportDataFleet?.annualMiles))}
                      </span>
                    </Box>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            padding={0}
            className={"gridAnalysisItem"}
          >
            <Accordion defaultExpanded className="AccordingAnalysis1">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className={classes.textGeneralInfoResult}
              >
                MAINTENANCE COST
              </AccordionSummary>
              <AccordionDetails className={classes.accordinAnalysisResult}>
                <Box>
                  <Box className={classes.divMt10}>
                    <Box className={classes.analysisResultTextDiv}>
                      <span className={classes.analysisListGenInfoTtext}>
                        Total Annualized Spend
                      </span>
                      <span className={classes.fleetListGenInfoInputText}>
                        {getCurrencyValue(maintananceCost?.totalAnnualized)}
                      </span>
                    </Box>
                  </Box>
                  <Box className={classes.divMt10}>
                    <Box className={classes.analysisResultTextDiv}>
                      <span className={classes.analysisListGenInfoTtext}>
                        Per Vehicle Spend
                      </span>

                      <span className={classes.fleetListGenInfoInputText}>
                        {getCurrencyValue(maintananceCost?.vehicleSpend)}
                      </span>
                    </Box>
                  </Box>
                  <Box className={classes.divMt10}>
                    <Box className={classes.analysisResultTextDiv}>
                      <span className={classes.analysisListGenInfoTtext}>
                        Vehicle Spend/Month
                      </span>
                      <span className={classes.fleetListGenInfoInputText}>
                        {getCurrencyValue(maintananceCost?.vehicleSpendMonth)}
                      </span>
                    </Box>
                  </Box>
                  <Box className={classes.divMt10}>
                    <Box className={classes.analysisResultTextDiv}>
                      <span className={classes.analysisListGenInfoTtext}>
                        Avg. Cost per Mile Per Vehicle Spend
                      </span>
                      <span className={classes.fleetListGenInfoInputText}>
                        {maintananceCost?.costPerMile
                          ? getCurrencyValue(
                              Number(maintananceCost?.costPerMile)
                            )
                          : 0}
                      </span>
                    </Box>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            padding={0}
            className={"gridAnalysisItem"}
          >
            <Accordion defaultExpanded className="AccordingAnalysis">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className={classes.textGeneralInfoResult}
              >
                FUEL COST
              </AccordionSummary>
              <AccordionDetails className={classes.accordinAnalysisResult}>
                <Box>
                  <Box className={classes.divMt10}>
                    <Box className={classes.analysisResultTextDiv}>
                      <span className={classes.analysisListGenInfoTtext}>
                        Avg. MPG
                      </span>
                      <span className={classes.fleetListGenInfoInputText}>
                        {fuelCost?.avgMpg?.toFixed(2)}
                      </span>
                    </Box>
                  </Box>
                  <Box className={classes.divMt10}>
                    <Box className={classes.analysisResultTextDiv}>
                      <span className={classes.analysisListGenInfoTtext}>
                        Avg. Annual Miles
                      </span>
                      <span className={classes.fleetListGenInfoInputText}>
                        {addComma(Math.round(fuelCost?.avgAnnualMiles))}
                      </span>
                    </Box>
                  </Box>
                  <Box className={classes.divMt10}>
                    <Box className={classes.analysisResultTextDiv}>
                      <span className={classes.analysisListGenInfoTtext}>
                        Number of Vehicles
                      </span>
                      <span className={classes.fleetListGenInfoInputText}>
                        {reportDataFleet?.currentFleetSize}
                        {/* {fuelCost?.numberOfVehicle?.toFixed(2)} */}
                      </span>
                    </Box>
                  </Box>
                  <Box className={classes.divMt10}>
                    <Box className={classes.analysisResultTextDiv}>
                      <span className={classes.analysisListGenInfoTtext}>
                        Avg. Cost per Gallon
                      </span>
                      <span className={classes.fleetListGenInfoInputText}>
                        {getCurrencyValue(fuelCost?.costPerGallon)}
                      </span>
                    </Box>
                  </Box>
                  <Box className={classes.divMt10}>
                    <Box className={classes.analysisResultTextDiv}>
                      <span className={classes.analysisListGenInfoTtext}>
                        Total Fuel Spend
                      </span>
                      <span className={classes.fleetListGenInfoInputText}>
                        {getCurrencyValue(fuelCost?.totalFuelSpend)}
                      </span>
                    </Box>
                  </Box>
                  <Box className={classes.divMt10}>
                    <Box className={classes.analysisResultTextDiv}>
                      <span className={classes.analysisListGenInfoTtext}>
                        Annual Spend/Vehicle
                      </span>
                      <span className={classes.fleetListGenInfoInputText}>
                        {getCurrencyValue(fuelCost?.annualSpendVehicle)}
                      </span>
                    </Box>
                  </Box>
                  <Box className={classes.divMt10}>
                    <Box className={classes.analysisResultTextDiv}>
                      <span className={classes.analysisListGenInfoTtext}>
                        Monthly Spend/Vehicle
                      </span>
                      <span className={classes.fleetListGenInfoInputText}>
                        {getCurrencyValue(fuelCost?.monthlySpendVehicle)}
                      </span>
                    </Box>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            padding={0}
            className={"gridAnalysisItem"}
          >
            <Accordion defaultExpanded className="AccordingAnalysis1">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className={classes.textGeneralInfoResult}
              >
                CYCLING COMPARISONS
              </AccordionSummary>
              <AccordionDetails className={classes.accordinAnalysisResult}>
                <Box>
                  <Box className={classes.divMt10}>
                    <Box className={classes.analysisResultTextDiv}>
                      <span className={classes.analysisListGenInfoTtext}>
                        Proposed Cycle (Years)
                      </span>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        size="small"
                        type="number"
                        value={proposedCycle}
                        className={classes.textinput}
                        {...register("proposedCycle")}
                        onChange={handleProposedCycleChange}
                      />
                    </Box>
                  </Box>
                  <Box className={classes.divMt10}>
                    <Box className={classes.analysisResultTextDiv}>
                      <span className={classes.analysisListGenInfoTtext}>
                        Monthly Lease Cost Average
                      </span>
                      <span className={classes.fleetListGenInfoInputText}>
                        {getCurrencyValue(cyclingComparision?.monthlyLeaseCost)}
                      </span>
                    </Box>
                  </Box>
                  <Box className={classes.divMt10}>
                    <Box className={classes.analysisResultTextDiv}>
                      <span className={classes.analysisListGenInfoTtext}>
                        Average Year 1 MPG
                      </span>
                      <span className={classes.fleetListGenInfoInputText}>
                        {cyclingComparision?.avgYear1Mpg?.toFixed(2)}
                      </span>
                    </Box>
                  </Box>
                  <Box className={classes.divMt10}>
                    <Box className={classes.analysisResultTextDiv}>
                      <span className={classes.analysisListGenInfoTtext}>
                        Y1 Fuel Spend/Vehicle
                      </span>
                      <span className={classes.fleetListGenInfoInputText}>
                        {getCurrencyValue(cyclingComparision?.fuelSpendY1)}
                      </span>
                    </Box>
                  </Box>
                  <Box className={classes.divMt10}>
                    <Box className={classes.analysisResultTextDiv}>
                      <span className={classes.analysisListGenInfoTtext}>
                        Year 1 Monthly Maintenance
                      </span>
                      <span className={classes.fleetListGenInfoInputText}>
                        {getCurrencyValue(
                          cyclingComparision?.monthlyMaintananceYear1
                        )}
                      </span>
                    </Box>
                  </Box>
                  <Box className={classes.divMt10}>
                    <Box className={classes.analysisResultTextDiv}>
                      <span className={classes.analysisListGenInfoTtext}>
                        Year 1 Average Cost Per Mile
                      </span>
                      <span className={classes.fleetListGenInfoInputText}>
                        {getCurrencyValue(
                          cyclingComparision?.avgYear1CostPerMiles
                        )}
                      </span>
                    </Box>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <div className={classes.aggridAnalysisReport}>
            <Box className={classes.gridContantReport}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "40px",
                }}
              >
                <Box className={classes.analysisDivMix}>Fleet Mix</Box>
                <Box className={classes.analysisSaveBox}>
                  <Box className={classes.analysisDivMix}>
                    <Button
                      className={classes.reportAnalysisSaveButton}
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Box>
              {loading ? (
                <TruckLoader />
              ) : (
                <AgGridReact
                  key={"ag-grid-reports"}
                  className="ag-theme-quartz ag-analysis-grid"
                  rowSelection="multiple"
                  suppressRowClickSelection
                  ref={gridRef}
                  pinnedColumns={{ right: ["action"] }}
                  pagination={true}
                  rowData={rowData}
                  onCellValueChanged={handleCellValueChange}
                  defaultColDef={defaultColDef}
                  columnDefs={analysisReportColumnData}
                />
              )}
            </Box>
          </div>
        </Grid>
      </Box>
    </Box>
  );
};
