import React, { useEffect, useMemo } from "react";
import { useUserLogs } from "../../common/hooks";
import { AgGridReact } from "ag-grid-react";
import { Box } from "@mui/material";
import { getDefaultColDef } from "../../common/constants/commonFunction";
import { UserLogsColumn } from "../../common/constants/gridRowColumn";
import { layoutStyle } from "../layout-ui/style";

export const Userlogs = () => {
  const { getUserLogsList, userLogs } = useUserLogs();
  const classes = layoutStyle();
  const defaultColDef = useMemo(() => getDefaultColDef(), []);
  useEffect(() => {
    getUserLogsList();
  }, []);

  useEffect(() => {
    if (userLogs?.length > 0) {
      userLogs?.forEach((item) => {
        const timestamps = item.configDetails?.idealTime?.map((date) =>
          new Date(date).getTime()
        );

        if (timestamps && timestamps.length > 1) {
          const totalTimeMs = timestamps[timestamps.length - 1] - timestamps[0]; // Difference in milliseconds
          const totalHours = totalTimeMs / (1000 * 60); // Convert milliseconds to hours

          item["totalHours"] = totalHours;
        } else {
          item["totalHours"] = 0; // If there's only one timestamp or none, set totalHours to 0
        }
      });
    }
  }, [userLogs]);

  return (
    <Box className={classes.mainContent}>
      <Box sx={{ width: "100%" }}>
        <Box className={classes.headerUserLogs}>User Activities</Box>
        <Box
          sx={{
            height: "calc(100vh - 180px)",
            width: "100%",
          }}
        >
          <AgGridReact
            className="ag-theme-quartz"
            rowSelection="multiple"
            pagination={true}
            rowData={userLogs}
            defaultColDef={defaultColDef}
            columnDefs={UserLogsColumn}
            suppressRowClickSelection={true}
          />
        </Box>
      </Box>
    </Box>
  );
};
