import React from "react";
import UserGuidePdf from "../../assets/pdf/User-Guide.pdf";
import { layoutStyle } from "../layout-ui/style";
import { Box } from "@mui/material";
export const UserGuide = () => {
  const classes = layoutStyle();
  return (
    <Box className={classes.mainContentUserGuide}>
      <Box className={classes.gridContantFleetList}>
        <Box className={classes.headerNameText}>User Guide</Box>
        <Box className={classes.addEditGrid}></Box>
        <div>
          <iframe
            src={`${UserGuidePdf}#zoom=50`}
            width="100%"
            height="600px"
            style={{ border: "none" }}
            title="User Guide"
          />
        </div>
      </Box>
    </Box>
  );
};
