import React, { useEffect, useMemo, useState } from "react";
import { layoutStyle } from "../layout-ui/style";
import { Box, Stack } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { HelpDeskDataColumn } from "../../common/constants/gridRowColumn";
import { useHelpDesk } from "../../common/hooks";
import {
  actionColumnFunction,
  getDefaultColDef,
} from "../../common/constants/commonFunction";
import { useNavigate } from "react-router-dom";

const HelpDesk = () => {
  const classes = layoutStyle();
  const [selectedRows, setSelectedRows] = useState([]);
  const [columnFinal, setColumnFinal] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [open, setOpen] = useState(false);
  const [paramsValue, setParamsValue] = useState();
  const { getHelpDeskListApi, helpdeskList, deleteHelpDeskApi } = useHelpDesk();
  const navigation = useNavigate();
  const defaultColDef = useMemo(() => getDefaultColDef(), []);

  useEffect(() => {
    getHelpDeskListApi({ limit: 10000, offset: 0 });
  }, []);

  useEffect(() => {
    if (helpdeskList?.length > 0) {
      setRowData(helpdeskList);
    } else {
      setRowData([]);
    }
  }, [helpdeskList]);
  const handleEditButton = (params) => {
    setOpen(true);
    navigation("/user-queries", { state: params });
    setParamsValue(params);
  };
  const handleDeleteButton = async (params) => {
    await deleteHelpDeskApi(params?.id);
  };

  useEffect(() => {
    if (HelpDeskDataColumn?.length > 0) {
      const actionColumn = actionColumnFunction({
        selectedRows: selectedRows,
        handleEditButton: handleEditButton,
        handleDeleteButton: handleDeleteButton,
        deleteButton: true,
        viewButton: true,
      });
      setColumnFinal([...HelpDeskDataColumn, actionColumn]);
    }
  }, [HelpDeskDataColumn]);
  return (
    <Box className={classes.mainContent}>
      <Box className={classes.helpDeskGridContant}>
        <Stack
          direction="row"
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Box className={classes.headerUserQuery}>User Queries</Box>
        </Stack>

        <AgGridReact
          className="ag-theme-quartz ag-grid-helpdesk"
          suppressRowClickSelection
          rowHeight={35}
          onSelectionChanged={(event) => {
            const selectedNodes = event?.api?.getSelectedNodes();
            const selectedRows = selectedNodes?.map((node) => node?.data);
            setSelectedRows(selectedRows);
          }}
          pagination={true}
          rowData={rowData}
          defaultColDef={defaultColDef}
          columnDefs={columnFinal}
        />
      </Box>
    </Box>
  );
};

export default HelpDesk;
