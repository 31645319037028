import CloseIcon from "@mui/icons-material/Close";
import { Drawer, Grid, InputLabel, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useComments, useHelpDesk } from "../../common/hooks";
import { layoutStyle } from "../../pages/layout-ui/style";

export default function AddHelpDesk({
  open,
  setOpen,
  title,
  setRefreshData,
  editValue,
}) {
  const classes = layoutStyle();
  const [binaryImage, setBinaryImage] = useState(null);
  const [description, setDescription] = useState(null);
  const [commnets, setComments] = useState(null);
  const userName = localStorage.getItem("name");
  const userId = localStorage.getItem("cashflow_admin_user_id");

  let data = new FormData();
  const { createHelpDeskListApi } = useHelpDesk();
  const { createCommentsApi } = useComments();

  const handleClose = () => {
    setBinaryImage(null);
    setDescription(null);
    setOpen(false);
  };

  const defaultValue = {
    description: "",
  };

  const {
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValue,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });
  const onSubmit = async () => {
    data.append("Name", userName);
    data.append("ImageFile", binaryImage);
    data.append("UserId", userId);
    data.append("Description", description);
    data.append("Deleted", false);
    data.append("Status", "Pending");

    const payload = {
      // dealerId: dealerId,
      userName: userName,
      comments: commnets,
      userId: userId,
    };

    await createHelpDeskListApi(data, userId);
    await createCommentsApi(payload);
    setRefreshData(true);
    handleClose();
  };

  useEffect(() => {
    data.delete("ImageFile");
    data.delete("Name");
    data.delete("Deleted");
  }, [open]);

  const imageToBinary = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const binaryString = reader.result;
        resolve(binaryString);
      };

      reader.onerror = () => {
        reject(reader.error);
      };

      reader.readAsBinaryString(file);
    });
  };

  const handleCompanyLogo = async (event) => {
    let file = event.target.files[0];

    try {
      const binaryData = await imageToBinary(file);
      setBinaryImage(file); // Output the binary data to console
    } catch (error) {
      console.error("Error converting image to binary:", error);
    }
  };

  const handleDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleComments = (event) => {
    setComments(event.target.value);
  };

  return (
    <React.Fragment>
      <Drawer
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        anchor="bottom"
        fullHeight
        style={{ top: "100px ", bottom: "56px !important" }}
        className="DrawerName"
      >
        <DialogTitle
          className={classes.dailogTitle}
          id="customized-dialog-title"
        >
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container className={classes.disFlexCenter}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid className={classes.companyForm}>
                <Grid className={classes.compnayFormField}>
                  <InputLabel className={classes.fontSize12}>Name</InputLabel>
                  <TextField
                    disabled
                    style={{ background: "lightgray", color: "black" }}
                    // error={errors?.companyName}
                    type="text"
                    fullWidth
                    value={userName}
                  />
                </Grid>
                <Grid className={classes.compnayFormField}>
                  <InputLabel className={classes.fontSize12}>
                    Description
                  </InputLabel>
                  <TextField
                    // error={errors?.companyName}
                    multiline
                    type="text"
                    fullWidth
                    value={description}
                    onChange={handleDescription}
                  />
                </Grid>
                <Grid className={classes.compnayFormField}>
                  <InputLabel className={classes.fontSize12}>
                    Comments
                  </InputLabel>
                  <TextField
                    // error={errors?.companyName}
                    multiline
                    type="text"
                    fullWidth
                    value={commnets}
                    onChange={handleComments}
                  />
                </Grid>
                <Grid className={classes.compnayFormField}>
                  <InputLabel className={classes.fontSize12}>Image</InputLabel>
                  <TextField
                    error={errors?.companyLogo}
                    type="file"
                    onChange={handleCompanyLogo}
                    fullWidth
                    inputProps={{
                      accept: "image/jpeg, image/png, image/gif, image/bmp",
                    }}
                    // {...register("companyLogo")}
                  />
                </Grid>

                <Button
                  variant="outlined"
                  type="submit"
                  className={classes.fleetListNewButton}
                  autoFocus
                  sx={{ mt: 3, height: "50px" }}
                >
                  {title}
                </Button>
              </Grid>
            </form>
          </Grid>
        </DialogContent>
      </Drawer>
    </React.Fragment>
  );
}
