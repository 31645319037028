import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { YearSelect } from "../YearSelect";
import { MakeSelect } from "../MakeSelect";
import { ModelSelect } from "../ModelSelect";
import { CommonSelect } from "../CommonSelect";
import { TypeName } from "../../common/constants";
import { useForm } from "react-hook-form";
import { AdminPageStyle } from "../../pages/admin/style";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
export const GeneralInfoFleet = ({
  setMakeName,
  setMakeValue,
  setAgeCount,
  setVinValue,
  setDealerId,
  setFuelId,
  setDriveTrainId,
  setStateId,
  setMpgData,
  setTypeData,
  setTypeId,
  yearValue,
  setYearValue,
  typeList,
  ageCount,
  year,
  vinValue,
  dealerId,
  fuelId,
  driveTrainId,
  stateId,
  mpgData,
  typeId,
  editData,
  fuelList,
  driveTrainList,
  typeName,
  dealer,
  stateList,
  dealerName,
  makeValue,
  modelValueData,
  setModelValueData,
  companyNameHeader,
  // setYear,
  setYearCsv,
  setModelCsv,
  setCsvState,
  driverName,
  setDriverName,
  divisionOfCompany,
  setDivisionCompany,
  operationalLocations,
  setOperationLocation,
  newFleetNo,
  setNewAdd,
}) => {
  const classes = AdminPageStyle();

  const { register, handleSubmit, setValue, reset, getValues } = useForm();
  const StateMenuProps = {
    PaperProps: {
      style: {
        maxHeight: 30 * 3,
      },
    },
    MenuListProps: {
      style: {
        padding: 0,
      },
    },
  };
  const handleYearChange = (event, value) => {
    let data = event.target.value;
    let date = new Date().getFullYear();
    const filterdYear = year?.filter((yr) => yr?.id === data)?.[0];
    if (data) {
      setYearCsv(value.props.children);
      setYearValue(data);
      if (filterdYear?.year) {
        let age = date - filterdYear.year;
        setAgeCount(age);
      }
    }
  };
  const handleVinChange = (e) => {
    setVinValue(e.target.value);
  };
  const handleDealerChange = (event, value) => {
    let data = event.target.value;
    setDealerId(data);
  };
  const handleFuelChange = (event) => {
    let data = event.target.value;
    setFuelId(data);
  };
  const handleDriveTrainChange = (event) => {
    let data = event.target.value;
    setDriveTrainId(data);
  };
  const handleStateChange = (event) => {
    let data = event.target.value;
    let filterdData = stateList?.filter((state) => state?.id === data)?.[0];
    setCsvState(filterdData);
    setStateId(data);
  };

  const handleMpgChange = (e) => {
    if (e.target.value) {
      setMpgData(Number(e.target.value));
    } else {
      setMpgData(null);
    }
  };

  const handleTypeChange = (event) => {
    let data = event.target.value;
    const filterdData = typeList?.filter((type) => type?.id === data)?.[0];
    setTypeData(filterdData);
    setTypeId(data);
  };
  const handleDriverNameChange = (e) => {
    let name = e.target.value;
    setDriverName(name);
  };

  useEffect(() => {
    if (modelValueData === null) {
      setMpgData(0);
    }
  }, [modelValueData]);

  return (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={classes.textGeneralInfo}
        >
          General information
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Company</span>
                <span className={classes.fleetListGenInfoInputText}>
                  {companyNameHeader?.companyName}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Year</span>
                <YearSelect
                  yearValue={yearValue}
                  handleYearChange={handleYearChange}
                  // setYear={setYear}
                />
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Age</span>
                <span className={classes.textinput} {...register("age")}>
                  {ageCount > 0 ? ageCount : 0}
                  {/* {ageCount == 0 ? 1 : ageCount} */}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Make</span>
                <MakeSelect
                  setMakeValue={setMakeValue}
                  editData={editData}
                  setMakeName={setMakeName}
                  setModelValueData={setModelValueData}
                  setMpgData={setMpgData}
                />
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Model</span>
                <ModelSelect
                  editData={editData}
                  makeValue={makeValue}
                  modelValueData={modelValueData}
                  setModelValueData={setModelValueData}
                  setMpgData={setMpgData}
                  yearValue={yearValue}
                  setModelCsv={setModelCsv}
                  newFleetNo={newFleetNo}
                  setNewAdd={setNewAdd}
                />
              </Box>
              <Box className={classes.divMt10}>
                <Box className={classes.fleetListGenInfoDiv}>
                  <span className={classes.fleetListGenInfoTtext}>Fuel</span>
                  <CommonSelect
                    className={classes.textinput}
                    handleChange={handleFuelChange}
                    menuProp={""}
                    option={fuelList}
                    value={fuelId}
                  />
                </Box>
              </Box>
              <Box className={classes.divMt10}>
                <Box className={classes.fleetListGenInfoDiv}>
                  <span className={classes.fleetListGenInfoTtext}>Type</span>
                  <CommonSelect
                    className={classes.textinput}
                    handleChange={handleTypeChange}
                    menuProp={""}
                    option={typeList}
                    value={typeId}
                  />
                </Box>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              {/* <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>
                  Drive Train
                </span>
                <CommonSelect
                  className={classes.textinput}
                  handleChange={handleDriveTrainChange}
                  menuProp={""}
                  option={driveTrainList}
                  value={driveTrainId}
                />
              </Box> */}
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>
                  CPM Category
                </span>
                <span {...register("cpmCategory")}>
                  {typeName.toLowerCase() == TypeName.suv ||
                  typeName.toLowerCase() == TypeName.sedan ||
                  typeName.toLowerCase() == TypeName.HD ||
                  typeName.toLowerCase() == TypeName.LD ||
                  typeName.toLowerCase() == TypeName.VAN
                    ? "Standard"
                    : typeName.toLowerCase() == TypeName.EV
                    ? "EV"
                    : typeName.toLowerCase() == TypeName.MD
                    ? "MD"
                    : ""}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>MPG</span>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  type="number"
                  className={classes.textinput}
                  value={mpgData}
                  {...register("mpg", { valueAsNumber: true })}
                  onChange={handleMpgChange}
                />
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>VIN</span>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  type="text"
                  value={vinValue}
                  className={classes.textinput}
                  onChange={handleVinChange}
                />
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>
                  Driver Name
                </span>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  name="driverName"
                  className={classes.textinput}
                  value={driverName}
                  onChange={handleDriverNameChange}
                />
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>
                  Division Of Company
                </span>
                <span className={classes.fleetListGenInfoInputText}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    className={classes.textinput}
                    value={divisionOfCompany}
                    onChange={(e) => setDivisionCompany(e.target.value)}
                  />
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Dealer</span>
                {dealerName ? (
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    className={classes.textinput}
                    value={dealerName}
                  />
                ) : (
                  <CommonSelect
                    className={classes.textinput}
                    handleChange={handleDealerChange}
                    menuProp={""}
                    option={dealer}
                    value={dealerId}
                  />
                )}
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>State</span>
                <CommonSelect
                  className={classes.textinput}
                  value={stateId}
                  menuProp={StateMenuProps}
                  option={stateList}
                  handleChange={handleStateChange}
                />
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>
                  Operational Locations
                </span>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  className={classes.textinput}
                  value={operationalLocations}
                  onChange={(e) => setOperationLocation(e.target.value)}
                />
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
