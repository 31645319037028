import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { layoutStyle } from "../layout-ui/style";
import { useForm } from "react-hook-form";
import { GoogleGenerativeAI } from "@google/generative-ai";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";

export const ComparisionCTO = () => {
  const classes = layoutStyle();
  const { handleSubmit, register, reset } = useForm();
  const [question, setQuestionAnswer] = useState([]);
  const [loading, setLoading] = useState(false);
  const onSend = async (values) => {
    try {
      setLoading(true);
      const genAI = new GoogleGenerativeAI(
        "AIzaSyCE1-Jc-0is2FPGRdvcBUy5P0ezTRhj2ig"
      );
      const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });
      const prompt = values?.prompt;

      const result = await model.generateContent(prompt);
      const resultValue = result?.response?.text();
      setQuestionAnswer([
        ...question,
        { question: prompt, answer: resultValue },
      ]);
      reset();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  return (
    <Box className={classes.mainContent}>
      <Box className={classes.chatHeader}>Ask Questions</Box>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ margin: 2 }}>
          <Box
            style={{
              width: "100%",
              height: "calc(100vh - 280px)",
              overflow: "scroll",
              background: "white",
              border: "1px solid lightgray",
              borderRadius: "10px",
              paddingTop: 20,
            }}
          >
            {question &&
              question?.length > 0 &&
              question?.map((items) => {
                return (
                  <div style={{ width: "100% !important" }}>
                    <div className={classes.chatQuestion}>
                      <span className={classes.spanChatQue}>
                        {items?.question}
                      </span>
                    </div>
                    <div className={classes.chatAnswer}>
                      <span className={classes.spanChatAns}>
                        {items?.answer && (
                          <ReactMarkdown remarkPlugins={[remarkGfm]}>
                            {items.answer}
                          </ReactMarkdown>
                        )}
                      </span>
                    </div>
                  </div>
                );
              })}
          </Box>
        </Box>
        <form onSubmit={handleSubmit(onSend)} className={classes.chatForm}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            {...register("prompt")}
            style={{ width: "90%" }}
          />
          {loading ? (
            <span type="submit" className={classes.loaderChat}>
              Sending...
            </span>
          ) : (
            <Button type="submit" className={classes.chatButton}>
              Send
            </Button>
          )}
        </form>
      </Box>
    </Box>
  );
};
