import React, { useEffect, useState } from "react";
import { layoutStyle } from "../pages/layout-ui/style";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import { Settings } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { SearchBar } from "./SearchBar";
import * as XLSX from "xlsx";
import {
  useDriveTrain,
  useFleetList,
  useFuelList,
  useMake,
  useModel,
  useStates,
  useTypeList,
  useYear,
} from "../common/hooks";
import AddActionDialog from "./AddActionDialog";
import { useDebounce } from "./useDebounce";
import { setModelContentByActionFleetList } from "../common/constants/commonFunction";
import { uniqueNullValues } from "../common/constants/findNullValueGrid";
import { RequireFieldComponent } from "./RequireFieldComponent";

export const FleetHeaderButton = ({
  handleNext,
  handleClick,
  selectedRows,
  setSearchValue,
  anchorEl,
  handleOnClose,
  handleDeleteButton,
  companyId,
  setSelectedRows,
  rowDataFinal,
  clearAllFilters,
}) => {
  const classes = layoutStyle();
  const [anchorElSearch, setAnchorElSearch] = useState(null);
  const navigation = useNavigate();
  const [actionButton, setActionButton] = useState("");
  const { fuelList, getFuelListApi } = useFuelList();
  const [modalContent, setModalContent] = useState("");
  const [dropdownValue, setDropDownValue] = useState([]);
  const [keyValue, setKeyValue] = useState("");
  const { year, getYearApi } = useYear();
  const { make, getMakeApi } = useMake();
  const { typeList, getTypeListApi } = useTypeList();
  const { driveTrainList, getDriveTrainListApi } = useDriveTrain();
  const { modelData, getModelList } = useModel();
  const { stateList, getStateListApi } = useStates();
  const { requireFields, setRequireFields } = useFleetList();

  const onSearchFunc = (e) => {
    setSearchValue(e[0]);
  };
  const debouncedSearch = useDebounce(onSearchFunc, 500);

  const handleExport = () => {
    const worksheet = XLSX.utils.json_to_sheet(selectedRows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Fleet List");
    XLSX.writeFile(workbook, "FleetList.xlsx");
  };
  const handleActionSelect = (action, value, key) => {
    setActionButton(action);
    handleOnClose();
    setModelContentByActionFleetList({
      action: action,
      key: key,
      selectedRows: selectedRows,
      handleExport: handleExport,
      handleDeleteButton: handleDeleteButton,
      setKeyValue: setKeyValue,
      setModalContent: setModalContent,
    });
  };

  useEffect(() => {
    if (actionButton == "setFuel") {
      getFuelListApi();
    } else if (actionButton == "setYear") {
      getYearApi();
    } else if (actionButton == "setMake") {
      getMakeApi();
    } else if (actionButton == "setModel") {
      if (selectedRows?.length > 0) {
        let array = [...new Set(selectedRows.map((item) => item.makeId))];
        getModelList({
          makeId: selectedRows[0]?.makeId,
          yearId: selectedRows[0]?.yearId,
        });
      }
    } else if (actionButton == "setDriveTrain") {
      getDriveTrainListApi();
    } else if (actionButton == "setType") {
      getTypeListApi();
    } else if (actionButton == "setAll") {
      getFuelListApi();
      getTypeListApi();
      getDriveTrainListApi();
      getStateListApi();
    } else if (actionButton === "State") {
      getStateListApi();
    }
  }, [actionButton]);

  const closeModal = (e) => {
    if (e === "save") {
      setSelectedRows([]);
    }
    setModalContent("");
    setDropDownValue([]);
    setActionButton("");
  };
  const onSearch = (e) => {
    debouncedSearch(e?.target?.value);
    handleClickSearch(e);
  };
  const handleClickSearch = (event) => {
    setAnchorElSearch(event.currentTarget);
  };
  useEffect(() => {
    if (actionButton == "setFuel") {
      setDropDownValue(fuelList);
    } else if (actionButton == "setYear") {
      setDropDownValue(year);
    } else if (actionButton == "setMake") {
      setDropDownValue(make);
    } else if (actionButton == "setModel") {
      setDropDownValue(modelData);
    } else if (actionButton == "setDriveTrain") {
      setDropDownValue(driveTrainList);
    } else if (actionButton == "setType") {
      setDropDownValue(typeList);
    } else if (actionButton == "setAll") {
      setDropDownValue({
        typeListArray: typeList,
        fuelListArray: fuelList,
        driveTrainListArray: driveTrainList,
        yearArray: year,
        state: stateList,
      });
    } else if (actionButton === "State") {
      setDropDownValue(stateList);
    }
  }, [modelData, make, year, driveTrainList, typeList, fuelList, stateList]);

  useEffect(() => {
    if (rowDataFinal?.length > 0) {
      const values = uniqueNullValues(rowDataFinal);
      if (values === "") {
        setRequireFields([]);
      } else {
        setRequireFields(values);
      }
    }
  }, [rowDataFinal]);

  return (
    <Box>
      <Box className={classes.addEditGrid}>
        <RequireFieldComponent
          classes={classes}
          requireFields={requireFields}
        />
        {/* <Button
          variant="outlined"
          className={classes.fleetListNewButton}
          onClick={clearAllFilters}
        >
          Clear Filters
        </Button> */}
        {requireFields?.length === 0 && (
          <Button
            variant="outlined"
            className={classes.fleetListNewButton}
            onClick={handleNext}
          >
            <span style={{ fontSize: 12 }}>Next</span>
          </Button>
        )}
        <Button
          variant="outlined"
          className={classes.fleetListNewButton}
          onClick={handleClick}
          disabled={selectedRows.length === 0}
        >
          <Settings style={{ fontSize: 16, marginBottom: 2, marginRight: 3 }} />
          <span style={{ fontSize: 12 }}>Action</span>
        </Button>
        <Button
          variant="outlined"
          className={classes.fleetListNewButton}
          onClick={() => navigation("/new-fleetlist")}
        >
          NEW
        </Button>
        <SearchBar onSearch={onSearch} />
      </Box>
      <>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleOnClose}
        >
          <MenuItem
            onClick={() => handleActionSelect("delete", modelData, "model")}
          >
            Delete
          </MenuItem>
          <MenuItem onClick={() => handleActionSelect("export")}>
            Export
          </MenuItem>
          <MenuItem
            onClick={() => handleActionSelect("setFuel", fuelList, "name")}
          >
            Set Fuel
          </MenuItem>
          <MenuItem
            onClick={() => handleActionSelect("setType", typeList, "name")}
          >
            Set Type
          </MenuItem>
          {/* Hide because client require to hide for now */}
          {/* <MenuItem
            onClick={() =>
              handleActionSelect("setDriveTrain", driveTrainList, "name")
            }
          >
            Set Drive Train
          </MenuItem> */}
          <MenuItem onClick={() => handleActionSelect("setYear", year, "year")}>
            Set Year
          </MenuItem>
          <MenuItem onClick={() => handleActionSelect("setMake", make, "name")}>
            Set Make
          </MenuItem>
          <MenuItem
            onClick={() => handleActionSelect("setModel", modelData, "model")}
          >
            Set Model
          </MenuItem>
          <MenuItem onClick={() => handleActionSelect("setMPG", "", "mpg")}>
            Set MPG
          </MenuItem>
          {/* //Hide because client require to hide for now */}
          {/* <MenuItem
            onClick={() => handleActionSelect("groupType", "", "groupType")}
          >
            Set Group Type
          </MenuItem> */}
          <MenuItem
            onClick={() => handleActionSelect("State", stateList, "State")}
          >
            Set Location
          </MenuItem>
          <MenuItem
            onClick={() => handleActionSelect("driverName", "", "driverName")}
          >
            Set Driver Name
          </MenuItem>
          <MenuItem onClick={() => handleActionSelect("setAll", "", "data")}>
            Set All
          </MenuItem>
        </Menu>
      </>

      <AddActionDialog
        modalContent={modalContent}
        closeModal={closeModal}
        selectedRows={selectedRows}
        dropdownValue={dropdownValue}
        keyValue={keyValue}
        companyId={companyId}
      />
    </Box>
  );
};
